import instance from '@/api/index';

const KeywordAPI = {
  getKeywordList: (query: object) => {
    const url = '/counsel/keyword/list';
    const params = query;
    const method = 'get';

    return instance({
      url,
      params,
      method,
    });
  },
  deleteKeyword: (query: object) => {
    const url = '/counsel/keyword';
    const params =  {
      data: query,
    };
    return instance.delete(
      url,
      params,
      );
    },
  postCreateKeyword: (query: object) => {
    const url = '/counsel/keyword';
    const params = query;

    return instance.post(
      url,
      params,
    );
  },
  putEditKeyword: (query: object) => {
    const url = '/counsel/keyword';
    const params = query;

    return instance.put(
      url,
      params,
    );
  },
};

export default KeywordAPI;
