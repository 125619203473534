import Login from '@/views/Login.vue';
import BasicLayout from '@/components/BasicLayout/BasicLayout.vue';
import ManageCounselee from '@/views/counselee/ManageCounselee.vue';
import CreateCounselee from '@/views/counselee/CreateCounselee.vue';
import CounseleeDetail from '@/views/counselee/CounseleeDetail.vue';
import CounseleeTestDetail from '@/views/counselee/CounseleeTestDetail.vue';
import CounseleeReportDetail from '@/views/counselee/CounseleeReportDetail.vue';
import CounseleeTestHistory from '@/views/counselee/CounseleeTestHistory.vue';
import ManageUser from '@/views/user/ManageUser.vue';
import CreateEditUser from '@/views/user/CreateEditUser.vue';
import CounselorMyPage from '@/views/myPage/CounselorMyPage.vue';
import AdminMyPage from '@/views/myPage/AdminMyPage.vue';
import MasterMyPage from '@/views/myPage/MasterMyPage.vue';
import ManageReport from '@/views/report/ManageReport.vue';
import printReport from '@/views/report/PrintReport.vue';
import previewReport from '@/views/report/PreviewReport.vue';
import EditCounseleeInfo from '@/views/counselee/EditCounseleeInfo.vue';
import ErrorPage from '@/views/error/ErrorPage.vue';
import ManageOrg from '@/views/organization/ManageOrg.vue';
import CreateEditOrg from '@/views/organization/CreateEditOrg.vue';
import ManageMaster from '@/views/master/ManageMaster.vue';
import CreateEditMaster from '@/views/master/CreateEditMaster.vue';
import ManageSetGroup from '@/views/setGroup/ManageSetGroup.vue';
import CreateEditSetGroup from '@/views/setGroup/CreateEditSetGroup.vue';
import ManageTestSet from '@/views/testSet/ManageTestSet.vue';
import ManageApp from '@/views/app/ManageApp.vue';
import OrgTestStatistics from '@/views/statistics/OrgTestStatistics.vue';
import CounselorCounseleeStatistics from '@/views/statistics/CounselorCounseleeStatistics.vue';
import TestSetStatistics from '@/views/statistics/TestSetStatistics.vue';
import ManageKeyword from '@/views/keyword/ManageKeyword.vue';

// component: () => import() 형식으로 라우터 설정을 했을 때 간헐적으로
//  라우팅이 제대로 되지 않는 현상이 있어 상단에 미리 import 하여 사용한다
//

const routes: any[] = [
  {
    path: '/login',
    name: 'login',
    component: Login,
  },
  {
    path: '/',
    redirect: '/counselee/manage',
  },
  {
    path: '/counselee',
    component: BasicLayout,
    children: [
      {
        path: 'manage',
        name: 'manageCounselee',
        component: ManageCounselee,
      },
      {
        path: 'create',
        name: 'createCounselee',
        component: CreateCounselee,
      },
      {
        path: 'detail/:id/:name',
        name: 'counseleeDetail',
        component: CounseleeDetail,
      },
      {
        path: 'test/:counseleeId/:id/:name',
        name: 'testDetail',
        component: CounseleeTestDetail,
      },
      {
        path: 'report/:counseleeId/:name/:testId/:status',
        name: 'reportDetail',
        component: CounseleeReportDetail,
      },
      {
        path: 'test/history/:id',
        name: 'testHistory',
        component: CounseleeTestHistory,
      },
      {
        path: 'edit/:id/:name',
        name: 'editCounseleeInfo',
        component: EditCounseleeInfo,
      },
    ],
  },
  {
    path: '/user',
    component: BasicLayout,
    children: [
      {
        path: 'manage',
        name: 'manageUser',
        component: ManageUser,
      },
      {
        path: 'create/:status/:id',
        name: 'createEditUser',
        component: CreateEditUser,
      },
    ],
  },
  {
    path: '/org',
    component: BasicLayout,
    children: [
      {
        path: 'manage',
        name: 'manageOrg',
        component: ManageOrg,
      },
      {
        path: 'create/:status/:id',
        name: 'createEditOrg',
        component: CreateEditOrg,
      },
    ],
  },
  {
    path: '/master',
    component: BasicLayout,
    children: [
      {
        path: 'manage',
        name: 'manageMaster',
        component: ManageMaster,
      },
      {
        path: 'create/:status/:id',
        name: 'createEditMaster',
        component: CreateEditMaster,
      },
    ],
  },
  {
    path: '/setGroup',
    component: BasicLayout,
    children: [
      {
        path: 'manage',
        name: 'manageSetGroup',
        component: ManageSetGroup,
      },
      {
        path: 'create/:status/:id',
        name: 'createEditSetGroup',
        component: CreateEditSetGroup,
      },
    ],
  },
  {
    path: '/testSet',
    component: BasicLayout,
    children: [
      {
        path: 'manage',
        name: 'manageTestSet',
        component: ManageTestSet,
      },
    ],
  },
  {
    path: '/myPage',
    component: BasicLayout,
    children: [
      {
        path: 'counselor',
        name: 'counselorMyPage',
        component: CounselorMyPage,
      },
      {
        path: 'admin',
        name: 'adminMyPage',
        component: AdminMyPage,
      },
      {
        path: 'master',
        name: 'masterMyPage',
        component: MasterMyPage,
      },
    ],
  },
  {
    path: '/report',
    component: BasicLayout,
    children: [
      {
        path: 'manage',
        name: 'manageReport',
        component: ManageReport,
      },
      {
        path: 'print/:id',
        name: 'printReport',
        component: printReport,
      },
      {
        path: 'preview/:id',
        name: 'previewReport',
        component: previewReport,
      },
    ],
  },
  {
    path: '/statistics',
    component: BasicLayout,
    children: [
      {
        path: 'counselorCounselee',
        name: 'counselorCounseleeStatistics',
        component: CounselorCounseleeStatistics,
      },
      {
        path: 'orgTest',
        name: 'orgTestStatistics',
        component: OrgTestStatistics,
      },
      {
        path: 'testSet',
        name: 'testSetStatistics',
        component: TestSetStatistics,
      },
    ],
  },
  {
    path: '/app',
    component: BasicLayout,
    children: [
      {
        path: 'manage',
        name: 'manageApp',
        component: ManageApp,
      },
    ],
  },
  {
    path: '/keyword',
    component: BasicLayout,
    children: [
      {
        path: 'manage',
        name: 'manageKeyword',
        component: ManageKeyword,
      },
    ],
  },
  {
    path: '/error',
    name: 'error',
    component: ErrorPage,
  },
  {
    path: '/:catchAll(.*)',
    name: 'notFound',
    component: ErrorPage,
  },
];

export default routes;
