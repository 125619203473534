
import { reactive, onBeforeMount, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import CounseleeAPI from '@/api/counselee';
import notify from '@/utils/notify';
import MessageBox from 'element-plus/lib/el-message-box';
import filters from '@/utils/filter';
import { koreanEngRegex, numberRegex, phoneRegex, specialRegex } from '@/utils/regex';
import OrgAPI from '@/api/organization';

export default {
  name: 'createCounselee',
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const relationList = [
      {
        label: '부', 
        value: 0
      }, 
      {
        label: '모', 
        value: 1
      },
      {
        label: '친인척', 
        value: 2
      },
      {
        label: '상담사', 
        value: 3
      },
      {
        label: '선생님', 
        value: 4
      },
      {
        label: '경찰관', 
        value: 5
      },
      {
        label: '기타', 
        value: 6
      },
    ];
    const genderList = [
      {
        label: '남', 
        value: 'M'
      }, 
      {
        label: '여', 
        value: 'F'
      }
    ];
    const careHistoryList = [
      {
        label: '병원치료', 
        value: 0
      }, 
      {
        label: '약물치료', 
        value: 1
      },
      {
        label: '상담치료', 
        value: 2
      },
      {
        label: '기타 치료', 
        value: 3
      },
    ];
    const schoolList = [
      {
        label: '유아', 
        value: 0
      }, 
      {
        label: '초등학교', 
        value: 1
      }, 
      {
        label: '중학교', 
        value: 2
      }, 
      {
        label: '고등학교', 
        value: 3
      }, 
      {
        label: '기타', 
        value: 4
      }, 
    ];
    const gradeList = reactive([
      {
        label: '1학년',
        value: 1
      },
      {
        label: '2학년',
        value: 2
      },
      {
        label: '3학년',
        value: 3
      },
      {
        label: '4학년',
        value: 4
      },
      {
        label: '5학년',
        value: 5
      },
      {
        label: '6학년',
        value: 6
      },
      {
        label: '7학년',
        value: 7
      },
      {
        label: '8학년',
        value: 8
      },
      {
        label: '9학년',
        value: 9
      },
      {
        label: '10학년',
        value: 10
      },
      {
        label: '11학년',
        value: 11
      },
      {
        label: '12학년',
        value: 12
      },
    ]);

    const childYears = reactive([
      {
        label: '5세',
        value: 5
      },
      {
        label: '6세',
        value: 6
      },
      {
        label: '7세',
        value: 7
      },
    ]);

    const selectList: {
      schoolYearList: {
        label: string
        value: number
      }[]
    } = reactive({
      schoolYearList: []
    });

    const counseleeInfo: any = reactive({
      name: '',
      birthDate: '',
      gender: '',
      phone: '',
      careHistory: [],
      schoolKind: '',
      schoolYear: '',
      memo: '',
      parentList: [
        {
          name: '',
          birthDate: '',
          gender: '',
          phone: '',
          relation: '',
          relationDetail: '',
          memo: '',
          pscList: []
        }
      ],
      testSet: null
    })

    const pscModels: {
      pscItems: any[]
    } = reactive({
      pscItems: [
        {
          pscScore: '',
          pscDate: ''
        }
      ]
    })

    const orgSelectList: {
      orgList: any[],
      counselorList: any[],
      testSetList: any[]
    } = reactive({
      orgList: [],
      counselorList: [],
      testSetList: []
    });

    const orgSelectItems: {
      organization: any,
      counselor: any,
      testSet: any
    } = reactive({
      organization: null,
      counselor: null,
      testSet: null,
    })

    
    /** 연락처 유효성 체크 및 '-' 추가 */
    const handleChangeCounseleePhone = (word: string) => {
      const phone:any = word ? word.replaceAll('-', '') : '';
      if (phone.length > 11) {
        notify.warning('연락처는 11자 이내로 입력해주세요');
        counseleeInfo.phone = '';
        return;
      }
      if (!isNaN(phone)) {
        counseleeInfo.phone = filters.getFormedPhoneNumber(phone);
      } else {
        notify.warning('연락처를 정확히 입력해주세요');
        counseleeInfo.phone = '';
      }
    }

    /** 보호자 연락처 유효성 체크 및 '-' 추가 */
    const handleChangeGuardianPhone = (word: string, index: number) => {
      const phone:any = word ? word.replaceAll('-', '') : '';
      if (phone.length > 11) {
        notify.warning('연락처는 11자 이내로 입력해주세요');
        counseleeInfo.parentList[index].phone = '';
        return;
      }
      if (!isNaN(phone)) {
        counseleeInfo.parentList[index].phone = filters.getFormedPhoneNumber(phone);
      } else {
        notify.warning('연락처를 정확히 입력해주세요');
        counseleeInfo.parentList[index].phone = '';
      }
    }

    /**보호자 추가 */
    const addGuardian = () => {
      if (counseleeInfo.parentList.length > 10) {
        notify.warning('보호자를 더 입력할 수 없습니다');
        return;
      }
      pscModels.pscItems.push({
        pscScore: '',
        pscDate: ''
      })
      counseleeInfo.parentList.push({
        name: '',
        birthDate: '',
        gender: '',
        phone: '',
        relation: '',
        memo: '',
        order: '',
        pscList: []
      })
      setTimeout(() =>{
        window.scrollTo({
          top: window.innerHeight + (390*counseleeInfo.parentList.length), 
          left: 0, 
          behavior: 'smooth'
        })
      }, 100)
    }


    /**보호자 삭제 */
    const removeGuardian = (index : number) => {
      counseleeInfo.parentList.splice(index, 1)
    }

    /** psc 점수 추가 */
    const addPsc = (index: number) => {
      const tmpPscDate = new Date(pscModels.pscItems[index].pscDate)
      if (pscModels.pscItems[index].pscScore === '' 
      || pscModels.pscItems[index].pscDate === ''
      || !numberRegex.test(pscModels.pscItems[index].pscScore)
      || isNaN(Number.parseInt(pscModels.pscItems[index].pscScore))
      || tmpPscDate.toString() === 'Invalid Date'
      ) {
        notify.warning('psc 항목을 정확히 입력해주세요');
        return;
      }
      if (Number.parseInt(pscModels.pscItems[index].pscScore) > 1000) {
        notify.warning('psc 점수는 1000점을 초과할 수 없습니다.');
        return;
      }

      counseleeInfo.parentList[index].pscList.push({
        score: Number.parseInt(pscModels.pscItems[index].pscScore).toString(),
        date: filters.dateToString(pscModels.pscItems[index].pscDate, 'yyyy-mm-dd')
      });

      counseleeInfo.parentList[index].pscList.sort((a:any,b:any) => {
        return new Date(a.date).getTime() - new Date(b.date).getTime()
      });

      pscModels.pscItems[index] = [
        {
          pscScore: '',
          pscDate: ''
        }
      ];
    }

    /** psc 점수 삭제 */
    const removePsc = (index: number, pscIndex: number) => {
      counseleeInfo.parentList[index].pscList.splice(pscIndex, 1)
    }

    /** 기관 list get api */
    const getOrganizationList = async() => {
      await CounseleeAPI.getOrganizationList()
      .then((response) => {
        const responseData = response.data
        if (responseData 
        && typeof responseData === 'object'
        && Object.keys(responseData).indexOf('result') > -1
        && Object.keys(responseData).indexOf('response') > -1
        && responseData.result ) {
          const {
            list
          } = responseData.response;
          const tmpList = [];
          for (const item of list) {
            tmpList.push({
              label: item.name,
              value: item.id
            })
          }
          orgSelectList.orgList = tmpList
        }
      })
      .catch((err) => {
        throw err;
      })
    }

    /** 기관별 상담사 list get api */
    const getOrgCounselorList = async(id: string) => {
      await CounseleeAPI.getOrgCounselorList({
        id: id,
      })
      .then((response) => {
        const responseData = response.data;
        if (responseData 
        && typeof responseData === 'object'
        && Object.keys(responseData).indexOf('result') > -1
        && Object.keys(responseData).indexOf('response') > -1
        && responseData.result ) {
          const {
            list
          } = responseData.response;
          const tmpList = [];
          for (const item of list) {
            tmpList.push({
              label: item.name,
              value: item.id
            })
          }
          orgSelectList.counselorList = tmpList
        } else {
          throw 'err';
        }
      })
      .catch((err) => {
        throw err;
      })
    }


    /** 기관별 테스트셋 list get api */
    const getOrtDetail = async(id: string) => {
      await OrgAPI.getOrgDetail({id})
      .then((response) => {
        const responseData = response.data;
        if (responseData 
        && typeof responseData === 'object'
        && Object.keys(responseData).indexOf('result') > -1
        && Object.keys(responseData).indexOf('response') > -1
        && responseData.result ) {
          const testSetList = responseData.response.testSetList;
          const tmpList:any[] = [
            {
              name: '선택 안함',
              id: ''
            }
          ];
          if (Array.isArray(testSetList)) {
            for (const item of testSetList) {
              tmpList.push(item)
            }
          }
          orgSelectList.testSetList = tmpList;
        } else {
          throw 'err';
        }
      })
      .catch((err) => {
        throw err;
      })
    }

    /** 내담자 등록 params 만드는 fnc */
    const getCreateParams = () => {      
      const {
        name,
        birthDate,
        gender,
        schoolKind,
        schoolYear,
        phone,
        careHistory,
        memo,
        parentList,
      } = counseleeInfo;


      const params:any = {
        birthDate: filters.dateToString(birthDate, 'yyyymmdd'),
        gender: gender,
        schoolKind: schoolKind,
        schoolYear: schoolYear,
        parentsList: []
      };

      if (!name
      || !birthDate
      || !gender
      || (schoolKind !== 0 && !schoolKind)
      || !schoolYear
      ) {
        notify.warning('내담자 정보를 모두 입력해주세요');
        return;
      }

      if (koreanEngRegex.test(name)) {
        params.name = name;
      } else {
        notify.warning('이름에는 한글이나 영문만 입력할 수 있습니다');
        return;
      }


      
      for (let i = 0; i < parentList.length; i++) {
        if (!parentList[i].name
        || !parentList[i].birthDate
        || !parentList[i].gender
        || !parentList[i].phone
        || (parentList[i].relation !== 0 && !parentList[i].relation)
        ) {
          notify.warning('보호자 정보를 모두 입력해주세요');
          return;
        } else {
          const tmpGuardian: any = {
            name: parentList[i].name,
            birthDate: filters.dateToString(parentList[i].birthDate, 'yyyymmdd'),
            gender: parentList[i].gender,
            relation: parentList[i].relation,
            order: i + 1
          }

          if (phoneRegex.test(parentList[i].phone)) {
            const onlyPhoneNumber = parentList[i].phone.replaceAll('-', '');
            if (onlyPhoneNumber.length > 11) {
              notify.warning('보호자 연락처를 11자 이내로 입력해 주세요');
              return;
            } else {
              tmpGuardian.phone = onlyPhoneNumber;
            }
          } else {
            notify.warning('보호자 연락처를 정확히 입력해 주세요');
            return;
          }

          if (koreanEngRegex.test(parentList[i].name)) {
            tmpGuardian.name = parentList[i].name;
          } else {
            notify.warning('이름에는 한글이나 영문만 입력할 수 있습니다');
            return;
          }

          const parentBirthDateTime = parentList[i].birthDate;

          const counseleeBirthDateTime = counseleeInfo.birthDate;
          
          if ((new Date(counseleeBirthDateTime).getTime() - new Date(parentBirthDateTime).getTime()) < 0) {
            notify.warning('내담자 생년월일은 보호자보다 빠를 수 없습니다.');
            return;
          }

          tmpGuardian.memo = parentList[i].memo;

          if (parentList[i].pscList.length > 0) {
            const tmpList:any = [];
            for(const pscItem of parentList[i].pscList) {
              tmpList.push({
                score: Number.parseInt(pscItem.score),
                date: filters.dateToString(pscItem.date, 'yyyymmdd')
              })
            }
            tmpGuardian.pscList = tmpList;
          }

          if (parentList[i].relation === 6) {
            tmpGuardian.relationDetail = parentList[i].relationDetail;
          }


          params.parentsList.push(tmpGuardian);

        }
      }

      if (!orgSelectItems.organization || !orgSelectItems.counselor) {
        notify.warning('기관 정보를 모두 입력해주세요');
        return;
      } else {
        const {
          organization,
          counselor
        } = orgSelectItems;

        params.organizationId = organization;
        params.counselorId = counselor;
      }

      if (phone !== '') {
        if (phoneRegex.test(phone)) {
          const onlyPhoneNumber = phone.replaceAll('-', '');
          if (onlyPhoneNumber.length > 11) {
            notify.warning('연락처를 11자 이내로 입력해주세요');
            return;
          } else {
            params.phone = onlyPhoneNumber;
          }
        } else {
          notify.warning('연락처를 정확히 입력해주세요');
          return;
        }
      } else {
        params.phone = '';
      }

      if (careHistory.length > 0) 
        params.careHistory = careHistory ;
      
      if (orgSelectItems.testSet === '') 
        params.testSetId = null;
      else if (orgSelectItems.testSet === 0 || orgSelectItems.testSet)
        params.testSetId = orgSelectItems.testSet;


      params.memo = memo;

      return params;

    }

    /** 내담자 등록 api */
    const postCreateCounselee = async() => {
      const params = getCreateParams();
      if (params) {
        await CounseleeAPI.postCreateCounselee(params)
        .then((response) => {
          const responseData= response.data;
          if(responseData 
          && typeof responseData === 'object'
          && Object.keys(responseData).indexOf('result') > -1
          && Object.keys(responseData).indexOf('response') > -1
          && responseData.result ) {
            MessageBox({
              title: '내담자 등록 완료',
              message: '내담자 등록이 완료되었습니다.',
              callback: () => router.push({
                name: 'manageCounselee',
                query: route.query
              }) 
            })
            
          } else {
            throw '';
          }
        })
        .catch((error) => {
          if (typeof error === 'object'
          && Object.keys(error).indexOf('response') > -1
          && typeof error.response === 'object'
          && Object.keys(error.response).indexOf('status') > -1
          && Object.keys(error.response).indexOf('data') > -1
          && Object.keys(error.response.data).indexOf('response') > -1
          && Object.keys(error.response.data.response).indexOf('message') > -1) {
            const status = error.response.status;
            if (status === 400) {
              if (error.response.data.response.message === 'Test set does not exists.') {
                notify.error('테스트 셋 정보에 문제가 있습니다. 관리자에게 문의해주세요');
              } 
            }
          } else {
            throw error;
          }
        })  
      }
    }

    /** 취소 클릭 */
    const handleClickCancel = () => {
      router.push({
        name: 'manageCounselee',
        query: route.query
      })
    }

    /** 저장 클릭 */
    const handleClickSave = () => {
      store.commit('loadingBar/showLoadingBar');
      postCreateCounselee()
      .then(() => {
        store.commit('loadingBar/hideLoadingBar');
      })
      .catch(() => {
        router.push({ name: 'error' }).catch(() => {});
      })
    }

    onBeforeMount(() => {
      getOrganizationList()
      .catch(() => {
        router.push({ name: 'error' }).catch(() => {});
      })
    })

    /** 담당기관에 따라 상담사 리스트 조정 */
    watch(() => orgSelectItems.organization,
      (organization:any, prevOrg: any) => {
        orgSelectItems.counselor = null;
        orgSelectItems.testSet = null;
        store.commit('loadingBar/showLoadingBar');
        Promise.all([
          getOrgCounselorList(organization),
          getOrtDetail(organization)
        ])
        .then(() => {
          store.commit('loadingBar/hideLoadingBar');
        })
        .catch(() => {
          router.push({ name: 'error' }).catch(() => {});
        })
    })

    /** 학교 변경 시 학년 reset */
    watch(() => counseleeInfo.schoolKind,
      (schoolKind:any, prevSchoolKind: any) => {
        counseleeInfo.schoolYear = null;
        switch (schoolKind) {
          case 0: selectList.schoolYearList = childYears; break;
          case 1: selectList.schoolYearList = [...gradeList].slice(0, 6); break;
          case 2: case 3: selectList.schoolYearList = [...gradeList].slice(0, 3); break;
          case 4: selectList.schoolYearList = gradeList; break;
          default: selectList.schoolYearList = []; break;
        }
    });

    
    

    return {
      relationList,
      genderList,
      orgSelectList,
      orgSelectItems,
      careHistoryList,
      counseleeInfo,
      addGuardian,
      gradeList,
      schoolList,
      removeGuardian,
      pscModels,
      addPsc,
      removePsc,
      childYears,
      handleChangeCounseleePhone,
      handleChangeGuardianPhone,
      selectList,
      handleClickSave,
      handleClickCancel,
      filters
    }
  }
}
