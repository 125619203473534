
import { useStore } from 'vuex'
import { computed } from 'vue';

export default {
  name: 'Modal',
  props: {
    options:{}
  },
  setup (props:any) {
    const store = useStore();
    const closeModal = () => {
      store.commit('modal/closeModal')
    }
    const modalOption = props.options;
    return {
      modalVisible: computed(() => store.getters['modal/modalVisible']),
      modalType: computed(() => store.getters['modal/modalType']),
      closeModal,
      modalOption,
      
    }
  },
}
