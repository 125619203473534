<template>
  <div class="error-page-wrapper">
    <div class="error-container">
      <img
        class="logo"
        alt="에러페이지 조앤 로고"
        :src="require('@/assets/logos/joanne-bi-1@3x.png')"
      />
      <div class="description-box">
        <h2 v-html="description"></h2>
      </div>
      <el-button 
        class="gray"
        @click="goMain"
        >메인페이지로 이동하기</el-button>
    </div>
  </div>
</template>

<script lang="ts">
import { useRouter } from 'vue-router'
export default {
  name: 'errorPage',
  setup(){
    const router = useRouter();
    return {
      description: '페이지를 찾을수가 없거나, <br/>페이지 처리에 오류가 발생하였습니다.',
      goMain(){
        router.push('/').catch(() => {});
      }
    }
  }
}
</script>

<style>

</style>