const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{8,20}$/;
const emailRegex = /^([0-9a-zA-Z_.-]+)@([0-9a-zA-Z_-]+)(\.[0-9a-zA-Z_-]+){1,2}$/;
const phoneRegex = /^[0-9]{2,3}-[0-9]{3,4}-[0-9]{4}/;
const koreanEngRegex = /^[ㄱ-ㅎ|가-힣|a-z|A-Z| |]+$/;
const engNumRegex = /^[a-zA-Z0-9]*$/;
const numberRegex = /^[0-9]*$/;
const dateRegex = /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/;
const floatRegex =  /^[0-9]+\.?[0-9]+$/;
const intAndFloatRegex = /(?:^| )\d+(\.\d+)?(?=$| )/;
const specialRegex = /[~!@#$%^&*()_+-|<>?:;`,{}\][/'"\\']/gi;
const koreanRegex = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/;


export {
  passwordRegex,
  emailRegex,
  phoneRegex,
  koreanEngRegex,
  engNumRegex,
  numberRegex,
  dateRegex,
  floatRegex,
  intAndFloatRegex,
  specialRegex,
  koreanRegex,
};
