<template>
  <div class="header-wrapper">
    <div class="bi-wrapper">
      <img 
        :src="require('@/assets/logos/joanne-bi-1.png')" 
        :srcset="require('@/assets/logos/joanne-bi-1@2x.png')"
        alt="조앤BI"
        @click="handleClickBi"
      >
    </div>
    <div class="menu-wrapper">
      <div 
        class="menu-item" 
        :class="{'active': getCurrentMenu() === 1}"
        @click="handleSelect(1)"
      >{{ '내담자 관리' }}</div>
      <div 
        class="menu-item" 
        v-if="(userLevel === '0')||(userLevel === '1')"
        :class="{'active': getCurrentMenu() === 2}"
        @click="handleSelect(2)"
      >{{ '상담사 관리' }}</div>
      <div 
        class="menu-item"
        v-if="(userLevel === '0')||(userLevel === '1')"
        :class="{'active': getCurrentMenu() === 3}"
        @click="handleSelect(3)"
      >{{ '보고서 출력' }}</div>
      <!-- <div 
        class="menu-item"
        v-if="(userLevel === '0')"
        :class="{'active': getCurrentMenu() === 4}"
        @click="handleSelect(4)"
      >{{ '앱 관리' }}</div> -->
    </div>

    <!-- 2, 3차개발 완료시 show -->
    <!-- <div class="menu-wrapper new">
      <div 
        class="menu-item" 
        :class="{'active': getCurrentMenu() === 1}"
        @click="handleSelect(1)"
      >{{ '내담자 관리' }}</div>
      <el-dropdown>
        <div 
          class="menu-item" 
          :class="{'active': getCurrentMenu() === 2 || getCurrentMenu() === 6 || getCurrentMenu() === 7}"
          style="width:110px;"
        >
          {{ '사용자 관리' }}
          <i class="el-icon-arrow-down el-icon--right"></i>
        </div>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item
              v-if="(userLevel === '0')||(userLevel === '1')"
              @click="handleSelect(2)"
            >
              {{ '상담사 관리' }}
            </el-dropdown-item>
            <el-dropdown-item
              v-if="(userLevel === '0')"
              @click="handleSelect(6)"
            >
              {{ '기관 관리' }}
            </el-dropdown-item>
            <el-dropdown-item
              v-if="(userLevel === '0')"
              @click="handleSelect(7)"
            >
              {{ '관리자 관리' }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
      <div 
        class="menu-item"
        v-if="(userLevel === '0')||(userLevel === '1')"
        :class="{'active': getCurrentMenu() === 3}"
        @click="handleSelect(3)"
      >{{ '보고서 출력' }}</div>
      <div 
        class="menu-item"
        v-if="(userLevel === '0')"
        :class="{'active': getCurrentMenu() === 4}"
        @click="handleSelect(4)"
      >{{ '앱 관리' }}</div>
      <el-dropdown>
        <div 
          class="menu-item" 
          v-if="(userLevel === '0')"
          :class="{'active': getCurrentMenu() === 5}"
          style="width:80px;"
        >
          {{ '통계' }}
          <i class="el-icon-arrow-down el-icon--right"></i>
        </div>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item
              v-if="(userLevel === '0')"
              @click="handleSelect(8)"
            >
              {{ '기관 테스트 통계' }}
            </el-dropdown-item>
            <el-dropdown-item
              v-if="(userLevel === '0')"
              @click="handleSelect(9)"
            >
              {{ '테스트 셋 통계' }}
            </el-dropdown-item>
            <el-dropdown-item
              v-if="(userLevel === '0')"
              @click="handleSelect(10)"
            >
              {{ '상담사/내담자 통계' }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div> -->
    <div class="user-info-wrapper">
      <h4
        @click="handleClickMyPage"
      >{{ userId }}</h4>
      <h4
        :class="userLevel === '1' 
        ? 'blue' : userLevel === '2' 
        ? 'orange' : 'black'"
      >{{ userLevel === '0' 
        ? '마스터' : userLevel === '1' 
        ? '기관' : userLevel === '2' 
        ? '상담사' : ''}}</h4>
      <el-button
        @click="handleClickLogout"
      >{{ '로그아웃' }}</el-button>
    </div>
  </div>
</template>

<script lang="ts">
import { deleteCookie, getCookie } from '@/utils/cookie'
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import AccountAPI from '@/api/account';
export default {
  name: 'Header',
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const userId = getCookie('userId');
    const userLevel = getCookie('userLevel');

    /**조앤 로고 클릭 */
    const handleClickBi = () => {
      router.push('/').catch(() => {});
    }


    /** 로그아웃 api */
    const logout = async() => {
      await AccountAPI.logout()
      .then(() => {
        deleteCookie('Authorization');
        deleteCookie('organizationId');
        deleteCookie('userId');
        deleteCookie('userName');
        deleteCookie('userType');
        deleteCookie('userLevel');
      })
    }

    /** 메뉴 클릭 */
    const handleSelect = (index:number) => {
      let viewName = 'manageCounselee';

      switch(index) {
        case 1: break;
        case 2: viewName = 'manageUser'; break;
        case 3: viewName = 'manageReport'; break;
        case 4: viewName = 'manageApp'; break;
        case 5: viewName = 'manageStatistics'; break;
        case 6: viewName = 'manageOrg'; break;
        case 7: viewName = 'manageMaster'; break;
        case 8: viewName = 'orgTestStatistics'; break;
        case 9: viewName = 'testSetStatistics'; break;
        case 10: viewName = 'counselorCounseleeStatistics'; break;
        default: break;
      }

      router.push({
        name: viewName,
      }).catch(() => {})
    }

    /** 로그아웃 클릭 */
    const handleClickLogout = () => {
      store.commit('loadingBar/showLoadingBar');
      logout()
      .then(() => {
        router.push({ name: 'login' }).catch(() => {});
        store.commit('loadingBar/hideLoadingBar');
      })
    }

    /** css 용 url 매치 여부 */
    const getCurrentMenu = () => {
      const currentPathName = route.matched[1].path.split('/')[1]
      switch(currentPathName) {
        case 'counselee': return 1;
        case 'user': return 2;
        case 'report': return 3;
        case 'app': return 4;
        case 'statistics': return 5;
        case 'org': return 6;
        case 'master': return 7;
        default: return 0;
      }
    }

    /** 등급별 마이페이지 이동 */
    const handleClickMyPage = () => {
      let menuName = '';
      switch(userLevel) {
        case '0': menuName = 'masterMyPage'; break;
        case '1': menuName = 'adminMyPage'; break;
        case '2': menuName = 'counselorMyPage'; break;
        default: break;
      }

      if (menuName) {
        router.push({ 
          name: menuName,
        }).catch(() => {});
      }
    }

    return {
      userId,
      userLevel,
      handleClickLogout,
      handleSelect,
      getCurrentMenu,
      handleClickBi,
      handleClickMyPage
    }
  },
}
</script>