
import { onBeforeMount, reactive, watch } from 'vue';
import Chart from '@/components/Charts/Chart.vue';
import filters from '@/utils/filter';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import CounseleeAPI from '@/api/counselee';
import StatisticsAPI from '@/api/statistics';
import color from '@/utils/color';
import _MessageBox from 'element-plus/lib/el-message-box';
import SetGroupAPI from '@/api/setGroup';
export default {
  name: 'testSetStatistics',
  components: {
    Chart
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const route = useRoute();

    const chartSize: {
      width: number,
      height: number
    } = reactive({
      width: 0,
      height: 0
    })

    const selectList: {
      agencyList: any[],
      setList: any[]
    } = reactive({
      agencyList: [],
      setList: []
    })

    const listOption: {
      maxPage: number,
      totalCount: number,
      page: number,
      list: any[],
      order: string,
      orderField: string,
      entireCount: number,
      search: string,
      date: any[],
      organization: any[],
      chart: any[],
      testSet: any[]
    } = reactive({
      maxPage: 10,
      totalCount: 0,
      page: 1,
      list: [],
      order: '',
      orderField: '',
      entireCount: 0,
      search: '',
      date: [],
      organization: [],
      chart: [],
      testSet: []
    });

    const orgInfo: {
      isSelectAll: boolean,
      selectedOrgNames: string
    } = reactive({
      isSelectAll: true,
      selectedOrgNames: ''
    })

    const chartData: {
      data: {
        labels: string[],
        datasets: any[],
      }
      options: any
    } = reactive({
      data: {
        labels: [],
        datasets: [],
      },
      options: {
        // min: 0,
        // max: 100,
        // responsive: true,
        // plugins: {
        //   legend: {
        //     position: "top",
        //   },
        // },
        // scales: {
        //   y: {
        //     min: 0,
        //     max: 150,
        //     ticks: {
        //       callback: (value:number) => `${value}%`,
        //     },
        //   },
        // },
      }
    })

    /** 검색쿼리 url 저장 */
    const setUrlQueryString = () => {
      const {
        search,
        date,
        page,
        order,
        orderField,
        organization,
        testSet,
      } = listOption;

      const query:any = {};

      if (Array.isArray(date)) {
        const tmpList = [];
        for (const item of date) {
          item && tmpList.push(filters.dateToString(item, 'yyyy-mm-dd'));
        }
        query.date = tmpList;
      }

      if (page !== 1)
        query.page = page;

      if (order)
        query.order = order;
      
      if (orderField)
        query.orderField = orderField;

      if (search)
        query.search = search;

      if (organization.length > 0)
        query.organization = organization;

      if (testSet.length > 0)
        query.testSet = testSet;

      router.replace({
        name: 'testSetStatistics',
        query
      }).catch(() => {});
    }

        /** 전체 체크 fnc */
    const checkAll = (type: string) => {
      const tmpList: any [] = []
      if (type === 'org') {
        for (const item of selectList.agencyList) {
          tmpList.push(item.value)
        }
        listOption.organization = tmpList;
      } else if (type === 'testSet') {
        for (const item of selectList.setList) {
          tmpList.push(item.value)
        }
        listOption.testSet = tmpList;
      }
    }


    /** 기관별 상담사 통계 params */
    const getParams = () => {
      const {
        maxPage,
        page,
        organization,
        order,
        orderField,
        search,
        date
      } = listOption;

      const params: any = {
        maxPage: maxPage,
        page: page,
      };

      if (date && date.length === 2) {
        params.gte = filters.dateToString(date[0], 'yyyy-mm-dd');
        params.lte = filters.dateToString(date[1], 'yyyy-mm-dd');
      }

      if (organization.length > 0) {
        let tmpList: any[] = [];
        for (const item of organization) {
          if ( item === 'all') {
            tmpList = [];
            break;
          } else {
            tmpList.push(item)
          }
        }
        params.organization = JSON.stringify(tmpList);

      }

      if (order && orderField) {
        params.order = order;
        params.orderField = orderField;
      }

      if (search) {
        params.search = search;
      }

      return params;
    }


    /** 기관별 테스트셋 통계 get api */
    const getOrgTestSetStatistics = async() => {
      const params = getParams();
      if (params) {
        await StatisticsAPI.getOrgTestSetStatistics(params)
        .then((response) => {
          const responseData = response.data;
         
          if (responseData 
          && typeof responseData === 'object'
          && Object.keys(responseData).indexOf('result') > -1
          && Object.keys(responseData).indexOf('response') > -1
          && responseData.result ) {
            const {
              list
            } = responseData.response;

            listOption.list = list;
            console.log(list)

            if (Array.isArray(list)) {
              const tmpLabels: string[] = [];
              const tmpDatasets: any[] = [];
              let tmpHighestCount = 0;
              let tmpOrgNames: string = '';

              list.sort((a:any, b:any) => {
                const aString = a.name[0].toUpperCase();
                const bString = b.name[0].toUpperCase();

                if (a.name !== '전체' && b.name !== '전체') {
                  return aString.localeCompare(bString);
                }
                return 0;
              });
              
              for (let i = 0; i < list.length; i++) {
                const tmpColor: string = color.getRandomColor();
                const tmpDataList: number[] = [];
                for (const item of list[i].testSetList) {
                  for (const detailItem of item.detailList) {
                    if (i == 0) 
                      tmpLabels.push(detailItem.date);
                    
                    tmpDataList.push(detailItem.count);

                    if (detailItem.count > tmpHighestCount)
                      tmpHighestCount = detailItem.count;
                  }
                }
                if (list[i].name !== '전체') {
                  if (i !== list.length - 1) {
                    tmpOrgNames += `${list[i].name}, `;
                  } else {
                    tmpOrgNames += list[i].name;
                  }
                }

                tmpLabels.reverse();
                tmpDataList.reverse();


                tmpDatasets.push({
                  label: list[i].name,
                  data: tmpDataList,
                  borderColor: tmpColor,
                  backgroundColor: tmpColor
                })

              }
              
              orgInfo.selectedOrgNames = tmpOrgNames;

              chartData.data.labels = tmpLabels;
              chartData.data.datasets = tmpDatasets;
              if (tmpHighestCount < 10) {
                chartData.options = {
                  scales: {
                    y: {
                      max: 10,
                      min: 0
                    }
                  }
                }
              }
            }
          } else {
            throw 'err';
          }
        })
        .catch(err => {
          throw err;
        })
      }
    }

    /** 기관정보 get api */
    const getOrganizationList = async() => {
      await CounseleeAPI.getOrganizationList()
      .then((response) => {
        const responseData = response.data
        if (responseData 
        && typeof responseData === 'object'
        && Object.keys(responseData).indexOf('result') > -1
        && Object.keys(responseData).indexOf('response') > -1
        && responseData.result ) {
          const {
            list
          } = responseData.response;
          const tmpList = [
            {
              label: '전체', 
              value: 'all'
            }
          ]
          for (const item of list) {
            tmpList.push({
              label: item.name,
              value: item.id
            })
          }
          selectList.agencyList = tmpList
        } else {
          throw 'err';
        }
      })
      .catch((err) => {
        throw err;
      })
    }

    /** 선택박스 테스트셋 전체 get api */
    const getTestSet = async() => {
      await SetGroupAPI.getTestSet()
      .then((response) => {
        const responseData = response.data;
        if (responseData 
        && typeof responseData === 'object'
        && Object.keys(responseData).indexOf('result') > -1
        && Object.keys(responseData).indexOf('response') > -1
        && responseData.result ) {
          const {
            list
          } = responseData.response;
          if (Array.isArray(list)) {
            const tmpList:{
              label: string,
              value: any
            }[] = [
              {
                label: '전체', 
                value: 'all'
              }
            ];
            for (const item of list) {
              tmpList.push({
                label: item.name,
                value: item.id
              })
            }
            selectList.setList = tmpList;
          }
        } else {
          throw 'err';
        }
      })
      .catch((err) => {
        throw err;
      })
    }


    /** 기관별 테스트 통계 Excel 다운로드 api */
    const getOrgTestSetStatisticsExcel = async() => {
      const params = getParams();
      if (params) {
        params.excel = true;
        
        await StatisticsAPI.getOrgTestSetStatisticsExcel(params)
        .then((response) => {
          if (response.data) {
            const blob = new Blob([response.data], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"})
            const a = window.document.createElement("a")

            a.href = window.URL.createObjectURL(blob)
            a.download = `기관별 테스트셋 통계_${filters.dateToString(new Date(), 'yyyy-mm-dd hh:mm:ss').replaceAll('-', '').replaceAll(':', '').replaceAll(' ', '')}.xlsx`;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
          } else {
            throw 'err';
          }
        })
        .catch((err) => {
          throw err;
        })
      }
    }

    /** 초기화 */
    const refresh = () => {
      store.commit('loadingBar/showLoadingBar');
      Promise.all([
        getOrgTestSetStatistics(),
        getOrganizationList(),
        getTestSet()
      ])
      .then(() => {
        store.commit('loadingBar/hideLoadingBar')
      })
      .catch(() => {
        router.push({ name: 'error' }).catch(() => {});
      })
    }

    onBeforeMount(() => {
      /** chart size */
      chartSize.width = window.innerWidth;
      chartSize.height = 500;

      addEventListener('resize', () => {
        chartSize.width = window.innerWidth;
      })


      if (Object.keys(route.query).length > 0) {
        const {
          page,
          order,
          orderField,
          search,
          organization,
          testSet,
          date
        } = route.query;
        listOption.page = Number.parseInt(page ? page.toString() : '1');
        listOption.order = order ? order.toString() : '';
        listOption.orderField = orderField ? orderField.toString() : '';
        listOption.search = search ? search.toString() : '';


        if (Array.isArray(organization)) {
          const tmpList = [];
          for (const item of organization) {
            if (item) {
              const tmpNum = Number.parseInt(item);
              (isNaN(tmpNum)) 
              ? tmpList.push(item)
              : tmpList.push(tmpNum);
            }
          }

          listOption.organization = tmpList;
        } else if (typeof organization === 'string' && organization !== '') {
          const tmpNum = Number.parseInt(organization);
          isNaN(tmpNum)
          ? listOption.organization = [organization]
          : listOption.organization = [tmpNum];
        }

        if (Array.isArray(testSet)) {
          const tmpList = [];
          for (const item of testSet) {
            if (item) {
              const tmpNum = Number.parseInt(item);
              (isNaN(tmpNum)) 
              ? tmpList.push(item)
              : tmpList.push(tmpNum);
            }
          }

          listOption.testSet = tmpList;
        } else if (typeof testSet === 'string' && testSet !== '') {
          const tmpNum = Number.parseInt(testSet);
          isNaN(tmpNum)
          ? listOption.testSet = [testSet]
          : listOption.testSet = [tmpNum];
        }

        if (Array.isArray(date) && date.length > 0) {
          const tmpList = [];
          for (const item of date) {
            item && tmpList.push(new Date(item));
          }
          listOption.date = tmpList;
        } else {
          /** set one month */
          listOption.date = [
            new Date(new Date().getTime() - 2592000000),
            new Date(new Date().getTime() - 86400000)
          ]
        }
      } else {
        /** set one month */
          listOption.date = [
            new Date(new Date().getTime() - 2592000000),
            new Date(new Date().getTime() - 86400000)
          ]
      }
      refresh();
    })

    watch(() => listOption.organization, (agency: any, prevAgency: any) => {
      if (selectList.agencyList.length > 0) {
        const checkAllIndex = agency.indexOf('all');
        const prevCheckAllIndex = prevAgency.indexOf('all'); 
        if ( checkAllIndex > -1 
        && prevCheckAllIndex < 0) {
          checkAll('org');
        } else if (checkAllIndex < 0 
        && prevCheckAllIndex > -1
        && agency.length === (selectList.agencyList.length -1)) {
          listOption.organization = [];
        } else if (checkAllIndex < 0
        && prevCheckAllIndex < 0
        && agency.length === (selectList.agencyList.length -1)) {
          checkAll('org');
        } else if (checkAllIndex > -1
        && prevCheckAllIndex > -1
        && agency.length === (selectList.agencyList.length -1)) {
          const tmpList: any [] = []
          for (const item of agency) {
            if (item !== 'all') {
              tmpList.push(item)
            }
          }
          listOption.organization = tmpList;
        }
      }
    })

    watch(() => listOption.testSet, (testSet: any, prevTestSet: any) => {
      if (selectList.agencyList.length > 0) {
        const checkAllIndex = testSet.indexOf('all');
        const prevCheckAllIndex = prevTestSet.indexOf('all'); 
        if ( checkAllIndex > -1 
        && prevCheckAllIndex < 0) {
          checkAll('testSet');
        } else if (checkAllIndex < 0 
        && prevCheckAllIndex > -1
        && testSet.length === (selectList.setList.length -1)) {
          listOption.organization = [];
        } else if (checkAllIndex < 0
        && prevCheckAllIndex < 0
        && testSet.length === (selectList.setList.length -1)) {
          checkAll('testSet');
        } else if (checkAllIndex > -1
        && prevCheckAllIndex > -1
        && testSet.length === (selectList.setList.length -1)) {
          const tmpList: any [] = []
          for (const item of testSet) {
            if (item !== 'all') {
              tmpList.push(item)
            }
          }
          listOption.testSet = tmpList;
        }
      }
    })

    return {
      listOption,
      filters,
      selectList,
      chartData,
      chartSize,
      orgInfo,
      getIndex(index: number){
        const {
          totalCount,
          maxPage,
          page
        } = listOption;
        return totalCount - (maxPage * (page - 1)) - index;
      },
      getTableTime(date: string) {
        const dateObj:any = new Date(date);
        let result: string = '';
        (!date || dateObj.toString() === 'Invalid Date') 
        ? result = '-'
        : result = filters.dateToString(dateObj, 'tableTime');
        return result.replaceAll('<br/>', ' ');
      },
      handleClickSearch() {
        listOption.page = 1;
        setUrlQueryString();
      },
      downloadExcel(){
        store.commit('loadingBar/showLoadingBar');
        getOrgTestSetStatisticsExcel()
        .then(() => {
          store.commit('loadingBar/hideLoadingBar');
        })
        .catch(() => {
          router.push({ name: 'error' }).catch(() => {});
        })
      }
    }
  }
}
