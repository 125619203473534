
import { onBeforeMount, onMounted, reactive, ref, watch } from "vue";
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import List from '@/components/List/List.vue';
import Modal from '@/components/Modal/Modal.vue';
import filters from '@/utils/filter';
import KeywordAPI from '@/api/keyword';
import notify from '@/utils/notify';
import _MessageBox from 'element-plus/lib/el-message-box';

export default {
  name: 'manageKeyword',
  components: {
    List,
    Modal
  },
  setup(){
    const router = useRouter();
    const route = useRoute();
    const store = useStore();

    const selectList = {
      kindOfKeywordList: [
        {
          label: '전체',
          value: 'all',
        },
        {
          label: '긍정',
          value: 'positive'
        },
        {
          label: '부정',
          value: 'negative'
        },
        {
          label: '중지',
          value: 'stop'
        }
      ]
    };

    /** 대표어 */
    const representWord = reactive({
      positive: '',
      negative: '',
      stop: ''
    })

    const tabNow = ref('all');

    const listOption: {
      maxPage: number,
      totalCount: number,
      page: number,
      list: any[],
      order: string,
      orderField: string,
      entireCount: number,
      search: string,
      date: any[],
      kindOfKeyword: any,
    } = reactive({
      maxPage: 10,
      totalCount: 0,
      page: 1,
      list: [],
      order: '',
      orderField: '',
      entireCount: 0,
      search: '',
      dateOption: 'test',
      date: [],
      kindOfKeyword: 'all'
    });

    const modalOption: {
      title: string,
      keyword: string,
      type: string,
      kind: string,
      keywordId: any
    } = reactive({
      title: '키워드 등록',
      keyword: '',
      type: 'create',
      kind: '',
      keywordId: null
    });

    const resetListOption = () => {
      listOption.totalCount = 0;
      listOption.page = 1;
      listOption.order = '';
      listOption.orderField = '';
      listOption.entireCount = 0;
      listOption.search = '';
      listOption.date = [];
    }

    const resetModalOption = (type: string) => {
      modalOption.keyword = '';
      modalOption.type = type;
      modalOption.kind = '';
      modalOption.keywordId = null;

      if (type === 'create') {
        modalOption.title = '키워드 등록';
      } else if (type === 'edit') {
        modalOption.title = '키워드 수정';
      }
    }

     /** 검색쿼리 url 저장 */
    const setUrlQueryString = () => {
      const {
        page,
        order,
        orderField,
        search,
        date,
        kindOfKeyword
      } = listOption;
      const query:any = {};

      if (page !== 1)
        query.page = page;

      if (order)
        query.order = order;
      
      if (orderField)
        query.orderField = orderField;

      if (search)
        query.search = search;
      
      if (kindOfKeyword !== '')
        query.kindOfKeyword = kindOfKeyword;

      if (Array.isArray(date)) {
        const tmpList = [];
        for (const item of date) {
          item && tmpList.push(filters.dateToString(item, 'yyyy-mm-dd'));
        }
        query.date = tmpList;
      }

      router.replace({
        name: 'manageKeyword',
        query
      }).catch(() => {});
    }

    /** 키워드  list get params*/
    const getParams = () => {
      const {
        date,
        orderField,
        order,
        search,
        maxPage,
        page,
        kindOfKeyword
      } = listOption;
      const params:any = {
        maxPage,
        page,
      };

      if (date && date.length === 2) {
        params.gte = filters.dateToString(date[0], 'yyyy-mm-dd');
        params.lte = filters.dateToString(date[1], 'yyyy-mm-dd');
      } 

      if (orderField) 
        params.orderField = orderField;
      
      
      if (order) 
        params.order = order;
      
      if (search) 
        params.search = search.replaceAll('-', '');

      if (kindOfKeyword)
        params.kind = kindOfKeyword;
      return params;
    }

    /** 키워드 list get api */
    const getKeywordList = async() => {
      const params = getParams();
      if (params) { 
        await KeywordAPI.getKeywordList(params)
        .then((response) => {
          const responseData = response.data
          if (responseData 
          && typeof responseData === 'object'
          && Object.keys(responseData).indexOf('result') > -1
          && Object.keys(responseData).indexOf('response') > -1
          && responseData.result ) {
            const {
              entireCount,
              totalCount,
              positiveWord,
              negativeWord,
              stopWord,
              list
            } = responseData.response;

            listOption.list = list;
            listOption.entireCount = entireCount;
            listOption.totalCount = totalCount;
            representWord.positive = positiveWord;
            representWord.negative = negativeWord;
            representWord.stop = stopWord;
            
          } else {
            throw 'err';
          }
        })
        .catch(err => {
          throw err;
        })
      }
    }

    /** 키워드 등록 */
    const postCreateKeyword = async() => {      
      if (!modalOption.keyword) {
        notify.warning('키워드 명을 입력해주세요');
        return;
      }

      if (!modalOption.kind) {
        notify.warning('키워드 종류를 선택하세요');
        return;
      }

      await KeywordAPI.postCreateKeyword({
        kind: modalOption.kind,
        keyword: modalOption.keyword
      })
      .then((response) => {
        const responseData = response.data;
        if (responseData 
        && typeof responseData === 'object'
        && Object.keys(responseData).indexOf('result') > -1
        && Object.keys(responseData).indexOf('response') > -1
        && responseData.result ) {
          _MessageBox({
            title: '키워드 등록 완료',
            message: '키워드 등록이 완료되었습니다.',
            type: 'info',
            callback(){
              store.commit('modal/closeModal');
              refresh();
            }
          })
        } else {
          throw 'err';
        }
      })
      .catch(error => {
        if (typeof error === 'object'
        && Object.keys(error).indexOf('response') > -1
        && typeof error.response === 'object'
        && Object.keys(error.response).indexOf('status') > -1
        && Object.keys(error.response).indexOf('data') > -1
        && Object.keys(error.response.data).indexOf('response') > -1
        && Object.keys(error.response.data.response).indexOf('message') > -1) {
          const status = error.response.status;
          if (status === 400) {
            if (error.response.data.response.message === 'Keyword already exists.') {
              notify.error('이미 존재하는 키워드 입니다.')
            } else {
              throw 'err';
            }
          } else {
            throw error;
          }
        } else {
          throw error;
        }
      })
      
    }

    /** 키워드 수정 */
    const putEditKeyword = async() => {

      if (!modalOption.keyword) {
        notify.warning('키워드 명을 입력해주세요');
        return;
      }

      if (!modalOption.kind) {
        notify.warning('키워드 종류를 선택하세요');
        return;
      }

      await KeywordAPI.putEditKeyword({
        kind: modalOption.kind,
        keyword: modalOption.keyword.trim(),
        id: modalOption.keywordId
      })
      .then((response) => {
        const responseData = response.data;
        if (responseData 
        && typeof responseData === 'object'
        && Object.keys(responseData).indexOf('result') > -1
        && Object.keys(responseData).indexOf('response') > -1
        && responseData.result ) {
          _MessageBox({
            title: '키워드 수정 완료',
            message: '키워드 수정이 완료되었습니다.',
            type: 'info',
            callback() {
              store.commit('modal/closeModal');
              refresh()
            }
          });
        } else {
          throw 'err';
        }
      })
      .catch((err) => {
        throw err;
      })
    }

    /** 키워드 삭제 */
    const deleteKeyword = async() => {
      await KeywordAPI.deleteKeyword({
        id: modalOption.keywordId
      })
      .then((response) => {
        const responseData = response.data;
        if (responseData 
        && typeof responseData === 'object'
        && Object.keys(responseData).indexOf('result') > -1
        && Object.keys(responseData).indexOf('response') > -1
        && responseData.result ) {
          _MessageBox({
            title: '키워드 삭제 완료',
            message: '키워드 삭제가 완료되었습니다.',
            type: 'info',
            callback() {
              store.commit('modal/closeModal');
              refresh()
            }
          });
        } else {
          throw 'err';
        }
      })
      .catch(err => {
        throw err;
      })
    }


    /** 초기화 */
    const refresh = () => {
      store.commit('loadingBar/showLoadingBar');
      getKeywordList()
      .then(() => {
        store.commit('loadingBar/hideLoadingBar');
      })
      .catch(() => {
        router.push({name: 'error'}).catch(() => {});
      })
    }

    onBeforeMount(() => {
      const {
        page,
        order,
        orderField,
        search,
        date,
        kindOfKeyword
      } = route.query;
      if (Object.keys(route.query).length > 0) {
        listOption.page = Number.parseInt(page ? page.toString() : '1');
        listOption.order = order ? order.toString() : '';
        listOption.orderField = orderField ? orderField.toString() : '';
        listOption.search = search ? search.toString() : '';

        if (kindOfKeyword) {
          listOption.kindOfKeyword = kindOfKeyword;
          tabNow.value = kindOfKeyword.toString();
        }

        if (Array.isArray(date)) {
          const tmpList = [];
          for (const item of date) {
            item && tmpList.push(new Date(item));
          }
          listOption.date = tmpList;
        }
      }
    });
    
    onMounted(() => {
      refresh();
    })

    return {
      selectList,
      tabNow,
      listOption,
      filters,
      modalOption,
      representWord,
      changePage(page: number) {
        listOption.page = page;
        setUrlQueryString();
      },
      handleClickSearch() {
        listOption.page = 1;
        setUrlQueryString();
      },
      sort(orderField: string) {
        if (listOption.orderField === orderField) {
          (listOption.order === 'desc') ? listOption.order = 'asc' : listOption.order = 'desc';
        } else {
          listOption.orderField = orderField;
          listOption.order = 'desc';
        }
        setUrlQueryString();

      },
      getTableTime(date: string) {
        const dateObj:any = new Date(date);
        let result: string = '';
        (!date || dateObj.toString() === 'Invalid Date') 
        ? result = '-'
        : result = filters.dateToString(dateObj, 'tableTime');
        return result;
      },
      getIndex(index: number) {
        const {
          totalCount,
          maxPage,
          page
        } = listOption;
        return totalCount - (maxPage * (page - 1)) - index;
      },
      handleClickTab(tab:any, e: Event ){
        tabNow.value = tab.props.name;
        listOption.kindOfKeyword = tab.props.name;
        resetListOption();
        setUrlQueryString();
      },
      handleClickOpenCreateModal() {
        resetModalOption('create');
        store.commit('modal/openModal');
      },
      handleClickOpenEditModal(row:any) {
        resetModalOption('edit');
        modalOption.kind = '';
        if (row.kind==='긍정') modalOption.kind = 'positive';
        else if (row.kind==='부정') modalOption.kind = 'negative';
        else if (row.kind==='중지') modalOption.kind = 'stop';
        modalOption.keyword = row.keyword;
        modalOption.keywordId = row.id
        store.commit('modal/openModal');
      },
      handleClickCreate() {
        store.commit('loadingBar/showLoadingBar');
        postCreateKeyword()
        .then(() => {
          store.commit('loadingBar/hideLoadingBar');
        })
        .catch(() => {
          router.push({ name: 'error' }).catch(() => {});
        })
      },
      handleClickDelete() {
        _MessageBox.confirm(`'${modalOption.keyword}'(을)를 삭제하시겠습니까?`, '키워드 삭제 안내', {
          confirmButtonText: '삭제',
          cancelButtonText: '취소',
          type: 'warning'
        })
        .then(() => {
          store.commit('loadingBar/showLoadingBar');
          deleteKeyword()
          .then(() => {
            store.commit('loadingBar/hideLoadingBar');
          })
          .catch(() => {
            router.push({ name: 'error' }).catch(() => {});
          })
        })
        .catch(() => {
        })
      },
      handleClickEdit() {
        store.commit('loadingBar/showLoadingBar');
        putEditKeyword()
        .then(() => {
          store.commit('loadingBar/hideLoadingBar');
        })
        .catch(() => {
          router.push({ name: 'error' }).catch(() => {});
        })
      },
      getHeaderStyle(){
        return { 
          backgroundColor:
          tabNow.value === 'positive' 
          ? '#e3fcee' 
          : tabNow.value === 'negative'
          ? '#ffe6e5'
          : tabNow.value === 'stop'
          ? '#dcdfe6'
          : 'white'
          
        }
      }
    }
  }
}
