import instance from '@/api/index';

const MasterAPI = {
  getMasterList: (query: object) => {
    const url = '/account/master/list';
    const params = query;
    const method = 'get';

    return instance({
      url,
      params,
      method,
    });
  },
  getMasterDetail: (query: object) => {
    const url = '/account/master';
    const params = query;
    const method = 'get';

    return instance({
      url,
      params,
      method,
    });
  },
  postCreateMaster: (query: object) => {
    const url = '/account/master';
    const params = query;

    return instance.post(
      url,
      params,
    );
  },
  putEditMaster: (query: object) => {
    const url = '/account/master';
    const params = query;

    return instance.put(
      url,
      params,
    );
  },
  deleteMaster: (query: object) => {
    const url = '/account/master';
    const params =  {
      data: query,
    };

    return instance.delete(
      url,
      params,
    );
  },
};

export default MasterAPI;
