
import { onBeforeMount, onMounted, reactive, watch } from "vue";
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import List from '@/components/List/List.vue';
import filters from '@/utils/filter';
import OrgAPI from '@/api/organization';
import _MessageBox from 'element-plus/lib/el-message-box';
export default {
  name: 'manageOrg',
  components: {
    List,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const selectList: {
      testGroupList: {
        label: string,
        value: any
      }[]
    } = reactive({
      testGroupList: []
    });
    const listOption: {
      maxPage: number,
      totalCount: number,
      page: number,
      list: any[],
      order: string,
      orderField: string,
      testGroup: any[],
      entireCount: number,
      search: string,
      date: any[]
    } = reactive({
      maxPage: 10,
      totalCount: 10,
      page: 1,
      list: [],
      order: '',
      orderField: '',
      testGroup: [],
      entireCount: 0,
      search: '',
      date: []
    });

    /** 검색쿼리 url 저장 */
    const setUrlQueryString = () => {
      const {
        page,
        order,
        orderField,
        testGroup,
        search,
        date
      } = listOption;

      const query:any = {};

      if (page !== 1)
        query.page = page;

      if (Array.isArray(date)) {
        const tmpList = [];
        for (const item of date) {
          item && tmpList.push(filters.dateToString(item, 'yyyy-mm-dd'));
        }
        query.date = tmpList;
      }

      if (order)
        query.order = order;
      
      if (orderField)
        query.orderField = orderField;

      if (search)
        query.search = search;
      
      if (testGroup.length > 0) 
        query.testGroup = testGroup;
      

      router.replace({
        name: 'manageOrg',
        query
      }).catch(() => {});
    }

    /** 전체 체크 fnc */
    const checkAll = () => {
      const tmpList: any[] = []
      for (const item of selectList.testGroupList) {
        tmpList.push(item.value)
      }
      listOption.testGroup = tmpList;
    }

    /** 테스트 셋 그룹 list get api */
    const getTestSetGroupList = async() => {
      await OrgAPI.getTestSetGroupList()
      .then((response) => {
        const responseData = response.data;
        if (responseData 
        && typeof responseData === 'object'
        && Object.keys(responseData).indexOf('result') > -1
        && Object.keys(responseData).indexOf('response') > -1
        && responseData.result ) {
          const {
            list
          } = responseData.response;
          if (Array.isArray(list) && list.length > 0) {
            const tmpList:{
              label: string,
              value: any
            }[] = [
              {
                label: '전체',
                value: 'all'
              },
              {
                label: '그룹 없음',
                value: 0
              }
            ]
            for (const item of list) {
              const itemKeys = Object.keys(item);
              if (itemKeys.indexOf('id') > -1 && itemKeys.indexOf('name') > -1) {
                tmpList.push({
                  label: item.name,
                  value: item.id
                });
              }
            }

            selectList.testGroupList = tmpList;
          }
        }
      })
      .catch(error => {
        if (typeof error === 'object'
        && Object.keys(error).indexOf('response') > -1
        && typeof error.response === 'object'
        && Object.keys(error.response).indexOf('status') > -1
        && Object.keys(error.response).indexOf('data') > -1
        && Object.keys(error.response.data).indexOf('response') > -1
        && Object.keys(error.response.data.response).indexOf('message') > -1) {
          const status = error.response.status;
          if (status === 400) {
            if (error.response.data.response.message === 'Only master can do this.') {
              _MessageBox({
                type: 'error',
                message: '잘못된 접근입니다.',
                callback() {
                  router.push({ name: 'manageCounselee' }).catch(() => {});
                }
              });
              return 1;
            } else {
              throw 'err';
            }
          } else {
            throw error;
          }
        } else {
          throw error;
        }
      })
    }

    /** 기관 list params */
    const getParams = () => {
      const {
        maxPage,
        page,
        date,
        testGroup,
        search,
        orderField,
        order
      } = listOption;
      const params:any = {
        maxPage,
        page,
      };

      if (date && date.length === 2) {
        params.gte = filters.dateToString(date[0], 'yyyy-mm-dd');
        params.lte = filters.dateToString(date[1], 'yyyy-mm-dd');
      } 

      if (Array.isArray(testGroup) && testGroup.length > 0) {
        let tmpList: any[] = [];
        for (const item of testGroup) {
          if ( item === 'all') {
            tmpList = [];
            break;
          } else {
            tmpList.push(item)
          }
        }
        if (tmpList.length > 0)
          params.testSetGroup = JSON.stringify(tmpList)

      }
      
      if (orderField) 
        params.orderField = orderField;
      
      
      if (order) 
        params.order = order;
      

      if (search) 
        params.search = search.replaceAll('-', '');
      

      return params;
    }

    /** 기관 list get api */
    const getOrgList = async() => {
      const params = getParams();
      if (params) {
        await OrgAPI.getOrgList(params)
        .then((response) => {
          const responseData = response.data;
          if (responseData 
          && typeof responseData === 'object'
          && Object.keys(responseData).indexOf('result') > -1
          && Object.keys(responseData).indexOf('response') > -1
          && responseData.result ) {
            const {
              entireCount,
              totalCount,
              // next,
              // previous,
              // startPage,
              // endPage,
              list
            } = responseData.response;
            
            (typeof entireCount === 'number')
            ? listOption.entireCount = entireCount
            : listOption.entireCount = 0;
              
            (typeof totalCount === 'number')
            ? listOption.totalCount = totalCount
            : listOption.totalCount = 0;

            Array.isArray(list)
            ? listOption.list = list
            : listOption.list = [];
            
          } else {
            throw 'err';
          }
        })
        .catch(error => {
          if (typeof error === 'object'
          && Object.keys(error).indexOf('response') > -1
          && typeof error.response === 'object'
          && Object.keys(error.response).indexOf('status') > -1
          && Object.keys(error.response).indexOf('data') > -1
          && Object.keys(error.response.data).indexOf('response') > -1
          && Object.keys(error.response.data.response).indexOf('message') > -1) {
            const status = error.response.status;
            if (status === 400) {
              if (error.response.data.response.message === 'Only admin can do this.') {
                _MessageBox({
                  type: 'error',
                  message: '잘못된 접근입니다.',
                  callback() {
                    router.push({ name: 'manageCounselee' }).catch(() => {});
                  }
                });
                return 1;
              } else {
                throw 'err';
              }
            } else {
              throw error;
            }
          } else {
            throw error;
          }
        })
      }
    }

    /** 초기화 */
    const refresh = () => {
      store.commit('loadingBar/showLoadingBar');
      Promise.all([
        getTestSetGroupList(),
        getOrgList()
      ])
      .then(() => {
        
      })
      .catch(() => {
        router.push({name: 'error'}).catch(() => {});
      })
      .finally(() => {
        store.commit('loadingBar/hideLoadingBar');
      })
    }

    onBeforeMount(() => {
      const {
        page,
        order,
        orderField,
        search,
        testGroup,
        date
      } = route.query;

      if (Object.keys(route.query).length > 0) {
        listOption.page = Number.parseInt(page ? page.toString() : '1');
        listOption.order = order ? order.toString() : '';
        listOption.orderField = orderField ? orderField.toString() : '';
        listOption.search = search ? search.toString() : '';
        
        if (Array.isArray(testGroup)) {
          const tmpList = [];
          for (const item of testGroup) {
            if (item) {
              const tmpNum = Number.parseInt(item);
              (isNaN(tmpNum)) 
              ? tmpList.push(item)
              : tmpList.push(tmpNum);
            }
          }
          listOption.testGroup = tmpList;
        } else if (typeof testGroup === 'string' && testGroup !== '') {
          const tmpNum = Number.parseInt(testGroup);
          isNaN(tmpNum)
          ? listOption.testGroup = [testGroup]
          : listOption.testGroup = [tmpNum];
        }

        if (Array.isArray(date)) {
          const tmpList = [];
          for (const item of date) {
            item && tmpList.push(new Date(item));
          }
          listOption.date = tmpList;
        }
      }
    });

    onMounted(() => {
      refresh();
    })

    watch(() => listOption.testGroup, (testGroup: any, prevTestGroup: any) => {
      if (selectList.testGroupList.length > 0) {
        const checkAllIndex = testGroup.indexOf('all');
        const prevCheckAllIndex = prevTestGroup.indexOf('all'); 
        if ( checkAllIndex > -1 
        && prevCheckAllIndex < 0) {
          checkAll()
        } else if (checkAllIndex < 0 
        && prevCheckAllIndex > -1
        && testGroup.length === (selectList.testGroupList.length -1)) {
          listOption.testGroup = [];
        } else if (checkAllIndex < 0
        && prevCheckAllIndex < 0
        && testGroup.length === (selectList.testGroupList.length -1)) {
          checkAll()
        } else if (checkAllIndex > -1
        && prevCheckAllIndex > -1
        && testGroup.length === (selectList.testGroupList.length -1)) {
          const tmpList: any [] = []
          for (const item of testGroup) {
            if (item !== 'all') {
              tmpList.push(item)
            }
          }
          listOption.testGroup = tmpList;
        }
      }
    });

    return {
      listOption,
      filters,
      selectList,
      getIndex(index: number){
        const {
          totalCount,
          maxPage,
          page
        } = listOption;
        return totalCount - (maxPage * (page - 1)) - index;
      },
      getTableTime(date: string) {
        const dateObj:any = new Date(date);
        let result: string = '';
        (!date || dateObj.toString() === 'Invalid Date') 
        ? result = '-'
        : result = filters.dateToString(dateObj, 'tableTime');
        return result;
      },
      handleClickCreateOrg() {
        router.push({ 
          name: 'createEditOrg',
          params: {
            status: 0,
            id: 0
          },
          query: route.query
        }).catch(() => {});
      },
      sort(orderField: string) {
        if (listOption.orderField === orderField) {
          (listOption.order === 'desc') ? listOption.order = 'asc' : listOption.order = 'desc';
        } else {
          listOption.orderField = orderField;
          listOption.order = 'desc';
        }
        setUrlQueryString();
      },
      handleRowClick(row: any) {
        router.push({
          name: 'createEditOrg', 
          params: {
            status: 1,
            id: row.id, 
          },
          query: route.query
        }).catch(() => {})
      },
      handleClickSearch() {
        listOption.page = 1;
        setUrlQueryString();
      },
      changePage(page: number) {
        listOption.page = page;
        setUrlQueryString();
      },
      handleClickCounselee (row:any) {
        router.push({ 
          name: 'manageCounselee', 
          query: {
            organization: row.id
          }
        })
      },
      handleClickCounselor (row:any) {
        router.push({ 
          name: 'manageUser', 
          query: {
            agency: row.id
          }
        })
      },


    }
  }
}
