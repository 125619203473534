import axios from 'axios';
import { getCookie, deleteCookie } from '@/utils/cookie';

const instance = axios.create({
  baseURL: `${process.env.VUE_APP_BASE_URL}/api/v1`,
});

instance.interceptors.request.use(
  (config) => {
    const cookie = getCookie('Authorization');
    if (cookie
      && cookie !== ''
      && (!config.headers.Authorization
      || config.headers.Authorization !== '')) {
      config.headers['Content-Type'] = 'application/json';
      config.headers.Authorization = `Token ${cookie}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // if (typeof error === 'object'
    // && Object.keys(error).indexOf('response') > -1
    // ) {
    //   const currentPath = window.location.href.toString();
    //   const {
    //     response: { status };
    //   } = error;
    // }
    if (typeof error === 'object'
    && Object.keys(error).indexOf('response') > -1
    && typeof error.response === 'object'
    && Object.keys(error.response).indexOf('status') > -1) {
      const status = error.response.status;
      if (status === 401) {
        deleteCookie('Authorization');
        deleteCookie('organizationId');
        deleteCookie('userLevel');
        deleteCookie('userName');
        deleteCookie('userId');
        deleteCookie('userType');
        window.location.href = '/login';
      } else if (status !== 400) {
        window.location.href = '/error';
      }
    } else {
      window.location.href = '/error';
    }
    return Promise.reject(error);
  },
);

export default instance;
