import instance from '@/api/index';

const CounseleeAPI = {
  getCounseleeList: (query: object) => {
    const url = 'account/counselee/list';
    const params = query;
    const method = 'get';

    return instance({
      url,
      params,
      method,
    });
  },
  getOrganizationList: () => {
    const url = 'account/organization';
    const method = 'get';

    return instance({
      url,
      method,
    });
  },
  getOrgCounselorList: (query: object) => {
    const url = 'account/organization/counselor';
    const params = query;
    const method = 'get';

    return instance({
      url,
      params,
      method,
    });
  },
  postCreateCounselee: (query: object) => {
    const url = 'account/counselee';
    const params = query;

    return instance.post(
      url,
      params,
    );
  },
  putEditCounseleeInfo: (query: object) => {
    const url = 'account/counselee';
    const params = query;

    return instance.put(
      url,
      params,
    );
  },
  getCounseleeDetail: (query: object) => {
    const url = 'account/counselee';
    const params = query;
    const method = 'get';

    return instance({
      url,
      params,
      method,
    });
  },
  getTestHistoryList: (query: object) => {
    const url = 'counsel/test/history/list';
    const params = query;
    const method = 'get';

    return instance({
      url,
      params,
      method,
    });
  },
  getTestHistoryDetail: (query: object) => {
    const url = 'counsel/test/history';
    const params = query;
    const method = 'get';

    return instance({
      url,
      params,
      method,
    });
  },
  getTestHistoryDetailList: (query: object) => {
    const url = 'counsel/test/history/detail';
    const params = query;
    const method = 'get';

    return instance({
      url,
      params,
      method,
    });
  },
  getTestAction: (query: object) => {
    const url = 'counsel/test/care';
    const params = query;
    const method = 'get';

    return instance({
      url,
      params,
      method,
    });
  },
  postCreateAction: (query: object) => {
    const url = 'counsel/test/care';
    const params = query;

    return instance.post(
      url,
      params,
    );
  },
  getTestDetailList: (query: object) => {
    const url = 'counsel/test/history/detail/list';
    const params = query;
    const method = 'get';

    return instance({
      url,
      params,
      method,
    });
  },
  getDownloadAudioAll: (query: object) => {
    const url = 'counsel/test/history/audio/download';
    const data = query;
    const method = 'post';
    const responseType = 'blob';

    return instance({
      url,
      data,
      method,
      responseType,
    });
  },
  putEditTestCounselor: (query: object) => {
    const url = '/counsel/test/history';
    const params = query;

    return instance.put(
      url,
      params,
    );
  }
};

export default CounseleeAPI;
