
import { useRouter } from 'vue-router'

export default {
  name: 'Footer',
  setup() {
    const router = useRouter();
    return {
      acrylInc: '© 2021 Acryl inc. All rights reserved.',
      goManageOrg() {
        router.push({ name: 'manageOrg' }).catch(() => {});
      },
      goManageMaster() {
        router.push({ name: 'manageMaster' }).catch(() => {});
      },
      goManageSetGroup() {
        router.push({ name: 'manageSetGroup'}).catch(() => {});
      },
      goManageTestSet() {
        router.push({ name: 'manageTestSet'}).catch(() => {});
      },
      goManageStatistics() {
        router.push({ name: 'counselorCounseleeStatistics' }).catch(() => {});
      },
      goManageKeyword() {
        router.push({ name: 'manageKeyword' }).catch(() => {});
      }
    }
  }

}
