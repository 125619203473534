
import { onBeforeMount, onMounted, reactive } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import List from '@/components/List/List.vue';
import filters from '@/utils/filter';
import Modal from '@/components/Modal/Modal.vue';
import AppAPI from '@/api/app';
import _MessageBox from 'element-plus/lib/el-message-box';
import notify from '@/utils/notify';
import { koreanRegex } from '@/utils/regex';
export default {
  name: 'manageApp',
  components: {
    List,
    Modal
  },
  setup(){
    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    const selectList = [
      {
        label: '전체',
        value: ''
      },
      {
        label: '활성',
        value: 1
      },
      {
        label: '비활성',
        value: 0
      }
    ];

    const videoFlagList = [
      {
        label: '전체',
        value: ''
      },
      {
        label: '활성',
        value: true
      },
      {
        label: '비활성',
        value: false
      }
    ];

    const listOption: {
      date: any[],
      search: string,
      page: number,
      maxPage: number,
      totalCount: number,
      list: any[],
      order: string,
      orderField: string,
      entireCount: number,
      status: any
    } = reactive({
      date: [],
      search: '',
      page: 1,
      maxPage: 10,
      totalCount: 0,
      list: [],
      order: '',
      orderField: '',
      entireCount: 0,
      status: null
    });

    const modalOption: {
      title: string,
      version: string,
      memo: string,
      apk: any,
      type: string,
      fileName: string,
      appId: number,
      status: any,
      videoFlag: boolean
    } = reactive({
      title: '앱 등록',
      version: '',
      memo: '',
      apk: null,
      type: 'create',
      fileName: '',
      appId: 0,
      status: 1,
      videoFlag: true
    });

    /** 검색쿼리 url 저장 */
    const setUrlQueryString = () => {
      const {
        page,
        order,
        orderField,
        date,
        search,
        status
      } = listOption;

      const query:any = {};

      if (search)
        query.search = search;

      if (page !== 1)
        query.page = page;

      if (order)
        query.order = order;
      
      if (orderField)
        query.orderField = orderField;

      if (Array.isArray(date)) {
        const tmpList = [];
        for (const item of date) {
          item && tmpList.push(filters.dateToString(item, 'yyyy-mm-dd'));
        }
        query.date = tmpList;
      }


      if (status !== null && status !== 'null') 
        query.status = status;
      
      
      router.replace({
        name: 'manageApp',
        query
      }).catch(() => {});
    }


    /** 앱 get list params */
    const getParams = () => {
      const {
        maxPage,
        page,
        orderField,
        order,
        date,
        search,
        status
      } = listOption

      const params:any = {
        maxPage,
        page,
      }

      if (orderField) 
        params.orderField = orderField;
      
      if (order) 
        params.order = order;
      
      if (date && date.length === 2) {
        params.gte = filters.dateToString(date[0], 'yyyy-mm-dd');
        params.lte = filters.dateToString(date[1], 'yyyy-mm-dd');
      } 

      if (search) 
        params.search = search;
      
      if (status !== null && status !== 'null' && status.toString() !== 'NaN') {
        params.status = JSON.stringify([status]);
      }

      return params;
    }

    /** 앱 get list api */
    const getAppList = async() => {
      const params = getParams();
      if (params) {
        await AppAPI.getAppList(params)
        .then((response) => {
          const responseData = response.data;
          if (responseData 
          && typeof responseData === 'object'
          && Object.keys(responseData).indexOf('result') > -1
          && Object.keys(responseData).indexOf('response') > -1
          && responseData.result ) {
            const {
              entireCount,
              totalCount,
              // next,
              // previous,
              // startPage,
              // endPage,
              list
            } = responseData.response;

            listOption.entireCount = entireCount;
            listOption.totalCount = totalCount;
            listOption.list = list;


          } else {
            throw 'err';
          }
        })
        .catch(error => {
          if (typeof error === 'object'
          && Object.keys(error).indexOf('response') > -1
          && typeof error.response === 'object'
          && Object.keys(error.response).indexOf('status') > -1
          && Object.keys(error.response).indexOf('data') > -1
          && Object.keys(error.response.data).indexOf('response') > -1
          && Object.keys(error.response.data.response).indexOf('message') > -1) {
            const status = error.response.status;
            if (status === 400) {
              if (error.response.data.response.message === 'Only admin can do this.') {
                _MessageBox({
                  type: 'error',
                  message: '잘못된 접근입니다.',
                  callback() {
                    router.push({ name: 'manageCounselee' }).catch(() => {});
                  }
                });
                return 1;
              } else {
                throw 'err';
              }
            } else {
              throw error;
            }
          } else {
            throw error;
          }
        })
      }
    }

    /** 앱 등록 api */
    const postCreateApp = async() => {
      const {
        version,
        memo,
        apk,
        status,
        videoFlag
      } = modalOption;

      if (version === '' || apk === null) {
        notify.warning('앱 버전과 apk 파일은 필수항목입니다.');
        return;
      }

      if (koreanRegex.test(version)) {
        notify.warning('버전정보에 한글은 입력할 수 없습니다.');
        return;
      }

      const params = new FormData();
      params.append('apk', apk);
      params.append('version', version);
      params.append('content', memo);
      params.append('status', status);
      params.append('videoFlag', videoFlag.toString());


      await AppAPI.postCreateApp(params)
      .then((response) => {
        const responseData = response.data;
        if (responseData 
        && typeof responseData === 'object'
        && Object.keys(responseData).indexOf('result') > -1
        && responseData.result ) {
          _MessageBox({
            title: '앱 등록 완료',
            message: '앱 등록이 완료되었습니다. ',
            type: 'info',
            callback() {
              store.commit('modal/closeModal');
            }
          })
        } else {
          throw 'err';
        }
      })
      .catch(error => {
        if (typeof error === 'object'
        && Object.keys(error).indexOf('response') > -1
        && typeof error.response === 'object'
        && Object.keys(error.response).indexOf('status') > -1
        && Object.keys(error.response).indexOf('data') > -1
        && Object.keys(error.response.data).indexOf('response') > -1
        && Object.keys(error.response.data.response).indexOf('message') > -1) {
          const status = error.response.status;
          if (status === 400) {
            if (error.response.data.response.message === 'Only admin can do this.') {
              _MessageBox({
                type: 'error',
                message: '잘못된 접근입니다.',
                callback() {
                  router.push({ name: 'manageCounselee' }).catch(() => {});
                }
              });
              return 1;
            } else if (error.response.data.response.message === "App version already exists.") {
              notify.error('이미 존재하는 버전입니다.');
              return 0;
            } else {
              throw 'err';
            }
          } else {
            throw error;
          }
        } else {
          throw error;
        }
      })
    }

    /** apk 파일 다운로드 */
    const downloadAPK = async(fileURL: string, fileName: string) => {
      if (fileURL) {
        await fetch(`${process.env.VUE_APP_BASE_URL}${fileURL}`)
        .then((response) => response.blob())
        .then((blobObj) => {
          const link: any = window.document.createElement('a');
          const objURL = URL.createObjectURL(blobObj);
          link.href = objURL;
          link.download = fileName;
          link.click();
          document.body.appendChild(link);
          setTimeout(() => {
            document.body.removeChild(link);
            URL.revokeObjectURL(objURL)
          }, 100)
        })
      }
    }

    /** 앱 삭제 */
    const deleteApp = async() => {
      await AppAPI.deleteApp({id: modalOption.appId})
      .then((response) => {
        const responseData = response.data;
        if (responseData 
        && typeof responseData === 'object'
        && Object.keys(responseData).indexOf('result') > -1
        && responseData.result ) {
          _MessageBox({
            title: '앱 삭제 완료',
            message: '앱 삭제가 완료되었습니다. ',
            type: 'info',
          })
        } else {
          throw 'err';
        }
      })
      .catch(error => {
        if (typeof error === 'object'
        && Object.keys(error).indexOf('response') > -1
        && typeof error.response === 'object'
        && Object.keys(error.response).indexOf('status') > -1
        && Object.keys(error.response).indexOf('data') > -1
        && Object.keys(error.response.data).indexOf('response') > -1
        && Object.keys(error.response.data.response).indexOf('message') > -1) {
          const status = error.response.status;
          if (status === 400) {
            if (error.response.data.response.message === 'Only admin can do this.') {
              _MessageBox({
                type: 'error',
                message: '잘못된 접근입니다.',
                callback() {
                  router.push({ name: 'manageCounselee' }).catch(() => {});
                }
              });
              return 1;
            } else {
              throw 'err';
            }
          } else {
            throw error;
          }
        } else {
          throw error;
        }
      })
    }

    /** 
     * 앱 수정 
     * @augments
     *  id
     *  version
     *  content
     *  apk 파일 유지 시에는 apk 속성 없이 요청
     *  status
     * */
    const putEditApp = async() => {
      const {
        appId,
        version,
        memo,
        apk,
        status,
        videoFlag
      } = modalOption;

      const params = new FormData();

      if (version === '') {
        notify.warning('버전정보를 입력해주세요');
        return;
      }

      if (apk !== null) {
        params.append('apk', apk);
      }

      params.append('id', appId.toString());
      params.append('content', memo);
      params.append('version', version);
      params.append('status', status);
      params.append('videoFlag', videoFlag.toString());

      await AppAPI.putEditApp(params)
      .then((response) => {
        const responseData = response.data;
        if (responseData 
        && typeof responseData === 'object'
        && Object.keys(responseData).indexOf('result') > -1
        && responseData.result ) {
          _MessageBox({
            title: '앱 수정 완료',
            message: '앱 수정이 완료되었습니다. ',
            type: 'info',
          })
        } else {
          throw 'err';
        }
      })
      .catch(error => {
        if (typeof error === 'object'
        && Object.keys(error).indexOf('response') > -1
        && typeof error.response === 'object'
        && Object.keys(error.response).indexOf('status') > -1
        && Object.keys(error.response).indexOf('data') > -1
        && Object.keys(error.response.data).indexOf('response') > -1
        && Object.keys(error.response.data.response).indexOf('message') > -1) {
          const status = error.response.status;
          if (status === 400) {
            if (error.response.data.response.message === 'Only master can do this.') {
              _MessageBox({
                type: 'error',
                message: '잘못된 접근입니다.',
                callback() {
                  router.push({ name: 'manageCounselee' }).catch(() => {});
                }
              });
              return 1;
            } else {
              throw 'err';
            }
          } else {
            throw error;
          }
        } else {
          throw error;
        }
      })


      
    }

    /** 초기화 */
    const refresh = () => {
      store.commit('loadingBar/showLoadingBar');
      getAppList()
      .then(() => {
        store.commit('loadingBar/hideLoadingBar')
      })
      .catch(() => {
        router.push({ name: 'error' }).catch(() => {});
      })
    }

    onBeforeMount(() => {
      const {
        page,
        order,
        orderField,
        search,
        date,
        status
      } = route.query;

      if (Object.keys(route.query).length > 0) {
        listOption.page = Number.parseInt(page ? page.toString() : '1');
        listOption.order = order ? order.toString() : '';
        listOption.orderField = orderField ? orderField.toString() : '';
        listOption.search = search ? search.toString() : '';
        listOption.status = (typeof status === 'string' && status !== '') ?  Number.parseInt(status.toString()) : null;
        
        if (Array.isArray(date)) {
          const tmpList = [];
          for (const item of date) {
            item && tmpList.push(new Date(item));
          }
          listOption.date = tmpList;
        }
      }
    });

    onMounted(() => {
      refresh();
    })



    return {
      listOption,
      modalOption,
      selectList,
      videoFlagList,
      filters,
      handleClickDownload(row: any) {
        store.commit('loadingBar/showLoadingBar');
        downloadAPK(row.apk, row.fileName)
        .then(() => {
          store.commit('loadingBar/hideLoadingBar')
        })
        .catch(() => {
          router.push({ name: 'error' }).catch(() => {});
        })

      },
      sort(orderField: string) {
        if (listOption.orderField === orderField) {
          (listOption.order === 'desc') ? listOption.order = 'asc' : listOption.order = 'desc';
        } else {
          listOption.orderField = orderField;
          listOption.order = 'desc';
        }
        setUrlQueryString();
      },
      changePage(page: number) {
        listOption.page = page;
        setUrlQueryString();
      },
      getTableTime(date: string) {
        const dateObj:any = new Date(date);
        let result: string = '';
        (!date || dateObj.toString() === 'Invalid Date') 
        ? result = '-'
        : result = filters.dateToString(dateObj, 'tableTime');
        return result.replaceAll('<br/>', ' ');
      },
      handleClickApp(row:any){
        modalOption.status = row.status;
        modalOption.videoFlag = row.videoFlag == 'True'? true : false;
        modalOption.type = 'edit';
        modalOption.title = '앱 수정';
        modalOption.version = row.version;
        modalOption.memo = row.content;
        modalOption.apk = null;
        modalOption.fileName = row.fileName;
        modalOption.appId = row.id;
        store.commit('modal/openModal');
        
      },
      getIndex(index: number){
        const {
          totalCount,
          maxPage,
          page
        } = listOption;
        return totalCount - (maxPage * (page - 1)) - index;
      },
      handleClickCreateApp() {
        modalOption.status = 1;
        modalOption.videoFlag = true;
        modalOption.type = 'create';
        modalOption.title = '앱 등록';
        modalOption.version = '';
        modalOption.memo = '';
        modalOption.apk = null;
        modalOption.fileName = '';
        modalOption.appId = 0;
        store.commit('modal/openModal');

      },
      handleChangeFile(e:any){
        const tmpFile:any = e.target.files[0];
        if (tmpFile && tmpFile.type === 'application/vnd.android.package-archive') {
          modalOption.fileName = tmpFile.name;
          modalOption.apk = tmpFile;
        } else {
          notify.warning('파일 형식 등을 확인해주세요');
        }
      },
      handleClickSave(){
        if(modalOption.version === '') {
          notify.warning('버전을 입력해주세요');
          return;
        }
        if (modalOption.apk === null) {
          notify.warning('파일을 선택해주세요');
          return;
        }

        store.commit('loadingBar/showLoadingBar')
        postCreateApp()
        .then((res:any) => {
          if (res) 
            store.commit('modal/closeModal');
          
          store.commit('loadingBar/hideLoadingBar');
          refresh();
        })
        .catch(() => {
          router.push({ name: 'error' }).catch(() => {})
        })
      },
      handleClickDelete(){
        _MessageBox.confirm(`${modalOption.version} 버전을 삭제하시겠습니까?`, '앱 삭제 안내', {
          confirmButtonText: '삭제',
          cancelButtonText: '취소',
          type: 'warning'
        })
        .then(() => {
          store.commit('loadingBar/showLoadingBar');
          deleteApp()
          .then(() => {
            store.commit('modal/closeModal');
            store.commit('loadingBar/hideLoadingBar');
            refresh();
          })
        })
        .catch(() => {
          refresh();
        })
      },
      handleClickEdit(){
        store.commit('loadingBar/showLoadingBar');
        putEditApp()
        .then(() => {
          store.commit('loadingBar/hideLoadingBar');
          store.commit('modal/closeModal');
          refresh();
        })
        .catch(() => {
          router.push({ name: 'error' }).catch(() => {});
        })
      },
      handleClickSearch() {
        listOption.page = 1;
        setUrlQueryString();
      },
    }
  }
}
