<template>
  <div class="counselee-test-wrapper">
    <div class="counselee-test-info-wrapper">
      <label>{{'테스트 상세 기록'}}</label>
      <table class="counselee-test-info-table">
        <label>{{'테스트 정보'}}</label>
        <tr>
          <th>상담자</th>
          <td>{{ counseleeInfoData.counselor }}</td>
          <th>테스트 일자</th>
          <td>{{ getTableTime(counseleeInfoData.testDate) }}</td>
          <th>테스트 결과</th>
          <td>
            <el-button
              v-if="counseleeInfoData.testResult === 0"
              type="success"
              class="medium"
              plain
            >
              {{'정기 상담 권유'}}
            </el-button>
            <el-button
              v-else-if="counseleeInfoData.testResult === 1"
              type="danger"
              class="medium"
              plain
            >
              {{'조기 상담 권유'}}
            </el-button>
            <el-button
              v-else-if="counseleeInfoData.testResult === 2"
              type="warning"
              class="medium"
              plain
            >
              {{'응급 상담 권유'}}
            </el-button>
            <div v-else
            >{{ '-' }}</div>
          </td>
        </tr>
      </table>
    </div>
    <div class="counselee-test-button-wrapper">
      <el-button
        type="primary"
        @click="handleClickDownloadAudioAll"
        :disabled="listOption.list.length === 0"
      >
        {{'전체 음성 파일 다운로드'}}
      </el-button>
    </div>
    <List
      :selectedPage="listOption.page"
      :maxPage="listOption.maxPage"
      :totalCount="listOption.totalCount"
      :pagination="false"
      @changePage="changePage"
    >
      <template v-slot:list-table>
        <el-table
          :data="listOption.list"
        >
          <el-table-column
            align ="center"
            width="50"
            label = "No"
            type="index"
            :index="getIndex"
            fixed
          />
          <el-table-column
            align ="center"
            prop="itemNo"
            width="150"
          >
            <template #header>
              {{'질문번호'}}
              <span 
                class="icon pointer"
                @click="sort('itemNo')"
              >
                <i 
                  v-if="(listOption.orderField === 'itemNo' 
                    && listOption.order === 'desc') 
                    ? true: false" 
                  class='el-icon-caret-top'
                />
                <i v-else class="el-icon-caret-bottom"/>
              </span>
            </template>
          </el-table-column>
          <el-table-column
            align ="center"
            prop="content"
          >
            <template #header>
              {{'질문'}}
              <span 
                class="icon pointer"
                @click="sort('content')"
              >
                <i 
                  v-if="(listOption.orderField === 'content' 
                    && listOption.order === 'desc') 
                    ? true: false" 
                  class='el-icon-caret-top'
                />
                <i v-else class="el-icon-caret-bottom"/>
              </span>
            </template>
          </el-table-column>
          <el-table-column
            align ="center"
            prop="answer"
            width="120"
          >
            <template #header>
              {{'답변'}}
              <span 
                class="icon pointer"
                @click="sort('answer')"
              >
                <i 
                  v-if="(listOption.orderField === 'answer' 
                    && listOption.order === 'desc') 
                    ? true: false" 
                  class='el-icon-caret-top'
                />
                <i v-else class="el-icon-caret-bottom"/>
              </span>
            </template>
          </el-table-column>
          <el-table-column
            align ="center"
            prop="tts"
            label="TTS"
            width="120"
          >
          </el-table-column>
          <el-table-column
            align ="center"
            label="음성재생"
            width="330"
          >
            <template #default="scope">
              <audio controls>
                <source :src="`${envURL}${scope.row.audio}`" type="audio/mpeg"/>
              </audio>
            </template>
          </el-table-column>
        </el-table>
      </template>
    </List>
  </div>
</template>

<script lang="ts">
import { reactive, ref } from '@vue/reactivity';
import { onBeforeMount } from '@vue/runtime-core';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import List from '@/components/List/List.vue';
import CounseleeAPI from '@/api/counselee';
import filters from '@/utils/filter';

export default {
  name: 'counseleeTestDetail',
  components: {
    List
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const counseleeInfoData: {
      counselor: string,
      testDate: string,
      testResult: any
    } = reactive({
      counselor: '',
      testDate: '',
      testResult: null
    });
    const envURL = ref(process.env.VUE_APP_BASE_URL);
    const listOption: {
      maxPage: number,
      totalCount: number,
      page: number,
      list: {
        id: number,
        itemNo: number,
        content: string,
        answer: string,
        tts: string,
        audio: any
      }[],
      order: string,
      orderField: string
    } = reactive({
      maxPage: 999,
      totalCount: 0,
      page: 1,
      list: [],
      order: '',
      orderField: ''
    })

    /** 리스트 No */
    const getIndex = (index: number) => {
      const {
        totalCount,
        maxPage,
        page
      } = listOption;
      return totalCount - (maxPage * (page - 1)) - index;
    }

    /** 날짜 포맷 fnc */
    const getTableTime = (date: string) => {
      const dateObj:any = new Date(date);
      let result: string = '';
      (!date || dateObj.toString() === 'Invalid Date') 
      ? result = '-'
      : result = filters.dateToString(dateObj, 'tableTime');
      return result.replace('<br/>', ' ');
    }

    /** 리스트용 파람생성 fnc */
    const getParams = () => {
      const params: any = {
        id: route.params.id,
        page: listOption.page,
        maxPage: listOption.maxPage,
      }

      if (listOption.order) {
        params.order = listOption.order;
      }

      if (listOption.orderField) {
        params.orderField = listOption.orderField;
      }

      return params
    }

    /** 테스트 정보 조회 api */
    const getTestHitoryDetail = async() => {
      const params = {
        id: route.params.id
      }
      await CounseleeAPI.getTestHistoryDetail(params)
      .then((response) => {
        const responseData = response.data
        if (responseData 
        && typeof responseData === 'object'
        && Object.keys(responseData).indexOf('result') > -1
        && Object.keys(responseData).indexOf('response') > -1
        && responseData.result ) {
          const {
            // id,
            counselee,
            counselor,
            testDate,
            testResult
          } = responseData.response;

          /** 잘못된 이름url로 들어올 경우 */
          if (route.params.name !== counselee) {
            router.replace({
              name: 'testDetail',
              params: {
                counseleeId: route.params.counseleeId,
                id: route.params.id,
                name: counselee
              }
            })
            .catch(() => {})
          }

          if (typeof counselor === 'string') {
            counseleeInfoData.counselor = counselor;
          }

          if (typeof testDate === 'string') {
            counseleeInfoData.testDate = testDate;
          }

          if (typeof testResult === 'string') {
            counseleeInfoData.testResult = Number.parseInt(testResult);
          } else if (typeof testResult === 'number') {
            counseleeInfoData.testResult = testResult;
          }
        } else {
          throw 'error';
        }
      }).catch((err) => {
        throw err;
      })
    }

    /** 태스트 상세내역 list get api */
    const getTestDetailList = async() => {
      const params = getParams();
      await CounseleeAPI.getTestDetailList(params)
      .then((response) => {
        const responseData = response.data
        if (responseData 
        && typeof responseData === 'object'
        && Object.keys(responseData).indexOf('result') > -1
        && Object.keys(responseData).indexOf('response') > -1
        && responseData.result ) {
          const {
            totalCount,
            next,
            previous,
            startPage,
            endPage,
            list
          } = responseData.response;


          listOption.list = list;
          listOption.totalCount = totalCount;
        } else {
          throw 'error';
        }
      })
      .catch((err) => {
        throw err;
      })
    }


    /** 전체 음성 파일 다운로드 */
    const downloadAudioAll = async() => {
      const params = {
        id: Number.parseInt(route.params.id.toString())
      };
      await CounseleeAPI.getDownloadAudioAll(params)
      .then((response) => {
        const responseData = response.data;
        if (route.name === 'testDetail' && responseData) {
          const blob = new Blob([responseData], {type: "application/zip"})
          const a = window.document.createElement("a")

          a.href = window.URL.createObjectURL(blob)
          a.download = `${route.params.name}_전체음성데이터_${filters.dateToString(new Date(), 'yyyy-mm-dd hh:mm:ss').replaceAll('-', '').replaceAll(':', '').replaceAll(' ', '')}.zip`;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        } else {
          throw 'error';
        }
      })
      .catch((err) => {
        throw err;
      })
    }

    /** 전체 음성 파일 다운로드 클릭 */
    const handleClickDownloadAudioAll = () => {
      store.commit('loadingBar/showLoadingBar');
      downloadAudioAll()
      .then(() => {
        store.commit('loadingBar/hideLoadingBar');
      })
      .catch(() => {
        router.push({name: 'error'}).catch(() => {});
      })
    }

    /** 개별 음성 다운로드 */
    const downloadAudio = async(fileURL: string, no: string) => {
      if (fileURL) {
        await fetch(`${process.env.VUE_APP_BASE_URL}${fileURL}`)
        .then((response) => response.blob())
        .then((blobObj) => {
          const link: any = window.document.createElement('a');
          const objURL = URL.createObjectURL(blobObj);
          link.href = objURL;
          link.download = `${route.params.name}_${no}.mp3`;
          link.click();
          document.body.appendChild(link);
          setTimeout(() => {
            document.body.removeChild(link);
            URL.revokeObjectURL(objURL)
          }, 100)
        })
      }
    }

    /** 다운로드 버튼 클릭 */
    const handleClickDownload = (fileURL: string, no: string) => {
      store.commit('loadingBar/showLoadingBar');
      downloadAudio(fileURL, no)
      .then(() => {
        store.commit('loadingBar/hideLoadingBar')
      })
      .catch(() => {
        router.push({name: 'error'}).catch(() => {})
      })
    }

    /** 리스트 정렬 */
    const sort = (orderField: string) => {
      if (listOption.orderField === orderField) {
        (listOption.order === 'desc') ? listOption.order = 'asc' : listOption.order = 'desc';
      } else {
        listOption.orderField = orderField;
        listOption.order = 'desc';
      }
      store.commit('loadingBar/showLoadingBar');
      getTestDetailList()
      .then(() => {
        store.commit('loadingBar/hideLoadingBar');
      })
      .catch(() => {
        router.push({ name: 'error' }).catch(() => {});
      })
    }

    /** 페이지 변경 */
    const changePage = (page: number) => {
      listOption.page = page;
      store.commit('loadingBar/showLoadingBar');
      getTestDetailList()
      .then(() => {
        store.commit('loadingBar/hideLoadingBar');
      })
      .catch(() => {
        router.push({ name: 'error' }).catch(() => {});
      })
    }

    /** 페이지 초기화 */
    const refresh = () => {
      store.commit('loadingBar/showLoadingBar');
      Promise.all([
        getTestDetailList(),
        getTestHitoryDetail()
      ])
      .then(() => {
        store.commit('loadingBar/hideLoadingBar');
      })
      .catch(() => {
        router.push({ name: 'error' }).catch(() => {});
      })
    }

    onBeforeMount(() => {
      refresh();
    })


    return {
      envURL,
      counseleeInfoData,
      changePage,
      listOption,
      getTableTime,
      sort,
      handleClickDownloadAudioAll,
      handleClickDownload,
      getIndex
    }
  }
}
</script>