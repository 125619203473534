import { ActionContext } from 'vuex';

interface State {
  visible: boolean;
}

export default {
  namespaced: true,
  state: {
    visible: false,
  },
  actions: {
    showLoadingBar({commit}: ActionContext<null, null>) {
      commit('showLoadingBar');
    },
    hideLoadingBar({commit}: ActionContext<null, null>) {
      commit('hideLoadingBar');
    },
  },
  mutations: {
    showLoadingBar(state: State) {
      state.visible = true;
    },
    hideLoadingBar(state: State) {
      state.visible = false;
    },
  },
  getters: {
    visible: (state: State) => (state.visible),
  },
};
