import instance from '@/api/index';

const StatisticsAPI = {
  getOrgCounselorCounseleeStatistics: (query: object) => {
    const url = '/statistics/organization/user';
    const params = query;
    const method = 'get';

    return instance({
      url,
      params,
      method,
    });
  },
  getOrgCounselorCounseleeStatisticsExcel: (query: object) => {
    const url = '/statistics/organization/user';
    const params = query;
    const method = 'get';
    const responseType = 'blob';

    return instance({
      url,
      params,
      method,
      responseType,
    });
  },
  getOrgTestStatistics: (query: object) => {
    const url = '/statistics/organization/test';
    const params = query;
    const method = 'get';

    return instance({
      url,
      params,
      method,
    });
  },
  getOrgTestStatisticsExcel: (query: object) => {
    const url = '/statistics/organization/user';
    const params = query;
    const method = 'get';
    const responseType = 'blob';

    return instance({
      url,
      params,
      method,
      responseType,
    });
  },
  getOrgTestSetStatistics: (query: object) => {
    const url = '/statistics/organization/test/set';
    const params = query;
    const method = 'get';

    return instance({
      url,
      params,
      method,
    });
  },
  getOrgTestSetStatisticsExcel: (query: object) => {
    const url = '/statistics/organization/test/set';
    const params = query;
    const method = 'get';
    const responseType = 'blob';

    return instance({
      url,
      params,
      method,
      responseType,
    });
  },
};

export default StatisticsAPI;
