
import { useRoute, useRouter } from 'vue-router';
import ReportAPI from '@/api/report';
import { reactive } from '@vue/reactivity';
import { onBeforeMount } from '@vue/runtime-core';
import { useStore } from 'vuex';
import filters from '@/utils/filter';
import List from '@/components/List/List.vue';
import MessageBox from 'element-plus/lib/el-message-box';
// import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { ElementContainer } from 'html2canvas/dist/types/dom/element-container';

export default {
  name: 'printReport',
  components: {
    List,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    let printShow = reactive({
      show: true
    });
    const counseleeInfoData: {
      name: string,
      birthDate: string,
      gender: string,
      phone: string,
    } = reactive({
      name: '',
      birthDate: '',
      gender: '',
      phone: ''
    });
    const reportInfoData: {
      id: any,
      reportNo:string,
      author: string,
      authorDate: string,
      printDate: string,
      agency: string,
      info: string,
      parentOpinion: string,
      joenOpinion: string
    } = reactive({
      id: null,
      reportNo: '',
      author: '',
      authorDate: '',
      printDate: filters.dateToString(new Date(), 'yyyy.mm.dd'),
      agency: '',
      info: 'J.O.A.N.N.E 공감서비스는 아이들에게 자연스러운 대화를 통해 아이들이 일상 생활에서 겪는 스트레스 신호를 발견하고, 상담 필요성을 A.I. 시스템을 이용하여 선별한 후 적합한 전문기관에서 도움을 주기 위해 실시하는 것입니다. 이 검사는 의학적 진단을 위한 평가가 아니며, 검사 결과 상담 필요성이 있는 아이들은 최근의 스트레스로 정서나 행동상의 어려움이 있을 가능성이 있으므로, 상황에 맞는 도움을 제공받을 필요가 있다는 것입니다. \n\n만일 J.O.A.N.N.E. 공감서비스 결과 상담 권유로 평가되었다면, 심리적 어려움과 스트레스, 우울, 신체적 불편감 등의 경향성이 높게 나타날 수 있으므로 양육자와 주변의 세심한 관심과 함께 전문기관에서의 심층 대면 진단을 권장해드립니다.',
      parentOpinion: '',
      joenOpinion: ''
    });
    const listOption:{
      list: any[]
    } = reactive({
      list: []
    })
    const elContainer:any = reactive({
      canvasElement: null
    })

    /** 닫기 클릭 */
    const handleClickClose = () => {
      router.push({
        name: 'manageReport',
        query: route.query
      })
    }

    /** 화면 pdf로 전환 */
    const htmlToPdf = async() => {
      const printArea:any = document.getElementById('printArea');    
    
      await html2canvas(printArea)
      .then(canvas => {
        elContainer.canvasElement = canvas;
        printArea.appendChild(canvas);
        printShow.show = false
      })
    }

    /**보고서 조회 api */
    const getReport = async() => {
      const params = {
        id: route.params.id
      }
      await ReportAPI.getReport(params)
      .then((response) => {
        const responseData = response.data
        if (responseData 
        && typeof responseData === 'object'
        && Object.keys(responseData).indexOf('result') > -1
        && Object.keys(responseData).indexOf('response') > -1
        && responseData.result ) {
          const {
            id,
            no,
            name,
            birthDate,
            gender,
            phone,
            // testCnt,
            // createAt,
            // organization,
            // schoolKind,
            // schoolYear,
            // careHistory,
            // qrCode,
            // counselor,
            memo,
            // testId,
            // testCounselor,
            // testDate,
            // testResult,
            author,
            authorAt,
            counselAreaList,
            // reportStatus,
            parentOpinion,
            joenOpinion,
            printOrganization,
          } = responseData.response;

          reportInfoData.id = id;
          listOption.list = counselAreaList;

          if (typeof name === 'string') 
            counseleeInfoData.name = name;
          if (typeof birthDate === 'string') 
            counseleeInfoData.birthDate = birthDate;
          if (typeof gender === 'string') 
            counseleeInfoData.gender = gender;
          if (typeof phone === 'string') 
            counseleeInfoData.phone = phone;
          if (typeof no === 'string') 
            reportInfoData.reportNo = no;
          if (typeof author === 'string') 
            reportInfoData.author = author;
          if (typeof authorAt === 'string') 
            reportInfoData.authorDate = authorAt;
          if (typeof printOrganization === 'string') 
            reportInfoData.agency = printOrganization;
          if (typeof parentOpinion === 'string') 
            reportInfoData.parentOpinion = parentOpinion;
          if (typeof joenOpinion === 'string') 
            reportInfoData.joenOpinion = joenOpinion;
            
          
        } else {
          throw 'error';
        }
      })
      .catch((err) => {
        throw err;
      })
    }

    /** 보고서 미리보기 페이지 새탭 띄우기 */
    const handleOpenWindow = () => {
      window.open(`/report/preview/${route.params.id}`)
    }

    /** 보고서 출력 api */
    const postPrintReport = async() => {
      let result = 0;
      const params = {
        id: reportInfoData.id
      }
      await ReportAPI.postPrintReport(params)
      .then((response) => {
        const responseData = response.data;
        if (responseData 
        && typeof responseData === 'object'
        && Object.keys(responseData).indexOf('result') > -1
        && Object.keys(responseData).indexOf('response') > -1
        && responseData.result ) {
          result = 1;
        } else {
          throw 'err';
        }
      })
      .catch((err) => {
        throw err;
      })

      return result;
    }

    /** 인쇄 클릭 */
    const handleClickPrint = () => {
      postPrintReport()
      .then((result) => {
        if (result) {
          handleOpenWindow()
        } else {
          MessageBox({
            type: 'error',
            title: '출력 에러',
            message: '보고서를 출력할 수 없습니다. 관리자에게 문의하세요.',
            callback() {
              router.push('/');
            }
          });
        }
      })
      .catch(() => {
        router.push({name: 'error'}).catch(() => {});
      })
    }

    onBeforeMount(() => {
      
      store.commit('loadingBar/showLoadingBar');
      getReport()
      .then(() => {
        htmlToPdf()
        .then(() => {
          store.commit('loadingBar/hideLoadingBar');
        })
      })
      .catch(() => {
        router.push({name: 'error'}).catch(() => {});
      })

    })
    return {
      counseleeInfoData,
      reportInfoData,
      listOption,
      filters,
      handleClickPrint,
      printShow,
      handleClickClose,
    }
  }
}
