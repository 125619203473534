
import { onBeforeMount, reactive } from "vue";
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import MessageBox from 'element-plus/lib/el-message-box';
import notify from '@/utils/notify';
import List from '@/components/List/List.vue';
import Modal from '@/components/Modal/Modal.vue'
import CounseleeAPI from '@/api/counselee';
import filters from '@/utils/filter';
import { getCookie } from '@/utils/cookie';

export default {
  name: 'counseleeDetail',
  components: {
    List,
    Modal
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const userLevel = getCookie('userLevel');
    const userCounselorId = getCookie('counselorId');
    const userOrganizationId = getCookie('organizationId');

    const selectList: {
      counselorList: any[]
    } = reactive({
      counselorList: []
    });

    const selectItem: {
      counselor: any,
      test: any
    } = reactive({
      counselor: null,
      test: null
    });

    /** 내담자 정보 */
    const counseleeInfoData: {
      counseleeInfo: {
        birthDate: string,
        careHistoryString: string,
        counselor: string,
        createDate: string,
        gender: string,
        name: string,
        tel: string,
        schoolKindString: string,
        schoolYear: number,
        testCount: number,
        agency: string,
        memo: string,
        qrCode: string,
        organizationId: any,
        id: any
      },
      guardianList: any[]
    } = reactive({
      counseleeInfo : {
        birthDate: '',
        careHistoryString: '',
        counselor: '',
        createDate: '',
        gender: '',
        name: '',
        tel: '',
        schoolKindString: '',
        schoolYear: 0,
        testCount: 0,
        agency: '',
        memo: '',
        qrCode: '',
        organizationId: null,
        id: null,
      },
      guardianList: []
    });
    /** 조치내역 정보 */
    const actionInfoData: any = reactive({
      actionInfo: {
        id: 0,
        counselorId: null,
        counselor: '',
        parentId: null,
        content: '',
        parent: '',
        testId: 0,
        careDate: '',
      },
      selectGuardianList: [],
      selectCounselorList: []
    });
    
    const listOption: {
      maxPage: number,
      totalCount: number,
      page: number,
      list: {}[],
      order: string,
      orderField: string
    } = reactive({
      maxPage: 10,
      totalCount: 0,
      page: 1,
      list: [],
      order: '',
      orderField: ''
    })
    const modalOption = reactive({ modalType: '' });

    /** 정보수정 클릭 */
    const handleClickEdit =() => {
      router.push({
        name: 'editCounseleeInfo', 
        params: { 
          id: counseleeInfoData.counseleeInfo.id, 
          name: counseleeInfoData.counseleeInfo.name
        },
        query: route.query
      })
    }

    /** 보고서 작성/수정 클릭 */
    const handleClickReport = (row: any) => {
      router.push({
        name: 'reportDetail', 
        params: {
          counseleeId: `${ counseleeInfoData.counseleeInfo.id }`, 
          name: `${ counseleeInfoData.counseleeInfo.name }`,
          testId: `${ row.id }`,
          status: `${ row.reportStatus === '미작성' ? 0 : 1 }`
        },
        query: route.query
      })
    }

    /** 테스트 기록 클릭 */
    const handleClickTestRecord = (id:any) => {
      router.push({
        name: 'testDetail', 
        params: {
          counseleeId: route.params.id,
          id: id, 
          name: counseleeInfoData.counseleeInfo.name
        },
        query: route.query
      }).catch(() => {})
    }

    /** 리스트 No */
    const getIndex = (index: number) => {
      const {
        totalCount,
        maxPage,
        page
      } = listOption;
      return totalCount - (maxPage * (page - 1)) - index;
    }


    /** 날짜 포맷 fnc */
    const getTableTime = (date: string) => {
      const dateObj:any = new Date(date);
      let result: string = '';
      (!date || dateObj.toString() === 'Invalid Date') 
      ? result = '-'
      : result = filters.dateToString(dateObj, 'tableTime');
      return result;
    }

    /** 페이지 변경 */
    const changePage = (page: number) => {
      listOption.page = page;
      store.commit('loadingBar/showLoadingBar');
      getTestHistoryList()
      .then(() => {
        store.commit('loadingBar/hideLoadingBar');
      })
      .catch(() => {
        router.push({name: 'error'}).catch(() => {});
      })
    }

    /** 팝업창 열기 */
    const handleOpenModal = (type: string) => {
      modalOption.modalType = type;
      store.commit('modal/openModal', 'type-popup')
    }
    /** 팝업창 닫기 */
    const closeModal = () => {
      store.commit('modal/closeModal')
    }

    /** 팝업창 클릭 */
    const handleOpenActionModal = (testId: number, orgId: number, isAction: boolean) => {

      modalOption.modalType ='actionList';
      actionInfoData.actionInfo.testId = testId;


      if (isAction) {
        store.commit('loadingBar/showLoadingBar');
        Promise.all([
          getTestAction(testId),
          getOrgCounselorList(orgId)
        ])
        .then(() => {
          store.commit('modal/openModal', 'type-popup');
          store.commit('loadingBar/hideLoadingBar');
        })
        .catch(() => {
          window.location.href = '/error';
        })
      } else {
        getOrgCounselorList(orgId)
        .then(() => {
          store.commit('modal/openModal', 'type-popup');
          store.commit('loadingBar/hideLoadingBar');
        })
        .catch(() => {
          window.location.href = '/error';
        })

      }
    }

    /** 조치내역 초기화 */
    const resetActionInfoData = () => {
      actionInfoData.actionInfo = {
        id: 0,
        counselorId: 0,
        counselor: '',
        parentId: 0,
        content: '',
        parent: '',
        testId: 0,
        careDate: '',
      };
      actionInfoData.selectGuardianList = [];
      actionInfoData.selectCounselorList = [];
    }

    /** 조치내역 등록 버튼 클릭 */
    const handleClickCreateAction = () => {
      store.commit('loadingBar/showLoadingBar');
      createAction()
      .then((res) => {
        if (res) {
          resetActionInfoData();
        }
      })
      .catch(() => {
        window.location.href = '/error';
      })

    }

    /** 내담자 정보 수정 페이지 이동 */
    const goEditCounseleeInfo = () =>{
      store.commit('modal/closeModal');
      router.push({
        name: 'editCounseleeInfo', 
        params: { 
          id: counseleeInfoData.counseleeInfo.id, 
          name: counseleeInfoData.counseleeInfo.name
        },
        query: route.query
      }).catch(() => {});
    }

    /** QR 코드 다운로드 */
    const downloadQrCode = (qrCodeURL: string, name: string) => {
      if (qrCodeURL && name) {
        let readerObj:any;
        const canvas = window.document.createElement('canvas');
        const context: any = canvas.getContext('2d');
        const img = window.document.createElement('img');
        canvas.width = 512;
        canvas.height = 512;
        img.crossOrigin = null;
        img.src = `${process.env.VUE_APP_BASE_URL}${qrCodeURL}`;
  
        
        setTimeout(() => {
          context.drawImage(img, 0, 0);
    
          readerObj = canvas.toDataURL('img/png').toString();
          let imgData;
    
          if (readerObj.split(',')[0].indexOf('base64') >= 0)
            imgData = atob(readerObj.split(',')[1]);
          else
            imgData = unescape(readerObj.split(',')[1]);
    
    
          const len = imgData.length;
          const view = new Uint8Array(len);
    
          for (let i = 0; i <len; i++) {
            view[i] = imgData.charCodeAt(i);
          }
    
          const blob = new Blob([view], {type: 'image/png'});
    
          const link: any = window.document.createElement('a');
          const url = window.URL.createObjectURL(blob);
          link.href = url;
          link.download = `${name}.png`;
          link.click();
          document.body.appendChild(link);
          setTimeout(() => {
            document.body.removeChild(link);
            URL.revokeObjectURL(url)
          }, 100)
        }, 500)
      }
    }

    /** 내담자 등록 params */
    const getCreateActionParams = () => {
      const actionInfo = actionInfoData.actionInfo
      const params: any = {
        id: actionInfo.testId,
      }

      if (actionInfo.counselorId !== null ) {
        params.counselorId = actionInfo.counselorId;
      } else {
        notify.warning('상담사를 입력해주세요');
        return;
      }

      if (actionInfo.parentId !== null ) {
        params.parentId = actionInfo.parentId;
      } else {
        notify.warning('보호자를 입력해주세요');
        return;
      }

      if (actionInfo.content !== '' ) {
        params.content = actionInfo.content;
      } else {
        notify.warning('조치내역을 입력해주세요');
        return;
      }

      if (actionInfo.careDate && filters.dateToString(actionInfo.careDate, 'yyyy-mm-dd hh:mm')) {
        params.careDate = filters.dateToString(actionInfo.careDate, 'yyyy-mm-dd hh:mm');
      } else {
        notify.warning('조치일시를 입력해주세요');
        return;
      }
      return params;
    }

    /** 조치내역 등록/ 수정 */
    const createAction = async() => {
      const params = getCreateActionParams();
      if (params) {
        await CounseleeAPI.postCreateAction(params)
        .then((response) => {
          const responseData = response.data
          if (responseData 
          && typeof responseData === 'object'
          && Object.keys(responseData).indexOf('result') > -1
          && responseData.result ) {
            store.commit('modal/closeModal');
            MessageBox({
              title: '조치내역 메시지',
              message: '조치내역 등록이 완료되었습니다.',
              callback: refresh
            });
            return 1;
          } else {
            return 0;
          }
        })
        .catch((error) => {
          throw error;
        })
      } else {
        store.commit('loadingBar/hideLoadingBar');
        return 0;
      }
      
    }

    /** 기관별 상담사 리스트 get api */
    const getOrgCounselorList = async (id:number) => {
      await CounseleeAPI.getOrgCounselorList({
        id: id,
      })
      .then((response) => {
        const responseData = response.data
        if (responseData 
        && typeof responseData === 'object'
        && Object.keys(responseData).indexOf('result') > -1
        && Object.keys(responseData).indexOf('response') > -1
        && responseData.result ) {
          const {
            list
          } = responseData.response;
          const tmpList:any = [];
          for (const item of list) {
            tmpList.push({
              label: item.name,
              value: item.id
            })
          }
          actionInfoData.selectCounselorList = tmpList;
          selectList.counselorList = tmpList;
        } else {
          throw 'error';
        }
      })
      .catch((error) => {
        throw error;
      })
    } 

    /** 조치내역 get api */
    const getTestAction = async (testId: number) => {
      const params = {
        id: testId
      }
      await CounseleeAPI.getTestAction(params)
      .then((response) => {
        const responseData = response.data;
        if (responseData 
        && typeof responseData === 'object'
        && Object.keys(responseData).indexOf('result') > -1
        && Object.keys(responseData).indexOf('response') > -1
        && responseData.result ) {
          const {
            id,
            counselorId,
            counselor,
            parentId,
            parent,
            content,
            careDate
          } = responseData.response;

          actionInfoData.actionInfo = {
            id,
            counselorId,
            counselor,
            parentId,
            parent,
            content,
            careDate,
            testId
          }
        }
      })
      .catch((error) => {
        throw error;
      })
    }

    /** 내담자 상세정보 get api */
    const getCounseleeDetail = async() => {
        const params: object = {
          id: route.params.id
        }

        await CounseleeAPI.getCounseleeDetail(params)
        .then((response) => {
          const responseData = response.data
          if (responseData 
          && typeof responseData === 'object'
          && Object.keys(responseData).indexOf('result') > -1
          && Object.keys(responseData).indexOf('response') > -1
          && responseData.result ) {
            const {
              birthDate,
              careHistory,
              careHistoryString,
              counselor,
              counselorId,
              createAt,
              gender,
              id,
              memo,
              name,
              organization,
              organizationId,
              parentsList,
              phone,
              qrCode,
              schoolKind,
              schoolKindString,
              schoolYear,
              testCnt
            } = responseData.response;

            /** 잘못된 이름url로 들어올 경우 */
            if (route.params.name !== name) {
              router.replace({
                name: 'counseleeDetail',
                params: {
                  id: route.params.id,
                  name: name
                }
              })
              .catch(() => {})
            }

            counseleeInfoData.counseleeInfo = {
              birthDate,
              careHistoryString,
              counselor,
              createDate: createAt,
              gender,
              name,
              tel: phone,
              schoolKindString,
              schoolYear,
              testCount: testCnt,
              agency: organization,
              memo,
              qrCode,
              id,
              organizationId
            };

            counseleeInfoData.guardianList = parentsList;
            

            // 조치내역 팝업용 보호자 select item list
            const tmpSelectGuardianList = [];

            for (const parent of counseleeInfoData.guardianList) {
              tmpSelectGuardianList.push({
                label: parent.name,
                value: parent.id
              })
            }

            actionInfoData.selectGuardianList = tmpSelectGuardianList;            
          } else {
            throw 'error';
          }
        })
        .catch((err) => {
          throw err;
        })
    }

    const getParams = () => {
      const params:any = {
        id: route.params.id,
        maxPage: listOption.maxPage,
        page: listOption.page,
      }

      if (listOption.order) {
        params.order = listOption.order;
      }

      if (listOption.orderField) {
        params.orderField = listOption.orderField;
      }

      return params
    }

    /** 테스트 내역 리스트 get api */
    const getTestHistoryList = async() => {
      const params = getParams();
      await CounseleeAPI.getTestHistoryList(params)
      .then((response) => {
        const responseData = response.data;
        if (responseData 
        && typeof responseData === 'object'
        && Object.keys(responseData).indexOf('result') > -1
        && Object.keys(responseData).indexOf('response') > -1
        && responseData.result ) {
          const {
            list,
            // next,
            // previous,
            totalCount,
            // firstPage,
            // endPage
          } = responseData.response;

          listOption.list = list;
          listOption.totalCount = totalCount;
        } else {
          throw 'error';
        }
      })
      .catch((err) => {
        throw err;
      })
    }

    /** 테스트 상담사 변경 */
    const putEditTestCounselor = async() => {
      const params = {
        id: selectItem.test,
        counselorId: selectItem.counselor
      }
      await CounseleeAPI.putEditTestCounselor(params)
      .then((response) => {
        const responseData = response.data;
        if (responseData 
        && typeof responseData === 'object'
        && Object.keys(responseData).indexOf('result') > -1
        && responseData.result ) {
          notify.info('테스트 상담사가 변경되었습니다.');
        } else {
          throw 'err';
        }
      })
      .catch((err) => {
        throw err;
      })
    }

    /** 화면 초기화 */
    const refresh = () => {
      store.commit('loadingBar/showLoadingBar');
      Promise.all([
        getCounseleeDetail(),
        getTestHistoryList()
      ])
      .then(() => {
        store.commit('loadingBar/hideLoadingBar');
      })
      .catch(() => {
        router.push({name: 'error'}).catch(() => {})
      })
    }

    /** 리스트 정렬 */
    const sort = (orderField: string) => {
      if (listOption.orderField === orderField) {
        (listOption.order === 'desc') ? listOption.order = 'asc' : listOption.order = 'desc';
      } else {
        listOption.orderField = orderField;
        listOption.order = 'desc';
      }
      store.commit('loadingBar/showLoadingBar');
      getTestHistoryList()
      .then(() => {
        store.commit('loadingBar/hideLoadingBar');
      })
      .catch(() => {
        router.push({name: 'error'}).catch(() => {});
      })
    }

    onBeforeMount(() => {
      refresh()
    })
    
    return {
      selectList,
      selectItem,
      counseleeInfoData,
      actionInfoData,
      changePage,
      listOption,
      modalOption,
      closeModal,
      handleOpenModal,
      getTableTime,
      downloadQrCode,
      goEditCounseleeInfo,
      handleOpenActionModal,
      handleClickCreateAction,
      sort,
      filters,
      getIndex,
      handleClickTestRecord,
      userCounselorId,
      userLevel,
      userOrganizationId,
      handleClickEdit,
      handleClickReport,
      handleClickCounselor(test:any){
        selectItem.test = test.id;
        store.commit('loadingBar/showLoadingBar');
        getOrgCounselorList(counseleeInfoData.counseleeInfo.organizationId)
        .then(() => {
          store.commit('loadingBar/hideLoadingBar');
          handleOpenModal('editCounselor');
        })
        .catch(() => {
          router.push({ name: 'error' }).catch(() => {});
        })
      },
      handleClickEditCounselor(){
        store.commit('loadingBar/showLoadingBar');
        putEditTestCounselor()
        .then(() => {
          store.commit('modal/closeModal');
          store.commit('loadingBar/hideLoadingBar');
          refresh();
        })
        .catch(() => {
          router.push({ name: 'error' }).catch(() => {});
        })
      }
    }
  }
}
