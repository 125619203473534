<template>
  <div class="print-report-wrapper">
    <div class="print-area" id="printArea">
      <div class="print-report-box"
        v-if="printShow.show"
      >
        <div class="report-header-wrapper">
          <label>
            {{'보 고 서'}}
          </label>
          <p>
            {{ `문서번호: ${ reportInfoData.reportNo }`}} 
          </p>
        </div>
        <div class="counselee-info-wrapper">
          <table class="counselee-info-table">
            <thead>
              <tr>
                <th>이름</th>
                <th>생년월일</th>
                <th>성별</th>
                <th>연락처</th>
                <th>작성자</th>
                <th>작성 일자</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{counseleeInfoData.name}}</td>
                <td>{{ filters.getFormedDate(counseleeInfoData.birthDate) }}</td>
                <td>{{counseleeInfoData.gender === 'F' ? '여' : '남'}}</td>
                <td>{{ filters.getFormedPhoneNumber(counseleeInfoData.phone) }}</td>
                <td>{{reportInfoData.author}}</td>
                <td>{{ filters.dateToString(reportInfoData.authorDate, 'yyyy.mm.dd') }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="counselee-report-detail-wrapper">
          <div class="counselee-report-box">
              <div class="input-wrapper">
                <div class="label-wrapper">
                  <label>{{'아이들의 이야기를 들어주는 친구 Joanne의 공감 서비스란?'}}</label>
                </div>
                <div class="item-wrapper">
                  {{`${reportInfoData.info}`}}
                </div>
              </div>
              <div class="input-wrapper">
                <div class="label-wrapper">
                  <label>{{'01. Joanne 의 공감 상담 영역'}}</label>
                </div>
                <table class="counseling-area">
                  <thead>
                    <th>{{'No'}}</th>
                    <th>{{'영역'}}</th>
                    <th>{{'항목'}}</th>
                    <th>{{'표현'}}</th>
                    <th>{{'상담권유'}}</th>
                    <th>{{'의미'}}</th>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in listOption.list" :key="index">
                      <td>{{ index + 1 }}</td>
                      <td>{{ item.kind }}</td>
                      <td>{{ item.itemCnt }}</td>
                      <td>{{ item.expression }}</td>
                      <td>
                        <el-button
                          v-if="item.recommendation === 2"
                          type="warning"
                          class="medium"
                          plain
                        >
                          {{'응급 상담 권유'}}
                        </el-button>
                        <el-button
                          v-if="item.recommendation === 1"
                          type="danger"
                          class="medium"
                          plain
                        >
                          {{'조기 상담 권유'}}
                        </el-button>
                        <el-button
                          v-if="item.recommendation === 0"
                          type="success"
                          class="medium"
                          plain
                        >
                          {{'정기 상담 권유'}}
                        </el-button>
                      </td>
                      <td>
                        <p v-for="(item, index) in item.meaningString.split(',')" :key="index">
                          {{ item }}
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="input-wrapper">
                <div class="label-wrapper">
                  <label>{{'02. 보호자 관찰 의견'}}</label>
                </div>
                <div class="item-wrapper">
                  {{`${reportInfoData.parentOpinion}`}}
                </div>
              </div>
              <div class="input-wrapper">
                <div class="label-wrapper">
                  <label>{{'03. Joanne 의 의견'}}</label>
                </div>
                <div class="item-wrapper">
                  {{`${reportInfoData.joenOpinion}`}}
                </div>
              </div>
          </div>
        </div>
        <div class="report-footer-wrapper">
          <p>
            {{ `${reportInfoData.printDate}` }}
          </p>
          <p>
            {{ `${reportInfoData.agency}` }} 
          </p>
        </div>
        <div class="report-warn-info">
          <p>{{ '※ 본 출력물은 보고서용으로만 사용가능하며 명시된 개인정보는 유출불가합니다' }}</p>
          <br/>
          <br/>
        </div>
      </div>
    </div>
    <div class="bottom-button-wrapper">
      <div>
        <el-button
          type="info"
          class="medium"
          @click="handleClickClose"
        >{{'닫기'}}</el-button>
        <el-button
          type="primary"
          class="medium"
          @click="handleClickPrint"
        > 
          {{'인쇄'}}
          <i class="el-icon-printer"/>
        </el-button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { useRoute, useRouter } from 'vue-router';
import ReportAPI from '@/api/report';
import { reactive } from '@vue/reactivity';
import { onBeforeMount } from '@vue/runtime-core';
import { useStore } from 'vuex';
import filters from '@/utils/filter';
import List from '@/components/List/List.vue';
import MessageBox from 'element-plus/lib/el-message-box';
// import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { ElementContainer } from 'html2canvas/dist/types/dom/element-container';

export default {
  name: 'printReport',
  components: {
    List,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    let printShow = reactive({
      show: true
    });
    const counseleeInfoData: {
      name: string,
      birthDate: string,
      gender: string,
      phone: string,
    } = reactive({
      name: '',
      birthDate: '',
      gender: '',
      phone: ''
    });
    const reportInfoData: {
      id: any,
      reportNo:string,
      author: string,
      authorDate: string,
      printDate: string,
      agency: string,
      info: string,
      parentOpinion: string,
      joenOpinion: string
    } = reactive({
      id: null,
      reportNo: '',
      author: '',
      authorDate: '',
      printDate: filters.dateToString(new Date(), 'yyyy.mm.dd'),
      agency: '',
      info: 'J.O.A.N.N.E 공감서비스는 아이들에게 자연스러운 대화를 통해 아이들이 일상 생활에서 겪는 스트레스 신호를 발견하고, 상담 필요성을 A.I. 시스템을 이용하여 선별한 후 적합한 전문기관에서 도움을 주기 위해 실시하는 것입니다. 이 검사는 의학적 진단을 위한 평가가 아니며, 검사 결과 상담 필요성이 있는 아이들은 최근의 스트레스로 정서나 행동상의 어려움이 있을 가능성이 있으므로, 상황에 맞는 도움을 제공받을 필요가 있다는 것입니다. \n\n만일 J.O.A.N.N.E. 공감서비스 결과 상담 권유로 평가되었다면, 심리적 어려움과 스트레스, 우울, 신체적 불편감 등의 경향성이 높게 나타날 수 있으므로 양육자와 주변의 세심한 관심과 함께 전문기관에서의 심층 대면 진단을 권장해드립니다.',
      parentOpinion: '',
      joenOpinion: ''
    });
    const listOption:{
      list: any[]
    } = reactive({
      list: []
    })
    const elContainer:any = reactive({
      canvasElement: null
    })

    /** 닫기 클릭 */
    const handleClickClose = () => {
      router.push({
        name: 'manageReport',
        query: route.query
      })
    }

    /** 화면 pdf로 전환 */
    const htmlToPdf = async() => {
      const printArea:any = document.getElementById('printArea');    
    
      await html2canvas(printArea)
      .then(canvas => {
        elContainer.canvasElement = canvas;
        printArea.appendChild(canvas);
        printShow.show = false
      })
    }

    /**보고서 조회 api */
    const getReport = async() => {
      const params = {
        id: route.params.id
      }
      await ReportAPI.getReport(params)
      .then((response) => {
        const responseData = response.data
        if (responseData 
        && typeof responseData === 'object'
        && Object.keys(responseData).indexOf('result') > -1
        && Object.keys(responseData).indexOf('response') > -1
        && responseData.result ) {
          const {
            id,
            no,
            name,
            birthDate,
            gender,
            phone,
            // testCnt,
            // createAt,
            // organization,
            // schoolKind,
            // schoolYear,
            // careHistory,
            // qrCode,
            // counselor,
            memo,
            // testId,
            // testCounselor,
            // testDate,
            // testResult,
            author,
            authorAt,
            counselAreaList,
            // reportStatus,
            parentOpinion,
            joenOpinion,
            printOrganization,
          } = responseData.response;

          reportInfoData.id = id;
          listOption.list = counselAreaList;

          if (typeof name === 'string') 
            counseleeInfoData.name = name;
          if (typeof birthDate === 'string') 
            counseleeInfoData.birthDate = birthDate;
          if (typeof gender === 'string') 
            counseleeInfoData.gender = gender;
          if (typeof phone === 'string') 
            counseleeInfoData.phone = phone;
          if (typeof no === 'string') 
            reportInfoData.reportNo = no;
          if (typeof author === 'string') 
            reportInfoData.author = author;
          if (typeof authorAt === 'string') 
            reportInfoData.authorDate = authorAt;
          if (typeof printOrganization === 'string') 
            reportInfoData.agency = printOrganization;
          if (typeof parentOpinion === 'string') 
            reportInfoData.parentOpinion = parentOpinion;
          if (typeof joenOpinion === 'string') 
            reportInfoData.joenOpinion = joenOpinion;
            
          
        } else {
          throw 'error';
        }
      })
      .catch((err) => {
        throw err;
      })
    }

    /** 보고서 미리보기 페이지 새탭 띄우기 */
    const handleOpenWindow = () => {
      window.open(`/report/preview/${route.params.id}`)
    }

    /** 보고서 출력 api */
    const postPrintReport = async() => {
      let result = 0;
      const params = {
        id: reportInfoData.id
      }
      await ReportAPI.postPrintReport(params)
      .then((response) => {
        const responseData = response.data;
        if (responseData 
        && typeof responseData === 'object'
        && Object.keys(responseData).indexOf('result') > -1
        && Object.keys(responseData).indexOf('response') > -1
        && responseData.result ) {
          result = 1;
        } else {
          throw 'err';
        }
      })
      .catch((err) => {
        throw err;
      })

      return result;
    }

    /** 인쇄 클릭 */
    const handleClickPrint = () => {
      postPrintReport()
      .then((result) => {
        if (result) {
          handleOpenWindow()
        } else {
          MessageBox({
            type: 'error',
            title: '출력 에러',
            message: '보고서를 출력할 수 없습니다. 관리자에게 문의하세요.',
            callback() {
              router.push('/');
            }
          });
        }
      })
      .catch(() => {
        router.push({name: 'error'}).catch(() => {});
      })
    }

    onBeforeMount(() => {
      
      store.commit('loadingBar/showLoadingBar');
      getReport()
      .then(() => {
        htmlToPdf()
        .then(() => {
          store.commit('loadingBar/hideLoadingBar');
        })
      })
      .catch(() => {
        router.push({name: 'error'}).catch(() => {});
      })

    })
    return {
      counseleeInfoData,
      reportInfoData,
      listOption,
      filters,
      handleClickPrint,
      printShow,
      handleClickClose,
    }
  }
}
</script>
<style lang="scss">
@import '../../style/styles.scss';
body{
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}
* {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}  

@page {
  size: 21cm 29.7cm;
  margin: 1cm 0;
}
@media print {
  html, body {
    width: 210mm;
    height: 297mm;
    border: 0;
    margin: 0;
    padding: 0;
  }

  * {
    -webkit-print-color-adjust: exact;
  }

  .print-report-box{
    padding: 2cm;
    width: 93%;
  
  }

}

</style>