import instance from '@/api/index';

const CounselorAPI = {
  getCounselorList: (query: object) => {
    const url = 'account/counselor/list';
    const params = query;
    const method = 'get';

    return instance({
      url,
      params,
      method,
    });
  },
  getCounselorInfo: (query: object) => {
    const url = 'account/counselor';
    const params = query;
    const method = 'get';

    return instance({
      url,
      params,
      method,
    });
  },
  postCreateCounselor: (query: object) => {
    const url = 'account/counselor';
    const params = query;

    return instance.post(
      url,
      params,
    );
  },
  putEditCounselor: (query: object) => {
    const url = 'account/counselor';
    const params = query;

    return instance.put(
      url,
      params,
    );
  },
  getChargedCounseleeList: (query: object) => {
    const url = 'account/counselor/counselee';
    const params = query;
    const method = 'get';

    return instance({
      url,
      params,
      method,
    });
  },
  getReportedCounseleeList: (query: object) => {
    const url = 'account/counselor/report/counselee';
    const params = query;
    const method = 'get';

    return instance({
      url,
      params,
      method,
    });
  },
};

export default CounselorAPI;
