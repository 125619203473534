<template>
  <div class="manage-test-set-wrapper">
    <div class="search-box-wrapper">
      <label>{{'검색 옵션'}}</label>
      <div class="search-condition-wrapper">
        <div class="search-condition-item">
          <el-date-picker
            type="daterange"
            start-placeholder="시작일 선택"
            end-placeholder="종료일 선택"
            v-model="listOption.date"
            :disabled-date="filters.disabledDate"
          />
        </div>
        <div class="search-condition-item">          
          <el-input
            placeholder="검색어 입력(테스트 셋 명, 생성자)"
            @keydown.enter="handleClickSearch"
            v-model="listOption.search"
            maxlength="150"
          />
        </div>
        <el-button
          type="info"
          class="search"
          @click="handleClickSearch"
          :disabled="listOption.entireCount === 0"
        >{{'검색'}}</el-button>
      </div>
    </div>
    <div class="list-upper-wrapper">
      <label>{{`${listOption.totalCount} / ${listOption.entireCount}`}}</label>
      <el-button
        type="primary"
        @click="handleClickCreateSetGroup"
        disabled
      >
        <i class="el-icon-plus"/>
        {{'셋그룹 등록'}}
      </el-button>
    </div>
    <List
      :selectedPage="listOption.page"
      :maxPage="listOption.maxPage"
      :totalCount="listOption.totalCount"
      :pagination="true"
      @changePage="changePage"
    >
      <template v-slot:list-table>
        <el-table
          :data="listOption.list"
          empty-text="테스트 셋 정보가 없습니다."
        >
          <el-table-column
            align ="center"
            width="50"
            label = "No"
            type="index"
            :index="getIndex"
            fixed
          />
          <el-table-column
            align ="center"
            prop="name"
          >
            <template #header>
              {{'테스트 셋 명'}}
              <span 
                class="icon pointer"
                @click="sort('name')"
              >
                <i 
                  v-if="listOption.orderField === 'name' 
                        && listOption.order === 'asc' 
                        ? true : false" 
                  class='el-icon-caret-top'
                />
                <i v-else class="el-icon-caret-bottom"/>
              </span>
            </template>
            <template #default="scope">
              <a
                @click="handleClickTestSet"
              >
                {{ scope.row.name }}
              </a>
            </template>
          </el-table-column>
          <el-table-column
            align ="center"
          >
            <template #header>
              {{'연결 셋 그룹수'}}
              <span 
                class="icon pointer"
                @click="sort('testSetGroupCnt')"
              >
                <i 
                  v-if="listOption.orderField === 'testSetGroupCnt' 
                        && listOption.order === 'asc' 
                        ? true : false" 
                  class='el-icon-caret-top'
                />
                <i v-else class="el-icon-caret-bottom"/>
              </span>
            </template>
            <template #default="scope">
              <a
                v-if="scope.row.testSetGroupCnt"
                @click="handleOpenModal('group', scope.row)"
              >
                {{ scope.row.testSetGroupCnt }}
              </a>
              <div v-else>{{'-'}}</div>
            </template>
          </el-table-column>
          <el-table-column
            align ="center"
          >
            <template #header>
              {{ '연결 내담자 수' }}
              <span 
                class="icon pointer"
                @click="sort('counseleeCnt')"
              >
                <i 
                  v-if="listOption.orderField === 'counseleeCnt' 
                        && listOption.order === 'asc' 
                        ? true : false" 
                  class='el-icon-caret-top'
                />
                <i v-else class="el-icon-caret-bottom"/>
              </span>
            </template>
            <template #default="scope">
              <a
                v-if="scope.row.counseleeCnt"
                @click="handleOpenModal('counselee', scope.row)"
              >
                {{ scope.row.counseleeCnt }}
              </a>
              <div v-else>{{'-'}}</div>
            </template>
          </el-table-column>
          <el-table-column
            align ="center"
            prop="author"
          >
            <template #header>
              {{ '생성자' }}
              <span 
                class="icon pointer"
                @click="sort('author')"
              >
                <i 
                  v-if="listOption.orderField === 'author' 
                        && listOption.order === 'asc' 
                        ? true : false" 
                  class='el-icon-caret-top'
                />
                <i v-else class="el-icon-caret-bottom"/>
              </span>
            </template>
          </el-table-column>
          <el-table-column
            align ="center"
            prop="createAt"
            label="등록일시"
          >
            <template #header>
              {{'등록일시'}}
              <span 
                class="icon pointer"
                @click="sort('createAt')"
              >
                <i 
                  v-if="listOption.orderField === 'createAt' 
                        && listOption.order === 'asc' 
                        ? true : false" 
                  class='el-icon-caret-top'
                />
                <i v-else class="el-icon-caret-bottom"/>
              </span>
            </template>
            <template #default="scope">
              <span v-html="getTableTime(scope.row.createAt)" />
            </template>
          </el-table-column>
        </el-table>
      </template>
    </List>
    <!-- 등록 테스트 셋, 연결기관 팝업 -->
    <Modal
      :options="modalOption"
    >
      <template v-slot:modal-body>
        <div class="entire-count">
          {{ `${modalOption.selectedGroup} - ${modalOption.totalCount}개` }}
        </div>
        <el-table
          :data="modalOption.list"
          v-loading="modalOption.loading"
          max-height="400"
          empty-text="데이터가 없습니다."
        >
          <el-table-column
            label="No"
            type="index"
            align="center"
          ></el-table-column>
          <el-table-column
            align="center"
            width="100"
          >
            <template #header>
              {{ modalOption.type === 'group' ? '연결 셋 그룹' : '기관' }}
            </template>
            <template #default="scope">
              <a
                v-if="modalOption.type === 'group'"
                @click="handleClickModalRow(scope.row)"
              >{{ scope.row.name }}</a>
              <div v-else>
                {{ scope.row.organization }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            width="100"
            v-if="modalOption.type === 'counselee'"
          >
            <template #header>
              {{ '성명' }}
            </template>
            <template #default="scope">
              <a
                @click="handleClickModalRow(scope.row)"
              >{{ scope.row.name }}</a>
            </template>
          </el-table-column>
        </el-table>
      </template>
    </Modal>
  </div>
</template>

<script lang="ts">
import Modal from '@/components/Modal/Modal.vue';
import List from '@/components/List/List.vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import {  } from '@vue/reactivity';
import { reactive, computed, onBeforeMount, onMounted } from 'vue';
import filters from '@/utils/filter';
import notify from '@/utils/notify';
import TestSetAPI from '@/api/testSet';
import _MessageBox from 'element-plus/lib/el-message-box';
export default {
  name: 'manageTestSet',
  components: {
    List,
    Modal
  },
  setup(){
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const listOption: {
      maxPage: number,
      totalCount: number,
      page: number,
      list: any[],
      order: string,
      orderField: string,
      entireCount: number,
      search: string,
      date: any[]
    } = reactive({
      maxPage: 10,
      totalCount: 0,
      page: 1,
      list: [],
      order: '',
      orderField: '',
      entireCount: 0,
      search: '',
      date: []
    });

    const modalOption:{
      title: string,
      rightButtonName: string,
      rightButtonCallBack: any,
      totalCount: number,
      list: any[],
      loading: boolean,
      visible: boolean,
      selectedGroup: string,
      type: string
    } = reactive({
      title: '',
      rightButtonName: '닫기',
      rightButtonCallBack: () => store.commit('modal/closeModal'),
      totalCount: 0,
      list: [],
      loading: false,
      visible: computed(() => store.getters['modal/modalVisible']),
      selectedGroup: '',
      type: ''
    });

    /** 검색쿼리 url 저장 */
    const setUrlQueryString = () => {
      const {
        page,
        order,
        orderField,
        search,
        date
      } = listOption;
      const query:any = {};

      if (page !== 1)
        query.page = page;
      
      if (order)
        query.order = order;
      
      if (orderField)
        query.orderField = orderField;

      if (search)
        query.search = search;
      
      if (Array.isArray(date)) {
        const tmpList = [];
        for (const item of date) {
          item && tmpList.push(filters.dateToString(item, 'yyyy-mm-dd'));
        }
        query.date = tmpList;
      }

      router.replace({
        name: 'manageTestSet',
        query
      }).catch(() => {});

    }

    /** 셋 리스트 params */
    const getParams = () => {
      const params:any = {};
      const {
        date,
        search,
        order,
        orderField
      } = listOption;

      if (date && date.length === 2) {
        params.gte = filters.dateToString(date[0], 'yyyy-mm-dd');
        params.lte = filters.dateToString(date[1], 'yyyy-mm-dd');
      } 

      if (orderField) 
        params.orderField = orderField;
      
      
      if (order) 
        params.order = order;
      

      if (search) 
        params.search = search.replaceAll('-', '');

      return params;
    }

     /** 셋 리스트 get api */
    const getTestSetList = async() => {
      const params = getParams();
      if (params) {
        await TestSetAPI.getTestSetList(params)
        .then((response) => {
          const responseData = response.data;
          if (responseData 
          && typeof responseData === 'object'
          && Object.keys(responseData).indexOf('result') > -1
          && Object.keys(responseData).indexOf('response') > -1
          && responseData.result ) {
            const {
              entireCount,
              totalCount,
              // previous,
              // startPage,
              // endPage,
              list
            } = responseData.response;

            if (typeof entireCount === 'number')
            listOption.entireCount = entireCount;
            else {
              if (typeof entireCount === 'string'
              && !isNaN(Number.parseInt(entireCount)))
                listOption.entireCount = Number.parseInt(entireCount);
            }

            
            if (typeof totalCount === 'number')
              listOption.totalCount = totalCount;
            else {
              if (typeof totalCount === 'string'
              && !isNaN(Number.parseInt(totalCount)))
                listOption.totalCount = Number.parseInt(totalCount);
            }

            if (Array.isArray(list))
              listOption.list = list;
            else
              listOption.list = [];
          } else {
            throw 'err';
          }
        })
        .catch(error => {
          if (typeof error === 'object'
          && Object.keys(error).indexOf('response') > -1
          && typeof error.response === 'object'
          && Object.keys(error.response).indexOf('status') > -1
          && Object.keys(error.response).indexOf('data') > -1
          && Object.keys(error.response.data).indexOf('response') > -1
          && Object.keys(error.response.data.response).indexOf('message') > -1) {
            const status = error.response.status;
            if (status === 400) {
              if (error.response.data.response.message === 'Only master can do this.') {
                _MessageBox({
                  type: 'error',
                  message: '잘못된 접근입니다.',
                  callback() {
                    router.push({ name: 'manageCounselee' }).catch(() => {});
                  }
                });
                return 1;
              } else {
                throw 'err';
              }
            } else {
              throw error;
            }
          } else {
            throw error;
          }
        })
      }
    }

    /** 연결 테스트셋 or 연결 내담자 get api */
    const getTestSetDetail = async(category: string, id: number) => {
      let result = 0;
      const params = { 
        category,
        id
      };

      await TestSetAPI.getTestSetDetail(params)
      .then((response) => {
        const responseData = response.data;
        if (responseData 
        && typeof responseData === 'object'
        && Object.keys(responseData).indexOf('result') > -1
        && Object.keys(responseData).indexOf('response') > -1
        && responseData.result ) {
          const {
            entireCount,
            // name,
            list
          } = responseData.response;

          modalOption.totalCount = entireCount;
          modalOption.list = list;
          result = 1;
        } else {
          throw 'err';
        }
      })
      .catch((err) => {
        throw err;
      })
      return result;
    }

    /** 초기화 */
    const refresh = () => {
      store.commit('loadingBar/showLoadingBar');
      getTestSetList()
      .then(() => {
        store.commit('loadingBar/hideLoadingBar');
      })
      .catch(() => {
        router.push({ name: 'error' }).catch(() => {});
      })
    }

    onBeforeMount(() => {
      if (Object.keys(route.query).length > 0) {
        const {
          page,
          order,
          orderField,
          search,
          date
        } = route.query;
        listOption.page = Number.parseInt(page ? page.toString() : '1');
        listOption.order = order ? order.toString() : '';
        listOption.orderField = orderField ? orderField.toString() : '';
        listOption.search = search ? search.toString() : '';


        if (Array.isArray(date)) {
          const tmpList = [];
          for (const item of date) {
            item && tmpList.push(new Date(item));
          }
          listOption.date = tmpList;
        }
      }
    })

    onMounted(() => {
      refresh();
    })



    return {
      listOption,
      modalOption,
      filters,
      getIndex(index:number) {
        const {
          totalCount,
          maxPage,
          page
        } = listOption;
        return totalCount - (maxPage * (page - 1)) - index;
      },
      changePage(page: number){
        listOption.page = page;
        setUrlQueryString();
      },
      sort(orderField: string){
        if (listOption.orderField === orderField) 
          (listOption.order === 'desc') 
          ? listOption.order = 'asc' 
          : listOption.order = 'desc';
        else {
          listOption.orderField = orderField;
          listOption.order = 'desc';
        }
        setUrlQueryString();
      },
      getTableTime(date: string) {
        const dateObj:any = new Date(date);
        let result: string = '';
        (!date || dateObj.toString() === 'Invalid Date') 
        ? result = '-'
        : result = filters.dateToString(dateObj, 'tableTime');
        return result.replaceAll('<br/>', ' ');
      },
      handleClickSearch() {
        listOption.page = 1;
        setUrlQueryString();
      },
      handleClickTestSet() {
        notify.info('상세페이지 준비중입니다.');
      },
      handleOpenModal(type: string, setGroup: any) {
        modalOption.loading = true;
        modalOption.type = type;

        if (type === 'group') 
          modalOption.title = '연결 테스트 셋 그룹';
        else if (type === 'counselee') 
          modalOption.title = '연결 내담자';
        
        modalOption.selectedGroup = setGroup.name;

        store.commit('modal/openModal');
        getTestSetDetail(type, setGroup.id)
        .then((result) => {
          if(result)
            modalOption.loading = false;
        })
      },
      handleClickModalRow(row:any) {
        if (modalOption.type === 'group') {
          router.push({
            name: 'createEditSetGroup',
            params: {
              id: row.id,
              status: 1
            }
          })
        } else {
          router.push({
            name: 'counseleeDetail',
            params: {
              name: row.name,
              id: row.id
            }
          })
        }
        
        store.commit('modal/closeModal');
      },
    }
  }
}
</script>
