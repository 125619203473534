import instance from '@/api/index';

const AccountAPI = {
  login: (query: object) => {
    const url = 'account/login';
    const params = query;

    return instance.post(
      url,
      params,
    );
  },
  logout: () => {
    const url = 'account/logout';

    return instance.post(
      url,
    );
  },
  getUserInfo: () => {
    const url = 'account/info';
    const method = 'get';

    return instance({
      url,
      method,
    });
  },
  putEditUserInfo: (query: object) => {
    const url = 'account/info';
    const params = query;

    return instance.put(
      url,
      params,
    );
  },
};

export default AccountAPI;
