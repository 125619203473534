<template>
  <div class="create-counselee-wrapper">
    <div class="counselee-info-wrapper">
      <label>{{'내담자 정보'}}</label>
      <div class="counselee-info-box">
        <div class="top-area">
          <div class="left-box">
            <div class="input-wrapper">
              <div class="label-wrapper">
                <label>{{'이름'}}</label>
              </div>
              <el-input
                placeholder="이름을 입력하세요"
                v-model="counseleeInfo.name"
              />
            </div>
            <div class="input-wrapper">
              <div class="label-wrapper">
                <label>{{'생년월일'}}</label>
              </div>
              <el-date-picker
                placeholder="생년월일을 선택해주세요"
                v-model="counseleeInfo.birthDate"
                :disabled-date="filters.disabledDate"
              />
            </div>
            <div class="input-wrapper">
              <div class="label-wrapper">
                <label>{{'치료력'}}</label>
              </div>
              <el-select 
                v-model="counseleeInfo.careHistory" 
                multiple
                placeholder="치료력을 선택하세요(복수 가능)"
              >
                <el-option
                  v-for="(item, index) in careHistoryList"
                  :key="index"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </div>
          </div>
          <div class="right-box">
            <div class="input-wrapper">
              <div class="label-wrapper">
                <label>{{'성별'}}</label>
              </div>
              <el-select 
                v-model="counseleeInfo.gender" 
                placeholder="성별을 선택하세요"
              >
                <el-option
                  v-for="(item, index) in genderList"
                  :key="index"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </div>
            <div class="input-wrapper">
              <div class="label-wrapper">
                <label>{{'연락처'}}</label>
              </div>
              <el-input
                placeholder="연락처를 입력해주세요(-제외)"
                v-model="counseleeInfo.phone"
                @change="handleChangeCounseleePhone"
              />
            </div>
            <div class="input-wrapper">
              <div class="label-wrapper">
                <label>{{'학교'}}</label>
              </div>
              <div class="select-box-area">
                <el-select 
                  v-model="counseleeInfo.schoolKind" 
                  placeholder="학교를 선택하세요"
                  value-key="id"
                >
                  <el-option
                    v-for="(item, index) in schoolList"
                    :key="index"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
                <el-tooltip placement="right">
                  <template #content>
                    {{ '\'유아\'일 경우 나이를 입력해주세요.'}}
                  </template>
                  <el-select 
                    v-model="counseleeInfo.schoolYear" 
                    placeholder="학년을 선택하세요"
                    :disabled="(counseleeInfo.schoolKind !== 0 && !counseleeInfo.schoolKind)"
                    value-key="id"
                  >
                    <el-option
                      v-for="(item) in selectList.schoolYearList"
                      :key="item.label"
                      :label="item.label"
                      :value="item.value"
                    />
                  </el-select>
                </el-tooltip>
              </div>
            </div>
          </div>
        </div>
        <div class="bottom-area">
          <div class="input-wrapper">
            <div class="label-wrapper">
              <label>{{'메모'}}</label>
            </div>
            <el-input
              type="textarea"
              placeholder="학교, 학년, 치료 이력 등"
              v-model="counseleeInfo.memo"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="guardian-info-wrapper">
      <div class="item-header-wrapper">
        <label>{{'보호자 정보'}}</label>
        <el-button
          type="info"
          size="mini"
          icon="el-icon-plus"
          @click="addGuardian"
        >
        </el-button>
      </div>
      <div 
        class="guardian-info-box"
        v-for="(item, index) in counseleeInfo.parentList"
        :key="index"
      >
        <div class="top-area">
          <div class="left-box">
            <div class="input-wrapper">
              <div class="label-wrapper">
                <label>{{'이름'}}</label>
              </div>
              <el-input
                placeholder="이름을 입력하세요"
                v-model="item.name"
              />
            </div>
            <div class="input-wrapper">
              <div class="label-wrapper">
                <label>{{'생년월일'}}</label>
              </div>
              <el-date-picker
                placeholder="생년월일을 선택해주세요"
                v-model="item.birthDate"
                :disabled-date="filters.disabledDate"
              />
            </div>
            <div class="input-wrapper">
              <div class="label-wrapper">
                <label>{{'관계'}}</label>
              </div>
              <div class="select-box-area">
                <el-select 
                  v-model="item.relation" 
                  placeholder="관계를 선택하세요"
                >
                <el-option
                  v-for="(relationItem, relationIndex) in relationList"
                  :key="relationIndex"
                  :label="relationItem.label"
                  :value="relationItem.value"
                />
              </el-select>
              <el-tooltip placement="right">
                <template #content>
                  {{ '\'기타\'일 경우 입력해주세요.'}}
                </template>
                <el-input
                  :disabled="item.relation !== 6"
                  v-model="item.relationDetail"
                  placeholder="관계를 입력해주세요"
                />
              </el-tooltip>
            </div>
            </div>
          </div>
          <div class="right-box">
            <div class="input-wrapper">
              <div>
                <div class="label-wrapper">
                  <label>{{'성별'}}</label>
                </div>
                <el-select 
                  v-model="item.gender" 
                  placeholder="성별을 선택하세요"
                >
                  <el-option
                    v-for="(genderItem, genderIndex) in genderList"
                    :key="genderIndex"
                    :label="genderItem.label"
                    :value="genderItem.value"
                  />
                </el-select>
              </div>
              <div>
                <div class="button-wrapper">
                  <el-button
                    type="info"
                    size="mini"
                    icon="el-icon-close"
                    :disabled="counseleeInfo.parentList.length === 1"
                    @click="removeGuardian(index)"
                  />
                </div>
              </div>
            </div>
            <div class="input-wrapper">
              <div class="label-wrapper">
                <label>{{'연락처'}}</label>
              </div>
              <el-input
                placeholder="연락처를 입력해주세요(-제외)"
                v-model="item.phone"
                @change="(word) => handleChangeGuardianPhone(word, index)"
              />
            </div>
          </div>
        </div>
        <div class="bottom-area">
          <div class="input-wrapper memo">
            <div class="label-wrapper">
              <label>{{'메모'}}</label>
            </div>
            <el-input
              type="textarea"
              placeholder="메모를 입력해주세요"
              v-model="item.memo"
            />
          </div>
          <div class="input-wrapper psc">
            <div class="label-wrapper">
              <label>{{'PSC 점수'}}</label>
            </div>
            <div class="psc-wrapper">
              <div class="psc-main">
                <el-input
                  v-model="pscModels.pscItems[index].pscScore"
                />
                <el-date-picker
                  placeholder="날짜를 선택해주세요."
                  v-model="pscModels.pscItems[index].pscDate"
                  :disabled-date="filters.disabledDate"
                />
                <i 
                  class="el-icon-plus"
                  @click="addPsc(index)"
                />
              </div>
              <div 
                class="psc-item"
                v-for="(pscItem, pscIndex) in item.pscList"
                :key="pscIndex"
              >
                <h5>{{ pscItem.score }}</h5>
                <h5>{{ pscItem.date }}</h5>
                <i 
                  class="el-icon-minus"
                  @click="removePsc(index, pscIndex)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="agency-info-wrapper">
      <label>{{'기관 정보'}}</label>
      <div class="agency-info-box">
        <div class="left-box">
          <div class="input-wrapper">
            <div class="label-wrapper">
              <label>{{'담당 기관'}}</label>
            </div>
            <el-select 
              :disabled="counseleeInfo.testFlag"
              v-model="orgSelectItems.organization" 
              placeholder="담당 기관을 선택하세요"
            >
              <el-option
                v-for="(item, index) in orgSelectList.orgList"
                :key="index"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </div>
        </div>
        <div class="right-box">
          <div class="input-wrapper">
            <div class="label-wrapper">
              <label>{{'상담사'}}</label>
            </div>
            <el-select 
              v-model="orgSelectItems.counselor"
              placeholder="상담사를 선택하세요"
              :disabled="!orgSelectItems.organization"
            >
              <el-option
                v-for="(item, index) in orgSelectList.counselorList"
                :key="index"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </div>
          <div class="input-wrapper">
            <div class="label-wrapper">
              <label>{{'테스트 셋'}}</label>
            </div>
            <el-select 
              v-model="orgSelectItems.testSet"
              placeholder="테스트 셋을 선택하세요"
              :disabled="!orgSelectItems.organization || counseleeInfo.progressStatus === 1"
            >
              <el-option
                v-for="(item, index) in orgSelectList.testSetList"
                :key="index"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom-button-wrapper">
      <el-button
        type="info"
        class="medium"
        @click="handleClickCancel"
      >{{'취소'}}</el-button>
      <el-button
        type="primary"
        class="medium"
        @click="handleClickSave"
      >{{'저장'}}</el-button>
    </div>
  </div>
</template>

<script lang="ts">
import { reactive, onBeforeMount, watch } from 'vue';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';
import CounseleeAPI from '@/api/counselee';
import MessageBox from 'element-plus/lib/el-message-box';
import notify from '@/utils/notify';
import filters from '@/utils/filter';
import { numberRegex, phoneRegex, koreanEngRegex } from '@/utils/regex';
import OrgAPI from '@/api/organization';

export default {
  name: 'editCounseleeInfo',
  setup(){
    const router = useRouter();
    const store = useStore();
    const route = useRoute();
    const relationList = [
      {
        label: '부', 
        value: 0
      }, 
      {
        label: '모', 
        value: 1
      },
      {
        label: '친인척', 
        value: 2
      },
      {
        label: '상담사', 
        value: 3
      },
      {
        label: '선생님', 
        value: 4
      },
      {
        label: '경찰관',
        value: 5,
      },
      {
        label: '기타', 
        value: 6
      },
    ];
    const genderList = [
      {
        label: '남', 
        value: 'M'
      }, 
      {
        label: '여', 
        value: 'F'
      }
    ];
    const careHistoryList = [
      {
        label: '병원치료', 
        value: 0
      }, 
      {
        label: '약물치료', 
        value: 1
      },
      {
        label: '상담치료', 
        value: 2
      },
      {
        label: '기타 치료', 
        value: 3
      },
    ];
    const schoolList = [
      {
        label: '유아', 
        value: 0
      }, 
      {
        label: '초등학교', 
        value: 1
      }, 
      {
        label: '중학교', 
        value: 2
      }, 
      {
        label: '고등학교', 
        value: 3
      }, 
      {
        label: '기타', 
        value: 4
      }, 
    ];
    const gradeList = reactive([
      {
        label: '1학년',
        value: 1
      },
      {
        label: '2학년',
        value: 2
      },
      {
        label: '3학년',
        value: 3
      },
      {
        label: '4학년',
        value: 4
      },
      {
        label: '5학년',
        value: 5
      },
      {
        label: '6학년',
        value: 6
      },
      {
        label: '7학년',
        value: 7
      },
      {
        label: '8학년',
        value: 8
      },
      {
        label: '9학년',
        value: 9
      },
      {
        label: '10학년',
        value: 10
      },
      {
        label: '11학년',
        value: 11
      },
      {
        label: '12학년',
        value: 12
      },
    ]);
    const childYears = reactive([
      {
        label: '5세',
        value: 5
      },
      {
        label: '6세',
        value: 6
      },
      {
        label: '7세',
        value: 7
      },
    ]);

    const selectList: {
      schoolYearList: {
        label: string
        value: number
      }[]
    } = reactive({
      schoolYearList: []
    });

    /** 내용 변경시 QR 코드 재다운 안내를 위한 states */
    const downloadQRmsgData: {
      isMounted: boolean,
      isWarned: boolean
    } = reactive({
      isMounted: false,
      isWarned: false
    });

    const counseleeInfo:any = reactive({
      id: null,
      name: '',
      birthDate: '',
      gender: '',
      phone: '',
      careHistory: [],
      schoolKind: null,
      schoolYear: null,
      memo: '',
      parentList: [
        {
          name: '',
          birthDate: '',
          gender: '',
          phone: '',
          relation: null,
          relationDetail: '',
          memo: '',
          pscList: [],
          id: null,
          age: null
        }
      ],
      testSet: null,
      progressStatus: 0
    })

    const pscModels: any = reactive({
      pscItems: [
        {
          pscScore: '',
          pscDate: ''
        }
      ]
    })

    const orgSelectList: {
      orgList: object[],
      counselorList: object[],
      testSetList: object[]
    } = reactive({
      orgList: [],
      counselorList: [],
      testSetList: []
    });

    const orgSelectItems: {
      organization: any,
      counselor: any,
      testSet: any
    } = reactive({
      organization: null,
      counselor: null,
      testSet: null,
    })

    const deleteGuardianList: number[] = reactive([]);

    /** 연락처 유효성 체크 및 '-' 추가 */
    const handleChangeCounseleePhone = (word: string) => {
      const phone:any = word ? word.replaceAll('-', '') : '';
      if (phone.length > 11) {
        notify.warning('연락처는 11자 이내로 입력해주세요');
        counseleeInfo.phone = '';
        return;
      }
      if (!isNaN(phone)) {
        counseleeInfo.phone = filters.getFormedPhoneNumber(phone);
      } else {
        notify.warning('연락처를 정확히 입력해주세요');
        counseleeInfo.phone = '';
      }
    }

     /** 보호자 연락처 유효성 체크 및 '-' 추가 */
    const handleChangeGuardianPhone = (word: string, index: number) => {
      const phone:any = word ? word.replaceAll('-', '') : '';
      if (phone.length > 11) {
        notify.warning('연락처는 11자 이내로 입력해주세요');
        counseleeInfo.parentList[index].phone = '';
        return;
      }
      
      if (!isNaN(phone)) {
        counseleeInfo.parentList[index].phone = filters.getFormedPhoneNumber(phone);
      } else {
        notify.warning('연락처를 정확히 입력해주세요');
        counseleeInfo.parentList[index].phone = '';
      }
    }

    /**보호자 추가 */
    const addGuardian = () => {
      if (counseleeInfo.parentList.length > 10) {
        notify.warning('보호자를 더 입력할 수 없습니다');
        return;
      }
      pscModels.pscItems.push({
          pscScore: '',
          pscDate: ''
      })
      counseleeInfo.parentList.push({
        name: '',
        birthDate: '',
        gender: '',
        phone: '',
        relation: null,
        relationDetail: '',
        memo: '',
        pscList: [],
        id: null,
        age: null
      })
      setTimeout(() =>{
        window.scrollTo({
          top: window.innerHeight + (390*counseleeInfo.parentList.length), 
          left: 0, 
          behavior: 'smooth'
        })
      }, 100)
    }



    /**보호자 삭제 */
    const removeGuardian = (index : number) => {
      const currentParent = {...counseleeInfo.parentList[index]};
      counseleeInfo.parentList.splice(index, 1)
      if (currentParent.id === 0 || currentParent.id)
      deleteGuardianList.push(currentParent.id)
    }

    /** psc 점수 추가 */
    const addPsc = (index: number) => {
      const tmpPscDate = new Date(pscModels.pscItems[index].pscDate)
      if (pscModels.pscItems[index].pscScore === '' 
      || pscModels.pscItems[index].pscDate === ''
      || !numberRegex.test(pscModels.pscItems[index].pscScore)
      || isNaN(Number.parseInt(pscModels.pscItems[index].pscScore))
      || tmpPscDate.toString() === 'Invalid Date'
      ) {
        notify.warning('psc 항목을 정확히 입력해주세요');
        return;
      }
      if (Number.parseInt(pscModels.pscItems[index].pscScore) > 1000) {
        notify.warning('psc 점수는 1000점을 초과할 수 없습니다.');
        return;
      }

      counseleeInfo.parentList[index].pscList.push({
        score: Number.parseInt(pscModels.pscItems[index].pscScore).toString(),
        date: filters.dateToString(pscModels.pscItems[index].pscDate, 'yyyy-mm-dd')
      })

      counseleeInfo.parentList[index].pscList.sort((a:any,b:any) => {
        return new Date(a.date).getTime() - new Date(b.date).getTime()
      })

      pscModels.pscItems[index] = [
        {
          pscScore: '',
          pscDate: ''
        }
      ]
    }

    /** psc 점수 삭제 */
    const removePsc = (index: number, pscIndex: number) => {
      counseleeInfo.parentList[index].pscList.splice(pscIndex, 1)
    }

    /** 기관 list get api */
    const getOrganizationList = () => {
      return new Promise((resolve: any, reject: any) => {
        CounseleeAPI.getOrganizationList()
        .then((response) => {
          const responseData = response.data
          if (responseData 
          && typeof responseData === 'object'
          && Object.keys(responseData).indexOf('result') > -1
          && Object.keys(responseData).indexOf('response') > -1
          && responseData.result ) {
            const {
              list
            } = responseData.response;
            const tmpList: {}[] = []
            for (const item of list) {
              tmpList.push({
                label: item.name,
                value: Number.parseInt(item.id) 
              })
            }
            orgSelectList.orgList = tmpList
            resolve();
          }
        })
        .catch(() => {
          reject();
        })
      })
    }

    /** 상담사 list get api */
    const getOrgCounselorList = (id: string) => {
      return new Promise((resolve: any, reject: any) => {
        CounseleeAPI.getOrgCounselorList({
          id: id,
        })
        .then((response) => {
          const responseData = response.data
          if (responseData 
          && typeof responseData === 'object'
          && Object.keys(responseData).indexOf('result') > -1
          && Object.keys(responseData).indexOf('response') > -1
          && responseData.result ) {
            const {
              list
            } = responseData.response;
            const tmpList = [];
            for (const item of list) {
              tmpList.push({
                label: item.name,
                value: item.id
              })
            }
            orgSelectList.counselorList = tmpList
            resolve();
          }
        })
        .catch(() => {
          reject();
        })
      })
    }

    
    /** 기관별 테스트셋 list get api */
    const getOrtDetail = async(id: string) => {
      await OrgAPI.getOrgDetail({id})
      .then((response) => {
        const responseData = response.data;
        if (responseData 
        && typeof responseData === 'object'
        && Object.keys(responseData).indexOf('result') > -1
        && Object.keys(responseData).indexOf('response') > -1
        && responseData.result ) {
          const testSetList = responseData.response.testSetList;
          const tmpList:any[] = [
            {
              name: '선택 안함',
              id: ''
            }
          ];
          if (Array.isArray(testSetList)) {
            for (const item of testSetList) {
              tmpList.push(item)
            }
          }
          orgSelectList.testSetList = tmpList;
        } else {
          throw 'err';
        }
      })
      .catch((err) => {
        throw err;
      })
    }

    /** 내담자 수정 params 만드는 fnc */
    const getEditParams = () => {      
      const {
        id,
        name,
        birthDate,
        gender,
        schoolKind,
        schoolYear,
        phone,
        careHistory,
        memo,
        parentList,
      } = counseleeInfo;


      const params:any = {
        id,
        name,
        birthDate: birthDate.length === 8 ? birthDate : filters.dateToString(birthDate, 'yyyymmdd'),
        gender,
        schoolKind,
        schoolYear,
        parentsList: [],
      };

      if (!name
      || !birthDate
      || !gender
      || (schoolKind !== 0 && !schoolKind)
      || !schoolYear
      ) {
        notify.warning('내담자 정보를 모두 입력해주세요');
        return;
      }

      if (koreanEngRegex.test(name)) {
        params.name = name;
      } else {
        notify.warning('이름에는 한글이나 영문만 입력할 수 있습니다');
        return;
      }

      if (!orgSelectItems.organization || !orgSelectItems.counselor) {
        notify.warning('기관 정보를 모두 입력해주세요');
        return;
      } else {
        const {
          organization,
          counselor
        } = orgSelectItems;

        params.organizationId = organization;
        params.counselorId = counselor;
      }

      if (parentList.length === 0) {
        notify.warning('보호자 정보를 입력해주세요');
        return;
      }
      

      for (let i = 0; i < parentList.length; i++) {
        if (!parentList[i].name
        || !parentList[i].birthDate
        || !parentList[i].gender
        || !parentList[i].phone
        || (parentList[i].relation !== 0 && !parentList[i].relation)
        ) {
          notify.warning('보호자 정보를 모두 입력해주세요');
          return;
        } else {
          const tmpGuardian: any = {
            name: parentList[i].name,
            birthDate: parentList[i].birthDate.length === 8 
            ? parentList[i].birthDate 
            : filters.dateToString(parentList[i].birthDate, 'yyyymmdd'),
            gender: parentList[i].gender,
            relation: parentList[i].relation,
            order: i + 1,
          }

          if (phoneRegex.test(parentList[i].phone)) {
            const onlyPhoneNumber = parentList[i].phone.replaceAll('-', '');
            if (onlyPhoneNumber.length > 11) {
              notify.warning('보호자 연락처를 11자 이내로 입력해 주세요');
              return;
            } else {
              tmpGuardian.phone = onlyPhoneNumber;
            }
          } else {
            notify.warning('보호자 연락처를 정확히 입력해 주세요');
            return;
          }

          if (koreanEngRegex.test(parentList[i].name)) {
            tmpGuardian.name = parentList[i].name;
          } else {
            notify.warning('이름에는 한글이나 영문만 입력할 수 있습니다');
            return;
          }

          const parentBirthDateTime = parentList[i].birthDate.length === 8
          ? filters.getFormedDate(parentList[i].birthDate)
          : parentList[i].birthDate;

          const counseleeBirthDateTime = counseleeInfo.birthDate.length === 8
          ? filters.getFormedDate(counseleeInfo.birthDate)
          : counseleeInfo.birthDate;
          
          if ((new Date(counseleeBirthDateTime).getTime() - new Date(parentBirthDateTime).getTime()) < 0) {
            notify.warning('내담자 생년월일은 보호자보다 빠를 수 없습니다.');
            return;
          }

          if (parentList[i].id === 0 || parentList[i].id) {
            tmpGuardian.id = parentList[i].id;
          }

          tmpGuardian.memo = parentList[i].memo;

          if (parentList[i].pscList.length > 0) {
            const tmpList:any[] = [];
            for(let j = 0; j < parentList[i].pscList.length; j++ ) {
              tmpList[j] = {
                score: parentList[i].pscList[j].score,
                date: filters.dateToString(parentList[i].pscList[j].date, 'yyyymmdd')
              }
              if ( Object.keys(parentList[i].pscList[j]).indexOf('id') > -1 
              && ( parentList[i].pscList[j].id === 0 || parentList[i].pscList[j].id ) ) {
                tmpList[j].id = parentList[i].pscList[j].id;
              }
            }
            tmpGuardian.pscList = tmpList;
          }

          if (parentList[i].relation === 6) {
            tmpGuardian.relationDetail = parentList[i].relationDetail;
          }

          params.parentsList.push(tmpGuardian);

        }
      }

      
      if (phone && phone !== '') {
        if (phoneRegex.test(phone)) {
          const onlyPhoneNumber = phone.replaceAll('-', '');
          if (onlyPhoneNumber.length > 11) {
            notify.warning('연락처를 11자 이내로 입력해 주세요');
            return;
          } else {
            params.phone = onlyPhoneNumber;
          }
        } else {
          notify.warning('연락처를 정확히 입력해 주세요');
          return;
        }
      } else {
        params.phone = '';
      }

      if (careHistory.length > 0) 
        params.careHistory = careHistory ;
      
      if (orgSelectItems.testSet === '') 
        params.testSetId = null;
      else 
        params.testSetId = orgSelectItems.testSet;
      

      params.memo = memo;

      if (deleteGuardianList.length > 0) {
        params.deleteParentsList = deleteGuardianList;
      }

      return params;

    }

    /** 내담자 수정 api */
    const putEditCounseleeInfo = () => {
      return new Promise((resolve: any, reject: any) => {
        const params = getEditParams();
        if (params) {
          CounseleeAPI.putEditCounseleeInfo(params)
          .then((response) => {
            const responseData= response.data
            if(responseData 
            && typeof responseData === 'object'
            && Object.keys(responseData).indexOf('result') > -1
            && Object.keys(responseData).indexOf('response') > -1
            && responseData.result ) {

              const msgBoxMsg:string = downloadQRmsgData.isWarned 
              ? '내담자 수정이 완료되었습니다. 큐알코드를 다시 다운로드 하세요'
              : '내담자 수정이 완료되었습니다.'

              MessageBox({
                title: '수정 완료',
                message: msgBoxMsg,
                callback: router.back 
              });
              resolve();
            } else {
              reject();
            }
          })
          .catch(() => {
            reject();
          })  
        } else {
          resolve();
        }
      })
    }

    /** 내담자 정보 get api */
    const getCounseleeDetail = () => {
      return new Promise((resolve: any, reject: any) => {
        const params: object = {
          id: route.params.id
        }

        CounseleeAPI.getCounseleeDetail(params)
        .then((response) => {
          const responseData = response.data
          if (responseData 
          && typeof responseData === 'object'
          && Object.keys(responseData).indexOf('result') > -1
          && Object.keys(responseData).indexOf('response') > -1
          && responseData.result ) {
            const {
              birthDate,
              careHistory,
              careHistoryString,
              counselor,
              counselorId,
              createAt,
              gender,
              id,
              memo,
              name,
              organization,
              organizationId,
              parentsList,
              phone,
              qrCode,
              schoolKind,
              schoolKindString,
              schoolYear,
              testCnt,
              testFlag,
              progressStatus,
              testSetId
            } = responseData.response;

            /** 잘못된 이름url로 들어올 경우 */
            if (route.params.name !== name) {
              router.replace({
                name: 'editCounseleeInfo',
                params: {
                  id: route.params.id,
                  name: name,
                },
                query: route.query
              })
              .catch(() => {})
            }

            counseleeInfo.id = id;
            counseleeInfo.name = name;
            counseleeInfo.birthDate = birthDate;
            counseleeInfo.gender = gender;
            counseleeInfo.schoolKind = Number.parseInt(schoolKind);
            counseleeInfo.memo = memo;
            counseleeInfo.schoolYear = Number.parseInt(schoolYear);
            counseleeInfo.testFlag = testFlag;
            counseleeInfo.testSet = testSetId;
            counseleeInfo.progressStatus = progressStatus;

            handleChangeCounseleePhone(phone);

            const tmpCareHistory:number[] = []
            const tmpParentList:object[] = []
            
            for (const careItem of careHistory) {
              tmpCareHistory.push(
                Number.parseInt(careItem)
              );
            }

            for (let i = 0; i < parentsList.length; i++) {
              tmpParentList.push({
                id: parentsList[i].id,
                name: parentsList[i].name,
                birthDate: parentsList[i].birthDate,
                gender: parentsList[i].gender,
                phone: parentsList[i].phone ? filters.getFormedPhoneNumber(parentsList[i].phone) : '',
                relation: Number.parseInt(parentsList[i].relation),
                relationDetail: parentsList[i].relationDetail,
                memo: parentsList[i].memo,
                pscList: parentsList[i].pscList
              })

              pscModels.pscItems[i] = [
                {
                  pscScore: '',
                  pscDate: ''
                }
              ]
            }
            counseleeInfo.careHistory = tmpCareHistory;
            counseleeInfo.parentList = tmpParentList;

            orgSelectItems.organization = organizationId;
            orgSelectItems.counselor = counselorId;
            orgSelectItems.testSet = testSetId;
            
            resolve(id);
            
          }
        })
        .catch(() => {
          reject();
        })
      })
    }

    /** 취소 클릭 */
    const handleClickCancel = () => {
      router.push({
        name: 'manageCounselee',
        query: route.query
      })
    }

    /** 저장버튼 클릭 */
    const handleClickSave = () => {
      store.commit('loadingBar/showLoadingBar');
      putEditCounseleeInfo()
      .then(() => {
        store.commit('loadingBar/hideLoadingBar');
      })
      .catch(() => {
        router.push({ name: 'error' }).catch(() => {});
      })
    }

    onBeforeMount(() => {
      store.commit('loadingBar/showLoadingBar');
      Promise.all([
        getOrganizationList(),
        getCounseleeDetail()
      ])
      .then(() => {
        store.commit('loadingBar/hideLoadingBar');
        downloadQRmsgData.isMounted = true;
      })
      .catch(() => {
        router.push({ name: 'error' }).catch(() => {});
      })
    })

    /** 담당기관에 따라 상담사 리스트 조정 */
    watch(() => orgSelectItems.organization,
      (organization:any, prevOrg: any) => {
        if (prevOrg !== null) {
          orgSelectItems.counselor = null;
          orgSelectItems.testSet = null;
        }
        store.commit('loadingBar/showLoadingBar');
        Promise.all([
          getOrgCounselorList(organization),
          getOrtDetail(organization)
        ])
        .then(() => {
          store.commit('loadingBar/hideLoadingBar');
        })
        .catch(() => {
          router.push({ name: 'error' }).catch(() => {});
        })
    });

    /** 학교 변경 시 학년 reset */
    watch(() => counseleeInfo.schoolKind,
      (schoolKind:any, prevSchoolKind: any) => {
        if (prevSchoolKind !== null) {
          counseleeInfo.schoolYear = null;
        }
        switch (schoolKind) {
          case 0: selectList.schoolYearList = childYears; break;
          case 1: selectList.schoolYearList = [...gradeList].slice(0, 6); break;
          case 2: case 3: selectList.schoolYearList = [...gradeList].slice(0, 3); break;
          case 4: selectList.schoolYearList = gradeList; break;
          default: selectList.schoolYearList = []; break;
        }
    });

    /** 내담자 기본정보 변경시 QR 코드 재 다운로드 알람 */
    watch(() => [
      counseleeInfo.name,
      counseleeInfo.gender,
      counseleeInfo.birthDate,
      counseleeInfo.phone,
      counseleeInfo.careHistory,
      counseleeInfo.schoolKind,
      counseleeInfo.schoolYear,
      counseleeInfo.memo,
    ],
      () => {
        if (downloadQRmsgData.isMounted && !downloadQRmsgData.isWarned) {
          MessageBox({
            title: '내용변경 안내',
            message: '내담자의 이름, 생년월일 변경 후에는 QR코드가 새로 발급됩니다. 수정 완료 및 저장 후 QR 코드를 다운로드 받으세요.',
            type: 'warning'
          });
          downloadQRmsgData.isWarned = true;
        }
      }
    )

    return {
      relationList,
      genderList,
      orgSelectList,
      orgSelectItems,
      careHistoryList,
      counseleeInfo,
      addGuardian,
      gradeList,
      schoolList,
      removeGuardian,
      pscModels,
      addPsc,
      removePsc,
      putEditCounseleeInfo,
      childYears,
      handleClickSave,
      handleChangeCounseleePhone,
      handleChangeGuardianPhone,
      selectList,
      handleClickCancel,
      filters
    }
  }

}
</script>