/** 쿠키정보 get */
const getCookie = (cname: string) => {
  const name = cname + '=';
  const ca = document.cookie.split(';');
  for (let c of ca) {
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return decodeURI(c.substring(name.length, c.length));
    }
  }
  return '';
};

/** 쿠키정보 set */
const setCookie = (cname: string, cvalue: any, expire: number) => {
  const d = new Date();
  d.setTime(d.getTime() + (expire));
  const expires = 'expires=' + d.toUTCString();
  document.cookie = encodeURI(`${cname}=${cvalue};${expires};path=/`);
};

/** 특정 키의 쿠키 삭제 */
const deleteCookie = (cname: string) => {
  document.cookie = cname + '=; Max-Age=-99999999;';
};

export {
  getCookie,
  setCookie,
  deleteCookie,
};
