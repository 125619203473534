
import BarChart from './BarChart.vue';
import DoughnutChart from './DoughnutChart.vue';
import PieChart from './PieChart.vue';
import LineChart from './LineChart.vue';
export default {
  name: 'chart',
  components: {
    BarChart,
    DoughnutChart,
    PieChart,
    LineChart
  },
  props: {
    chartStyle: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true
    },
    data: {
      type: Object,
      required: true
    },
    options: {
      type: Object,
      default: () => ({})
    },
  },
}
