<template>
  <div class="manage-master-wrapper">
    <div class="search-box-wrapper">
      <label>{{'검색 옵션'}}</label>
      <div class="search-condition-wrapper">
        <div class="search-condition-item">
          <el-date-picker
            type="daterange"
            start-placeholder="시작일 선택"
            end-placeholder="종료일 선택"
            v-model="listOption.date"
            :disabled-date="filters.disabledDate"
          />
        </div>
        <div class="search-condition-item">
          <el-tooltip placement="top">
            <template #content>
              연락처는 전체를 입력해주세요
            </template>
            <el-input
              placeholder="검색어 입력(관리자명, 관리자ID, 연락처)"
              @keydown.enter="handleClickSearch"
              v-model="listOption.search"
              maxlength="150"
            />
          </el-tooltip>
        </div>
        <el-button
          type="info"
          class="search"
          @click="handleClickSearch"
          :disabled="listOption.entireCount === 0"
        >{{'검색'}}</el-button>
      </div>
    </div>
    <div class="list-upper-wrapper">
      <label>{{`${listOption.totalCount} / ${listOption.entireCount}`}}</label>
      <el-button
        type="primary"
        @click="handleClickCreateMaster"
      >
        <i class="el-icon-plus"/>
        {{'관리자 등록'}}
      </el-button>
    </div>
    <List
      :selectedPage="listOption.page"
      :maxPage="listOption.maxPage"
      :totalCount="listOption.totalCount"
      :pagination="true"
      @changePage="changePage"
    >
      <template v-slot:list-table>
        <el-table
          :data="listOption.list"
          @row-click="handleRowClick"
          style="cursor:pointer;"
          empty-text="관리자 정보가 없습니다."
        >
          <el-table-column
            align ="center"
            width="50"
            label = "No"
            type="index"
            :index="getIndex"
            fixed
          />
          <el-table-column
            align ="center"
            prop="name"
            label="관리자명"
          >
            <template #header>
              {{'관리자명'}}
              <span 
                class="icon pointer"
                @click="sort('name')"
              >
                <i 
                  v-if="listOption.orderField === 'name' 
                        && listOption.order === 'asc' 
                        ? true : false" 
                  class='el-icon-caret-top'
                />
                <i v-else class="el-icon-caret-bottom"/>
              </span>
            </template>
            <template #default="scope">
              <a>
                {{ scope.row.name }}
              </a>
            </template>
          </el-table-column>
          <el-table-column
            align ="center"
            prop="mail"
            label="관리자 ID"
          >
            <template #header>
              {{'관리자 ID'}}
              <span 
                class="icon pointer"
                @click="sort('mail')"
              >
                <i 
                  v-if="listOption.orderField === 'mail' 
                        && listOption.order === 'asc' 
                        ? true : false" 
                  class='el-icon-caret-top'
                />
                <i v-else class="el-icon-caret-bottom"/>
              </span>
            </template>
          </el-table-column>
          <el-table-column
            align ="center"
            prop="phone"
            label="관리자 연락처"
          >
            <template #header>
              {{ '관리자 연락처' }}
              <span 
                class="icon pointer"
                @click="sort('phone')"
              >
                <i 
                  v-if="listOption.orderField === 'phone' 
                        && listOption.order === 'asc' 
                        ? true : false" 
                  class='el-icon-caret-top'
                />
                <i v-else class="el-icon-caret-bottom"/>
              </span>
            </template>
            <template #default="scope">
              {{ filters.getFormedPhoneNumber(scope.row.phone) }}
            </template>
          </el-table-column>
          <el-table-column
            align ="center"
            prop="createAt"
            label="등록일시"
          >
            <template #header>
              {{'등록일시'}}
              <span 
                class="icon pointer"
                @click="sort('createAt')"
              >
                <i 
                  v-if="listOption.orderField === 'createAt' 
                        && listOption.order === 'asc' 
                        ? true : false" 
                  class='el-icon-caret-top'
                />
                <i v-else class="el-icon-caret-bottom"/>
              </span>
            </template>
            <template #default="scope">
              <span v-html="getTableTime(scope.row.createAt)" />
            </template>
          </el-table-column>
        </el-table>
      </template>
    </List>
  </div>
</template>

<script lang="ts">
import { onBeforeMount, onMounted, reactive } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import filters from '@/utils/filter';
import List from '@/components/List/List.vue';
import MasterAPI from '@/api/master';
import _MessageBox from 'element-plus/lib/el-message-box';
export default {
  name: 'manageMaster',
  components: {
    List
  },
  setup(){
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const listOption: {
      maxPage: number,
      totalCount: number,
      page: number,
      list: any[],
      order: string,
      orderField: string,
      entireCount: number,
      search: string,
      date: any[]
    } = reactive({
      maxPage: 10,
      totalCount: 0,
      page: 1,
      list: [],
      order: '',
      orderField: '',
      entireCount: 0,
      search: '',
      date: []
    });

    /** 검색쿼리 url 저장 */
    const setUrlQueryString = () => {
      const {
        search,
        date,
        page,
        order,
        orderField
      } = listOption;

      const query:any = {};

      if (Array.isArray(date)) {
        const tmpList = [];
        for (const item of date) {
          item && tmpList.push(filters.dateToString(item, 'yyyy-mm-dd'));
        }
        query.date = tmpList;
      }

      if (page !== 1)
        query.page = page;

      if (order)
        query.order = order;
      
      if (orderField)
        query.orderField = orderField;

      if (search)
        query.search = search;

      router.replace({
        name: 'manageMaster',
        query
      }).catch(() => {});
    }

    /** 관리자 리스트 params */
    const getParams = () => {
      const params:any = {};
      const {
        date,
        search,
        order,
        orderField
      } = listOption;

      if (date && date.length === 2) {
        params.gte = filters.dateToString(date[0], 'yyyy-mm-dd');
        params.lte = filters.dateToString(date[1], 'yyyy-mm-dd');
      } 

      if (orderField) 
        params.orderField = orderField;
      
      
      if (order) 
        params.order = order;
      

      if (search) 
        params.search = search.replaceAll('-', '');

      return params;
    }

    /** 관리자 리스트 get api */
    const getMasterList = async() => {
      const params = getParams();
      await MasterAPI.getMasterList(params)
      .then((response) => {
        const responseData = response.data;
        if (responseData 
        && typeof responseData === 'object'
        && Object.keys(responseData).indexOf('result') > -1
        && Object.keys(responseData).indexOf('response') > -1
        && responseData.result ) {
          const {
            entireCount,
            totalCount,
            // next,
            // previous,
            // startPage,
            // endPage,
            list
          } = responseData.response;

          if (typeof entireCount === 'number')
            listOption.entireCount = entireCount;
          else {
            if (typeof entireCount === 'string'
            && !isNaN(Number.parseInt(entireCount)))
              listOption.entireCount = Number.parseInt(entireCount);
          }

          
          if (typeof totalCount === 'number')
            listOption.totalCount = totalCount;
          else {
            if (typeof totalCount === 'string'
            && !isNaN(Number.parseInt(totalCount)))
              listOption.totalCount = Number.parseInt(totalCount);
          }

          if (Array.isArray(list))
            listOption.list = list;
          else
            listOption.list = [];
        }
      })
      .catch(error => {
        if (typeof error === 'object'
        && Object.keys(error).indexOf('response') > -1
        && typeof error.response === 'object'
        && Object.keys(error.response).indexOf('status') > -1
        && Object.keys(error.response).indexOf('data') > -1
        && Object.keys(error.response.data).indexOf('response') > -1
        && Object.keys(error.response.data.response).indexOf('message') > -1) {
          const status = error.response.status;
          if (status === 400) {
            if (error.response.data.response.message === 'Only master can do this.') {
              _MessageBox({
                type: 'error',
                message: '잘못된 접근입니다.',
                callback() {
                  router.push({ name: 'manageCounselee' }).catch(() => {});
                }
              });
              return 1;
            } else {
              throw 'err';
            }
          } else {
            throw error;
          }
        } else {
          throw error;
        }
      })
    }

    /** 초기화 */
    const refresh =() => {
      store.commit('loadingBar/showLoadingBar');
      getMasterList()
      .then(() => {
        store.commit('loadingBar/hideLoadingBar');
      })
      .catch(() => {
        router.push({ name: 'error' }).catch(() => {});
      })
    }

    onBeforeMount(() => {
      if (Object.keys(route.query).length > 0) {
        const {
          page,
          order,
          orderField,
          search,
          date
        } = route.query;
        listOption.page = Number.parseInt(page ? page.toString() : '1');
        listOption.order = order ? order.toString() : '';
        listOption.orderField = orderField ? orderField.toString() : '';
        listOption.search = search ? search.toString() : '';

        if (Array.isArray(date)) {
          const tmpList = [];
          for (const item of date) {
            item && tmpList.push(new Date(item));
          }
          listOption.date = tmpList;
        }
      }
    })

    onMounted(() => {
      refresh();
    })

    return {
      listOption,
      filters,
      getIndex(index: number){
        const {
          totalCount,
          maxPage,
          page
        } = listOption;
        return totalCount - (maxPage * (page - 1)) - index;
      },
      getTableTime(date: string) {
        const dateObj:any = new Date(date);
        let result: string = '';
        (!date || dateObj.toString() === 'Invalid Date') 
        ? result = '-'
        : result = filters.dateToString(dateObj, 'tableTime');
        return result.replaceAll('<br/>', ' ');
      },
      handleClickSearch() {
        listOption.page = 1;
        setUrlQueryString();
      },
      handleClickCreateMaster() {
        router.push({ 
          name: 'createEditMaster',
          params: {
            status: 0,
            id: 0
          } 
        }).catch(() => {});
      },
      changePage(page:number) {
        listOption.page = page;
        setUrlQueryString();
      },
      handleRowClick(row: any) {
        router.push({ 
          name: 'createEditMaster',
          params: {
            status: 1,
            id: row.id
          } 
        }).catch(() => {});
      },
      sort(orderField:string) {
        if (listOption.orderField === orderField) {
          (listOption.order === 'desc') ? listOption.order = 'asc' : listOption.order = 'desc';
        } else {
          listOption.orderField = orderField;
          listOption.order = 'desc';
        }
        setUrlQueryString();
      }
    }
  }
}
</script>

<style>

</style>