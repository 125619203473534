const filters = {
  dateToString: (dateObj: any, format: string) => {
    if (!dateObj || dateObj === '') {
      return '-';
    }
    const date = (dateObj instanceof Date) ? new Date(dateObj) : new Date(`${dateObj}`.replace(/\s/, 'T').replace('Z', ''));
    // ES 저장 시 로컬 시간으로 저장하나, 자동으로 UTC 형태로 들어가는 경우에 대한 방어로직 추가
    const year = date.getFullYear();
    const month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
    const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
    const hours = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours();
    const minutes = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();
    const seconds = date.getSeconds() < 10 ? `0${date.getSeconds()}` : date.getSeconds();

    if (format === 'yyyy-mm-dd hh:mm:ss') {
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    } else if (format === 'yyyy-mm-dd hh:mm') {
      return `${year}-${month}-${day} ${hours}:${minutes}`;
    } else if (format === 'yyyy-mm-dd') {
      return `${year}-${month}-${day}`;
    } else if (format === 'tableTime') {
      return `${year}-${month}-${day}<br/>[${hours}:${minutes}]`;
    } else if (format === 'ISO') {
      return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
    } else if (format === 'yyyymmdd') {
      return `${year}${month}${day}`;
    } else if (format === 'yyyy.mm.dd'){
      return `${year}.${month}.${day}`;
    } else {
      return '';
    }
  },
  stringToDate: (dateStr: string) => {
    return new Date(dateStr);
  },
  comma: (value: any) => {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  },
  numberToString: (num: string) => {
    return parseInt(num, 10) < 10 ? `0${num}` : num;
  },
  getFormedDate: (date: string) => {
    return `${date.slice(0, 4)}.${date.slice(4, 6)}.${date.slice(6, 8)}`;
  },
  birthDateToAge: (birthDate: string) => {
    const today = new Date();
    const birthDay = new Date(birthDate);

    return today.getFullYear() - birthDay.getFullYear() + 1;
  },
  getFormedPhoneNumber: (phoneString: string) => {
    const numString = phoneString ? phoneString.replaceAll('-', '') : '';
    const originLength = numString ? numString.length : 0;
    if (originLength > 0 && originLength < 7) {
      return numString;
    } else if (originLength >= 7 && originLength < 9) {
      return `${numString.slice(0, 3)}-${numString.slice(3, originLength)}`;
    } else if (originLength === 9) {
      return `${numString.slice(0, 2)}-${numString.slice(2, 5)}-${numString.slice(5, 9)}`;
    } else if (originLength === 10) {
      return `${numString.slice(0, 3)}-${numString.slice(3, 6)}-${numString.slice(6, 10)}`;
    } else if (originLength === 11) {
      return `${numString.slice(0, 3)}-${numString.slice(3, 7)}-${numString.slice(7, 11)}`;
    } else if (originLength >= 11) {
      return `${numString.slice(0, 3)}-${numString.slice(3, 7)}-${numString.slice(7, originLength)}`;
    }
    return '';
  },
  disabledDate( time: Date ) {
    return time.getTime() > Date.now();
  },
};

export default filters;
