
import { deleteCookie, getCookie } from '@/utils/cookie'
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import AccountAPI from '@/api/account';
export default {
  name: 'Header',
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const userId = getCookie('userId');
    const userLevel = getCookie('userLevel');

    /**조앤 로고 클릭 */
    const handleClickBi = () => {
      router.push('/').catch(() => {});
    }


    /** 로그아웃 api */
    const logout = async() => {
      await AccountAPI.logout()
      .then(() => {
        deleteCookie('Authorization');
        deleteCookie('organizationId');
        deleteCookie('userId');
        deleteCookie('userName');
        deleteCookie('userType');
        deleteCookie('userLevel');
      })
    }

    /** 메뉴 클릭 */
    const handleSelect = (index:number) => {
      let viewName = 'manageCounselee';

      switch(index) {
        case 1: break;
        case 2: viewName = 'manageUser'; break;
        case 3: viewName = 'manageReport'; break;
        case 4: viewName = 'manageApp'; break;
        case 5: viewName = 'manageStatistics'; break;
        case 6: viewName = 'manageOrg'; break;
        case 7: viewName = 'manageMaster'; break;
        case 8: viewName = 'orgTestStatistics'; break;
        case 9: viewName = 'testSetStatistics'; break;
        case 10: viewName = 'counselorCounseleeStatistics'; break;
        default: break;
      }

      router.push({
        name: viewName,
      }).catch(() => {})
    }

    /** 로그아웃 클릭 */
    const handleClickLogout = () => {
      store.commit('loadingBar/showLoadingBar');
      logout()
      .then(() => {
        router.push({ name: 'login' }).catch(() => {});
        store.commit('loadingBar/hideLoadingBar');
      })
    }

    /** css 용 url 매치 여부 */
    const getCurrentMenu = () => {
      const currentPathName = route.matched[1].path.split('/')[1]
      switch(currentPathName) {
        case 'counselee': return 1;
        case 'user': return 2;
        case 'report': return 3;
        case 'app': return 4;
        case 'statistics': return 5;
        case 'org': return 6;
        case 'master': return 7;
        default: return 0;
      }
    }

    /** 등급별 마이페이지 이동 */
    const handleClickMyPage = () => {
      let menuName = '';
      switch(userLevel) {
        case '0': menuName = 'masterMyPage'; break;
        case '1': menuName = 'adminMyPage'; break;
        case '2': menuName = 'counselorMyPage'; break;
        default: break;
      }

      if (menuName) {
        router.push({ 
          name: menuName,
        }).catch(() => {});
      }
    }

    return {
      userId,
      userLevel,
      handleClickLogout,
      handleSelect,
      getCurrentMenu,
      handleClickBi,
      handleClickMyPage
    }
  },
}
