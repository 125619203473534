
import { onBeforeMount, reactive, ref, watch, computed, onMounted } from "vue";
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import List from '@/components/List/List.vue';
import Modal from '@/components/Modal/Modal.vue';
import CounseleeAPI from '@/api/counselee';
import CounselorAPI from '@/api/counselor'; 
import filters from '@/utils/filter';

export default {
  name: 'manageUser',
  components: {
    List,
    Modal
  },
  setup() {
    const store = useStore();  
    const route = useRoute(); 
    const router = useRouter(); 
    const selectList: {
      agencyList: any[]
    } = reactive({
      agencyList: []
    });

    const listOption: {
      maxPage: number,
      entireCount: number,
      totalCount: number,
      page: number,
      order: string,
      orderField: string,
      list: any[],
      agency: any[],
      search: string,
      date: any[]
    } = reactive({
      maxPage: 10,
      entireCount: 0,
      totalCount: 0,
      page: 1,
      order: '',
      orderField: '',
      list: [],
      agency: [],
      search: '',
      date: []
    });

    const modalOption:{
      title: string,
      rightButtonName: string,
      rightButtonCallBack: any,
      totalCount: number,
      list: any[],
      loading: boolean,
      visible: boolean,
    } = reactive({
      title: '',
      rightButtonName: '닫기',
      rightButtonCallBack: () => store.commit('modal/closeModal'),
      totalCount: 0,
      list: [],
      loading: false,
      visible: computed(() => store.getters['modal/modalVisible']),
    });

    /** 검색쿼리 url 저장 */
    const setUrlQueryString = () => {
      const {
        page,
        order,
        orderField,
        agency,
        search,
        date
      } = listOption;
      const query:any = {};

      if (page !== 1)
        query.page = page;
      
      if (order)
        query.order = order;
      
      if (orderField)
        query.orderField = orderField;

      if (search)
        query.search = search;
      
      if (agency.length > 0) 
        query.agency = agency;
      
      if (Array.isArray(date)) {
        const tmpList = [];
        for (const item of date) {
          item && tmpList.push(filters.dateToString(item, 'yyyy-mm-dd'));
        }
        query.date = tmpList;
      }

      router.replace({
        name: 'manageUser',
        query
      }).catch(() => {});
    }

    /** 리스트 No */
    const getIndex = (index: number) => {
      const {
        totalCount,
        maxPage,
        page
      } = listOption;
      return totalCount - (maxPage * (page - 1)) - index;
    }

     /** 기관정보 get api */
    const getOrganizationList = async() => {
      await CounseleeAPI.getOrganizationList()
      .then((response) => {
        const responseData = response.data;
        if (responseData 
        && typeof responseData === 'object'
        && Object.keys(responseData).indexOf('result') > -1
        && Object.keys(responseData).indexOf('response') > -1
        && responseData.result ) {
          const {
            list
          } = responseData.response;
          const tmpList = [
            {
              label: '전체', 
              value: 'all'
            }
          ]
          for (const item of list) {
            tmpList.push({
              label: item.name,
              value: item.id
            })
          }
          selectList.agencyList = tmpList
        } else {
          throw 'error';
        }
      })
      .catch((err) => {
        throw err;
      })
    }

    /** 상담사 list get api params */
    const getParams = () => {
      const {
        maxPage,
        page,
        order,
        orderField,
        agency,
        search,
        date
      } = listOption

      const params:any = {
        page,
        maxPage
      };

      if (date && date.length === 2) {
        params.gte = filters.dateToString(date[0], 'yyyy-mm-dd');
        params.lte = filters.dateToString(date[1], 'yyyy-mm-dd');
      } 

      if (agency.length > 0) {
        const tmpList: any[] = [];
        for (const item of agency) {
          if (item !== 'all') {
            tmpList.push(item)
          }
        }
        params.organization = JSON.stringify(tmpList);
      }

      if (search) {
        params.search = search.replaceAll('-', '');
      }

      if (order && orderField) {
        params.order = order;
        params.orderField = orderField;
      }

      return params
    }

    /** 상담사 list get api */
    const getCounselorList = async() => {
      const params = getParams();
      await CounselorAPI.getCounselorList(params)
      .then((response) => {
        const responseData = response.data
        if (responseData 
        && typeof responseData === 'object'
        && Object.keys(responseData).indexOf('result') > -1
        && Object.keys(responseData).indexOf('response') > -1
        && responseData.result ) {
          const {
            entireCount,
            totalCount,
            // next,
            // previous,
            // startPage,
            // endPage,
            list
          } = responseData.response;

          listOption.entireCount = entireCount;
          listOption.totalCount = totalCount;
          listOption.list = list;
        } else {
          throw 'error';
        }
      })
      .catch((err) => {
        throw err;
      })
    }

    /** 지정 내담자 리스트 조회 api */
    const getChargedCounseleeList = async(id:number) => {
      const params = {
        id: id
      };

      await CounselorAPI.getChargedCounseleeList(params)
      .then((response) => {
        const responseData = response.data;
        if (responseData 
        && typeof responseData === 'object'
        && Object.keys(responseData).indexOf('result') > -1
        && Object.keys(responseData).indexOf('response') > -1
        && responseData.result ) {
          const {
            // entireCount,
            list
          } = responseData.response;

          modalOption.list = list;
        } else {
          throw 'err';
        }
      })
      .catch((err) => {
        throw err;
      })
    }

    /** 보고서 작성 내담자 리스트 조회 api */
    const getReportedCounseleeList = async(id:number) => {
      const params = {
        id: id
      };

      await CounselorAPI.getReportedCounseleeList(params)
      .then((response) => {
        const responseData = response.data;
        if (responseData 
        && typeof responseData === 'object'
        && Object.keys(responseData).indexOf('result') > -1
        && Object.keys(responseData).indexOf('response') > -1
        && responseData.result ) {
          const {
            entireCount,
            list
          } = responseData.response;

          modalOption.list = list;
        } else {
          throw 'err';
        }
      })
      .catch((err) => {
        throw err;
      })

    }

    /** 검색 클릭 */
    const handleClickSearch = () =>{
      listOption.page = 1;
      setUrlQueryString();
    }

    /** 상담사 클릭 */
    const handleClickCounselor = (id: any) => {
      router.push({ 
        name: 'createEditUser',
        params: {
          status: 1,
          id: id
        },
      }).catch(() => {});
    }

    /** 상담사 등록 클릭 */
    const handleClickCreateCounselor = () => {
      router.push({ 
        name: 'createEditUser',
        params: {
          status: 0,
          id: 0
        },
      }).catch(() =>{});
    }

    /** 페이지 이동 fnc */
    const changePage = (page: number) => {
      listOption.page = page;
      setUrlQueryString();
    }

    /** 날짜 format fnc */
    const getTableTime = (date: string) => {
      const dateObj:any = new Date(date);
      let result: string = '';
      (!date || dateObj.toString() === 'Invalid Date') 
      ? result = '-'
      : result = filters.dateToString(dateObj, 'tableTime');
      return result;
    }

    /** list 정렬 */
    const sort = (orderField: string) => {
      if (listOption.orderField === orderField) {
        (listOption.order === 'desc') ? listOption.order = 'asc' : listOption.order = 'desc';
      } else {
        listOption.orderField = orderField;
        listOption.order = 'desc';
      }
      setUrlQueryString();
    }

    /** 팝업 open */
    const handleOpenModal = (type: string, counselor: any) => {
      modalOption.loading = true;
      store.commit('modal/openModal');
      if (type === 'default') {
        modalOption.title = `${counselor.name}의 지정 내담자`;
        modalOption.totalCount = counselor.counseleeCnt;
        getChargedCounseleeList(counselor.id)
        .then(() => {
          modalOption.loading = false;
        })
        .catch(() => {
          router.push({ name: 'error' }).catch(() => {});
        })
      } else if (type === 'report') {
        modalOption.title = `${counselor.name}의 보고서 작성 내담자`;
        modalOption.totalCount = counselor.reportCounseleeCnt;
        getReportedCounseleeList(counselor.id)
        .then(() => {
          modalOption.loading = false;
        })
        .catch(() => {
          router.push({ name: 'error' }).catch(() => {});
        })
      }
    }

    /** 내담자 이름 선택 */
    const handleClickCounselee = (counselee: any) => {
      store.commit('modal/closeModal');
      router.push({
        name: 'counseleeDetail',
        params: {
          id: counselee.id,
          name: counselee.name
        },
        query: route.query
      }).catch(() => {});
    }

    /** 전체 체크 fnc */
    const checkAll = (type: string) => {
      if (type === 'org') {
        const tmpList: any [] = []
        for (const item of selectList.agencyList) {
          tmpList.push(item.value)
        }
        listOption.agency = tmpList;
      }
    }

    /** 페이지 초기화 */
    const refresh = () => {
      store.commit('loadingBar/showLoadingBar');
      Promise.all([
        getOrganizationList(),
        getCounselorList()
      ])
      .then(() => {
        store.commit('loadingBar/hideLoadingBar');
      })
      .catch(() => {
        router.push({ name: 'error'}).catch(() => {});
      })
    }

    onBeforeMount(() => {
      const {
        page,
        order,
        orderField,
        search,
        agency,
        date
      } = route.query;
      if (Object.keys(route.query).length > 0) {
        listOption.page = Number.parseInt(page ? page.toString() : '1');
        listOption.order = order ? order.toString() : '';
        listOption.orderField = orderField ? orderField.toString() : '';
        listOption.search = search ? search.toString() : '';

        if (Array.isArray(agency)) {
          const tmpList = [];
          for (const item of agency) {
            if (item) {
              const tmpNum = Number.parseInt(item);
              (isNaN(tmpNum)) 
              ? tmpList.push(item)
              : tmpList.push(tmpNum);
            }
          }
          listOption.agency = tmpList;
        } else if (typeof agency === 'string' && agency !== '') {
          const tmpNum = Number.parseInt(agency);
          (isNaN(tmpNum)) 
          ? listOption.agency = [agency]
          : listOption.agency = [tmpNum]
        }

        if (Array.isArray(date)) {
          const tmpList = [];
          for (const item of date) {
            item && tmpList.push(new Date(item));
          }
          listOption.date = tmpList;
        }
      }
    })

    onMounted(() => {
      refresh();
    })

    watch(() => listOption.agency, (agency: any, prevAgency: any) => {
      if (selectList.agencyList.length > 0) {
        const checkAllIndex = agency.indexOf('all');
        const prevCheckAllIndex = prevAgency.indexOf('all'); 
        if ( checkAllIndex > -1 
        && prevCheckAllIndex < 0) {
          checkAll('org')
        } else if (checkAllIndex < 0 
        && prevCheckAllIndex > -1
        && agency.length === (selectList.agencyList.length -1)) {
          listOption.agency = [];
        } else if (checkAllIndex < 0
        && prevCheckAllIndex < 0
        && agency.length === (selectList.agencyList.length -1)) {
          checkAll('org')
        } else if (checkAllIndex > -1
        && prevCheckAllIndex > -1
        && agency.length === (selectList.agencyList.length -1)) {
          const tmpList: any [] = []
          for (const item of agency) {
            if (item !== 'all') {
              tmpList.push(item)
            }
          }
          listOption.agency = tmpList;
        }
      }
    })

    

    watch(() => modalOption.visible, (visible, prevVisible) => {
      if (prevVisible && !visible) {
        modalOption.list = [];
      }
    })


    return {
      changePage,
      listOption,
      selectList,
      handleClickSearch,
      sort,
      getTableTime,
      filters,
      handleOpenModal,
      modalOption,
      handleClickCounselee,
      getIndex,
      handleClickCounselor,
      handleClickCreateCounselor,
    }
  }

}
