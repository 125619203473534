
import { reactive } from '@vue/reactivity';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex'
import SetGroupAPI from '@/api/setGroup';
import { onBeforeMount } from '@vue/runtime-core';
import MessageBox from 'element-plus/lib/el-message-box';
import notify from '@/utils/notify';
export default {
  name: 'createEditSetGroup',
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const mode = reactive({
      status: 0
    }); // 1: 수정, 0: 등록
    const setGroupInfo: {
      name: string,
      setList: any[],
      totalSetList: any[],
      selectedTestSet: any,
      groupUseFlag: boolean,
      ownSetList: any[]
    } = reactive({
      name: '',
      setList: [],
      totalSetList: [],
      selectedTestSet: null,
      groupUseFlag: false,
      ownSetList:[]
    });

    /** 선택박스 테스트셋 전체 get api */
    const getTestSet = async() => {
      await SetGroupAPI.getTestSet()
      .then((response) => {
        const responseData = response.data;
        if (responseData 
        && typeof responseData === 'object'
        && Object.keys(responseData).indexOf('result') > -1
        && Object.keys(responseData).indexOf('response') > -1
        && responseData.result ) {
          const {
            list
          } = responseData.response;
          if (Array.isArray(list)) {
            const tmpList:{
              label: string,
              value: number
            }[] = [];
            for (const item of list) {
              tmpList.push({
                label: item.name,
                value: item.id
              })
            }
            setGroupInfo.totalSetList = tmpList;
          }
        } else {
          throw 'err';
        }
      })
      .catch((err) => {
        throw err;
      })
    }

    /** 셋 그룹 생성 */
    const postCreateSetGroup = async() => {
      const testSetList = setGroupInfo.setList.map(item => item.value);
      const params: {
        name: string,
        testSetList: any[]
      } = {
        name: '',
        testSetList
      };

      if (!setGroupInfo.name) {
        notify.warning('셋그룹명을 입력해주세요.');
        return;
      } else {
        params.name = setGroupInfo.name;
      }

      await SetGroupAPI.postCreateSetGroup(params)
      .then((response) => {
        const responseData = response.data;
        if (responseData 
        && typeof responseData === 'object'
        && Object.keys(responseData).indexOf('result') > -1
        && responseData.result ) {
          MessageBox({
            title: '셋그룹 등록 완료',
            message: '셋그룹이 등록 완료 되었습니다. 리스트페이지로 이동합니다.',
            type: 'info',
            callback: router.back
          });
        }
      })
      .catch(error => {
        if (typeof error === 'object'
        && Object.keys(error).indexOf('response') > -1
        && typeof error.response === 'object'
        && Object.keys(error.response).indexOf('status') > -1
        && Object.keys(error.response).indexOf('data') > -1
        && Object.keys(error.response.data).indexOf('response') > -1
        && Object.keys(error.response.data.response).indexOf('message') > -1) {
          const status = error.response.status;
          if (status === 400) {
            if (error.response.data.response.message === 'Test set group already exists.') {
              notify.error('이미 존재하는 셋그룹 이름입니다.');
              return 1;
            } else if (error.response.data.response.message === 'Only master can do this.') {
              MessageBox({
                type: 'error',
                message: '잘못된 접근입니다.',
                callback() {
                  router.push({ name: 'manageCounselee' }).catch(() => {});
                }
              });
              return 1;
            } else {
              throw 'err';
            }
          } else {
            throw error;
          }
        } else {
          throw error;
        }
      })
    }

    /** 구성 셋리스트 get api */
    const getOwnTestSetList = async() => {
      const params = {
        id: route.params.id
      };

      await SetGroupAPI.getOwnTestSetList(params)
      .then((response) => {
        const responseData = response.data;
        if (responseData 
        && typeof responseData === 'object'
        && Object.keys(responseData).indexOf('result') > -1
        && Object.keys(responseData).indexOf('response') > -1
        && responseData.result ) {
          const {
            // id,
            name,
            groupUseFlag,
            testSetList
          } = responseData.response;

          setGroupInfo.groupUseFlag = groupUseFlag;
          setGroupInfo.name = name;

          if (Array.isArray(testSetList)) {
            const tmpList:{
              label: string,
              value: number
            }[] = [];
            for (const item of testSetList) {
              tmpList.push({
                label: item.name,
                value: item.id
              })
            }
            setGroupInfo.setList = tmpList;
          }

          
        } else {
          throw 'err';
        }
      })
      .catch((err) => {
        throw err;
      })
    }

    /** 셋그룹 수정 api */
    const putEditSetGroup = async() => {
      const params: {
        id: number,
        name: string,
        testSetList: number[]
      } = {
        id: Number.parseInt(route.params.id.toString()),
        name: '',
        testSetList: []
      }

      if (!setGroupInfo.name) {
        notify.warning('셋그룹 명을 입력해주세요');
        return;
      } else 
        params.name = setGroupInfo.name;
      
      for (const item of setGroupInfo.setList) {
        params.testSetList.push(item.value);
      }

      
      await SetGroupAPI.putEditSetGroup(params)
      .then((response) => {
        const responseData = response.data;
        if (responseData 
        && typeof responseData === 'object'
        && Object.keys(responseData).indexOf('result') > -1
        && responseData.result ) {
          MessageBox({
            title: '셋그룹 수정 완료',
            type: 'info',
            message: '셋그룹 수정이 완료되었습니다. 리스트 페이지로 이동합니다.',
            callback: router.back
          });
        }
      })
      .catch(error => {
        if (typeof error === 'object'
        && Object.keys(error).indexOf('response') > -1
        && typeof error.response === 'object'
        && Object.keys(error.response).indexOf('status') > -1
        && Object.keys(error.response).indexOf('data') > -1
        && Object.keys(error.response.data).indexOf('response') > -1
        && Object.keys(error.response.data.response).indexOf('message') > -1) {
          const status = error.response.status;
          if (status === 400) {
            if (error.response.data.response.message === 'Test set group already exists.') {
              notify.error('이미 존재하는 셋그룹 이름입니다.');
            } else if (error.response.data.response.message === 'Only master can do this.') {
              MessageBox({
                type: 'error',
                message: '잘못된 접근입니다.',
                callback() {
                  router.push({ name: 'manageCounselee' }).catch(() => {});
                }
              });
            } else {
              throw 'err';
            }
          } else {
            throw error;
          }
        } else {
          throw error;
        }
      })
    }

    /** 셋그룹 삭제 api */
    const deleteSetGroup = async() => {
      const params = {
        id: Number.parseInt(route.params.id.toString())
      }

      await SetGroupAPI.deleteSetGroup(params)
      .then((response) => {
        const responseData = response.data;
        if (responseData 
        && typeof responseData === 'object'
        && Object.keys(responseData).indexOf('result') > -1
        && responseData.result ) {
          MessageBox({
            title: '셋그룹 삭제 완료',
            type: 'info',
            message: '셋그룹 삭제가 완료되었습니다. 리스트페이지로 이동합니다.',
            callback: router.back
          })
        }
      })
      .catch(error => {
        if (typeof error === 'object'
        && Object.keys(error).indexOf('response') > -1
        && typeof error.response === 'object'
        && Object.keys(error.response).indexOf('status') > -1
        && Object.keys(error.response).indexOf('data') > -1
        && Object.keys(error.response.data).indexOf('response') > -1
        && Object.keys(error.response.data.response).indexOf('message') > -1) {
          const status = error.response.status;
          if (status === 400) {
            if (error.response.data.response.message === 'Only master can do this.') {
              MessageBox({
                type: 'error',
                message: '잘못된 접근입니다.',
                callback() {
                  router.push({ name: 'manageCounselee' }).catch(() => {});
                }
              });
            } if (error.response.data.response.message === "You cannot delete this group.") {
              notify.error('삭제할 수 없는 셋그룹입니다.');
            } else {
              throw error;
            }

          }
        } else {
          throw error;
        }
      })
    }

    
    /** 초기화 */
    const refresh = () => {
      store.commit('loadingBar/showLoadingBar');
      if (mode.status) {
        Promise.all([
          getTestSet(),
          getOwnTestSetList()
        ])
        .then(() => {
          store.commit('loadingBar/hideLoadingBar');
        })
        .catch(() => {
          router.push({ name: 'error' }).catch(() => {});
        })
      } else {
        getTestSet()
        .then(() => {
          store.commit('loadingBar/hideLoadingBar');
        })
        .catch(() => {
          router.push({ name: 'error' }).catch(() => {});
        })
      }
    }



    onBeforeMount(() => {
      route.params.status === '1' ? mode.status = 1 : mode.status = 0;
      refresh();
    })

    return {
      setGroupInfo,
      handleClickAdd(){
        if (setGroupInfo.selectedTestSet === null) {
          notify.info('테스트 셋을 선택하세요')
        } else {
          const existIndex = setGroupInfo.setList.findIndex(item => (
            item.value === setGroupInfo.selectedTestSet
            ? true
            : false
          ));
          
          if (existIndex < 0) {
            const index = setGroupInfo.totalSetList.findIndex((item) => (
              item.value === setGroupInfo.selectedTestSet
              ? true
              : false
            ));
            setGroupInfo.setList.push(
              setGroupInfo.totalSetList[index]
            )
          } else {
            notify.warning('이미 존재하는 테스트셋입니다');
          }
          
        }
      },
      handleClickDelete(){
        if (setGroupInfo.groupUseFlag) {
          notify.warning('사용하는 셋그룹은 삭제할 수 없습니다.');
        } else {
          MessageBox.confirm(`${setGroupInfo.name}을 삭제하시겠습니까?`, '기관 삭제 안내', {
            confirmButtonText: '삭제',
            cancelButtonText: '취소',
            type: 'warning'
          })
          .then(() => {
            store.commit('loadingBar/showLoadingBar');
            deleteSetGroup()
            .then(() => {
              store.commit('loadingBar/hideLoadingBar');
            })
            .catch(() => {
              router.push({ name: 'error' }).catch(() => {});
            })
          })
          .catch(() => {
            refresh();
          })
        }
      },
      handleClickCancel(){
        MessageBox.confirm(`작업중인 내역은 사라집니다. 취소하시겠습니까?`, '취소 안내', {
          confirmButtonText: '작업 취소',
          cancelButtonText: '계속 작업',
          type: 'warning'
        })
        .then(() => {
          router.back();
        })
        .catch(() => {
        })
      },
      handleClickSave(){
        if (setGroupInfo.name) {
          store.commit('loadingBar/showLoadingBar');
          if (mode.status) {
            putEditSetGroup()
            .then(() => {
              store.commit('loadingBar/hideLoadingBar');
            })
            .catch(() => {
              router.push({ name: 'error' }).catch(() => {});
            })
          } else {
            postCreateSetGroup()
            .then(() => {
              store.commit('loadingBar/hideLoadingBar');
            })
            .catch(() => {
              router.push({ name: 'error' }).catch(() => {});
            })
          }
        } else {
          notify.warning('셋그룹 이름을 입력해주세요');
        }
      },
      handleClickRemove(index: number){
        setGroupInfo.setList.splice(index, 1);
      }
    }
  }
}
