<template>
  <div class="counselee-detail-wrapper">
    <div class="counselee-info-wrapper">
      <div class="counselee-info-button-wrapper">
        <label>{{'개인 정보'}}</label>
        <el-button
          type="info"
          @click="handleClickEdit"
        >
          <i class="el-icon-edit"/>
          {{'정보 수정'}}
        </el-button>
        <el-button
          type="info"
          disabled
          @click="handleOpenModal('counseleeDelete')"
        >
          <i class="el-icon-close"/>
          {{' 삭제'}}
        </el-button>
      </div>
      <table class="counselee-info-table">
        <thead>
          <tr>
            <th>생년월일</th>
            <th>성별</th>
            <th>연락처</th>
            <th>테스트 횟수</th>
            <th>기관</th>
            <th>학교 / 학년(나이)</th>
            <th>치료력</th>
            <th>지정 상담사</th>
            <th>보호자 정보</th>
            <th>QR 코드</th>
            <th>등록일시</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{ filters.getFormedDate(counseleeInfoData.counseleeInfo.birthDate) }}</td>
            <td>{{ counseleeInfoData.counseleeInfo.gender === 'F' ? '여' : '남' }}</td>
            <td>{{ filters.getFormedPhoneNumber(counseleeInfoData.counseleeInfo.tel) }}</td>
            <td>{{counseleeInfoData.counseleeInfo.testCount}}</td>
            <td>{{counseleeInfoData.counseleeInfo.agency}}</td>
            <td>{{ `${counseleeInfoData.counseleeInfo.schoolKindString} / ${counseleeInfoData.counseleeInfo.schoolYear}` }}</td>
            <td>{{counseleeInfoData.counseleeInfo.careHistoryString}}</td>
            <td>{{counseleeInfoData.counseleeInfo.counselor}}</td>
            <td> 
              <a
                @click="handleOpenModal('guardianInfo')"
              >
                {{'상세보기'}}
              </a>
            </td>
            <td>
              <a
                @click="downloadQrCode(
                  counseleeInfoData.counseleeInfo.qrCode, 
                  counseleeInfoData.counseleeInfo.name
                )"
              >
                {{'다운로드'}}
              </a>
            </td>
            <td>
              <span v-html="getTableTime(counseleeInfoData.counseleeInfo.createDate, 'tableTime')" />
            </td>
          </tr>
        </tbody>
        <tr 
          class="horizontal-row"
          v-if="counseleeInfoData.counseleeInfo.memo"
        >
          <th>메모</th>
          <td>{{counseleeInfoData.counseleeInfo.memo}}</td>
        </tr>
        <tr v-else class="horizontal-row" style="border-top: 0;"></tr>
      </table>
    </div>
    <List
      :selectedPage="listOption.page"
      :maxPage="listOption.maxPage"
      :totalCount="listOption.totalCount"
      :pagination="true"
      @changePage="changePage"
    >
      <template v-slot:list-table>
        <el-table
          :data="listOption.list"
          empty-text="테스트 데이터가 없습니다."
        >
          <el-table-column
            align ="center"
            width="50"
            label = "No"
            type="index"
            :index="getIndex"
            fixed
          />
          <el-table-column
            align ="center"
            prop="testResult"
            width="170"
          >
            <template #header>
              {{'테스트 결과'}}
              <span 
                class="icon pointer"
                @click="sort('testResult')"
              >
                <i 
                  v-if="(listOption.orderField === 'testResult' 
                    && listOption.order === 'desc') 
                    ? true: false" 
                  class='el-icon-caret-top'
                />
                <i v-else class="el-icon-caret-bottom"/>
              </span>
            </template>
            <template #default="scope">
              <el-button
                v-if="scope.row.testResult === 0"
                type="success"
                class="medium"
                plain
              >
                {{'정기 상담 권유'}}
              </el-button>
              <el-button
                v-else-if="scope.row.testResult === 1"
                type="danger"
                class="medium"
                plain
              >
                {{'조기 상담 권유'}}
              </el-button>
              <el-button
                v-else-if="scope.row.testResult === 2"
                type="warning"
                class="medium"
                plain
              >
                {{'응급 상담 권유'}}
              </el-button>
              <div v-else
              >{{ scope.row.testResult }}</div>
            </template>
          </el-table-column>
          <el-table-column
            align ="center"
            width="170"
          >
            <template #header>
              {{'조치 내역'}}
              <span 
                class="icon pointer"
                @click="sort('actionStatus')"
              >
                <i 
                  v-if="(listOption.orderField === 'actionStatus' 
                    && listOption.order === 'desc') 
                    ? true: false" 
                  class='el-icon-caret-top'
                />
                <i v-else class="el-icon-caret-bottom"/>
              </span>
            </template>
            <template #default="scope">
              <el-button
                v-if="scope.row.actionStatus === '조치 전'"
                type="info"
                :disabled="scope.row.progress !== 100"
                class="medium"
                @click="handleOpenActionModal(scope.row.id, scope.row.organizationId, false)"
              >
                {{ scope.row.actionStatus }}
              </el-button>
              <el-button
                v-else-if="scope.row.actionStatus === '조치 완료'"
                type="primary"
                :disabled="scope.row.progress !== 100"
                class="medium"
                @click="handleOpenActionModal(scope.row.id, scope.row.organizationId, true)"
              >
                {{ scope.row.actionStatus }}
              </el-button>
              <div v-else>{{'-'}}</div>
            </template>
          </el-table-column>
          <el-table-column
            align ="center"
          >
            <template #header>
              {{'진행도'}}
              <span class="icon pointer">
                <span
                  class="icon pointer"
                  @click="sort('progress')"
                >
                  <i 
                    v-if="(listOption.orderField === 'progress' 
                      && listOption.order === 'desc') 
                      ? true: false" 
                    class='el-icon-caret-top'
                  />
                  <i v-else class="el-icon-caret-bottom"/>
                </span>
              </span>
            </template>
            <template #default="scope">
              <p>
                {{`${scope.row.progress}%`}}
              </p>
            </template>
          </el-table-column>
          <el-table-column
            align ="center"
          >
            <template #header>
              {{'상담사'}}
              <span 
                class="icon pointer"
                @click="sort('counselor')"
              >
                <i 
                  v-if="(listOption.orderField === 'counselor' 
                    && listOption.order === 'desc') 
                    ? true: false" 
                  class='el-icon-caret-top'
                />
                <i v-else class="el-icon-caret-bottom"/>
              </span>
            </template>
            <template #default="scope">
              <el-button
                type="info"
                class="medium"
                @click="handleClickCounselor(scope.row)"
              >
                {{scope.row.counselor}}
                <i class="el-icon-user"/>
              </el-button>
            </template>
          </el-table-column>
          <el-table-column
            align ="center"
          >
            <template #header>
              {{'테스트 기록'}}
            </template>
            <template #default="scope">
              <a
                v-if="scope.row.progress !== 0"
                @click="handleClickTestRecord(scope.row.id)"
                :disabled="scope.row.progress !== 100"
              >
                {{ '기록보기' }}
              </a>
              <div v-else>{{'-'}}</div>
            </template>
          </el-table-column>
          <el-table-column
            align ="center"
            prop="testDate"
          >
            <template #header>
              {{'테스트 일시'}}
              <span 
                class="icon pointer"
                @click="sort('testDate')"
              >
                <i 
                  v-if="( listOption.orderField === 'testDate' 
                    && listOption.order === 'desc') 
                    ? true: false" 
                  class='el-icon-caret-top'
                />
                <i v-else class="el-icon-caret-bottom"/>
              </span>
            </template>
            <template #default="scope">
              <span v-html="getTableTime(scope.row.testDate, 'tableTime')" />
            </template>
          </el-table-column>
          <el-table-column
            align ="center"
            label="보고서 작성"
            width="150"
          >
            <template #header>
              {{'보고서 작성'}}
              <span 
                class="icon pointer"
                @click="sort('reportStatus')"
              >
                <i 
                  v-if="(listOption.orderField === 'reportStatus' 
                    && listOption.order === 'desc') 
                    ? true: false" 
                  class='el-icon-caret-top'
                />
                <i v-else class="el-icon-caret-bottom"/>
              </span>
            </template>
            <template #default="scope">
              <div
                v-if="scope.row.progress !== 100 || scope.row.reportStatus === '-'"
              >{{'-'}}</div>
              <button
                v-else
                class="write-button"
                :disabled="userLevel === '2' && (scope.row.organizationId.toString() !== userOrganizationId.toString())"
                @click="handleClickReport(scope.row)"
              >
                {{ scope.row.reportStatus }}
              </button>
            </template>
          </el-table-column>
          <el-table-column
            align ="center"
            prop="reportDate"
            label="보고서 작성 일시"
          >
            <template #header>
              {{'보고서 작성 일시'}}
              <span 
                class="icon pointer"
                @click="sort('reportDate')"
              >
                <i 
                  v-if="(listOption.orderField === 'reportDate' 
                    && listOption.order === 'desc') 
                    ? true: false" 
                  class='el-icon-caret-top'
                />
                <i v-else class="el-icon-caret-bottom"/>
              </span>
            </template>
            <template #default="scope">
              <span v-html="getTableTime(scope.row.reportDate)"/>
            </template>
          </el-table-column>
        </el-table>
      </template>
    </List>
    <!-- 내담자 삭제 -->
    <Modal v-if="modalOption.modalType === 'counseleeDelete'">
      <template v-slot:modal-title><b class="modal-title">내담자 삭제</b></template>
      <template v-slot:modal-body>
        <p>
          {{`[${counseleeInfoData.name}] 내담자를 삭제하면`}}<br>
          {{`모든 테스트 기록이 삭제됩니다.`}}
        </p>
        <br>
        <p>{{`삭제하시겠습니까?`}}</p>
      </template>
      <template v-slot:modal-footer>
        <el-button
          type="primary"
          @click="closeModal">
          삭제
        </el-button>
      </template>
    </Modal>
    <!-- 보호자 정보 -->
    <Modal v-else-if="modalOption.modalType === 'guardianInfo'">
      <template v-slot:modal-title><b class="modal-title">보호자 정보</b></template>
      <template v-slot:modal-body>
        <div 
          class="guardian-info-box"
          v-for="(item, index) in counseleeInfoData.guardianList"
          :key="index"
        >
          <div class="info-wrapper">
            <div class="label-wrapper">
              <label>{{'성명'}}</label>
            </div>
            <div class="item-wrapper">
              {{`${item.name} (${item.relationString}, ${item.age}세, ${item.gender === 'F' ? '여' : '남'})`}}
            </div>
          </div>
          <div class="info-wrapper">
            <div class="label-wrapper">
              <label>{{'연락처'}}</label>
            </div>
            <div class="item-wrapper">
              {{ filters.getFormedPhoneNumber(item.phone) }}
            </div>
          </div>
          <div class="info-wrapper">
            <div class="label-wrapper">
              <label>{{'메모'}}</label>
            </div>
            <div class="item-wrapper">
              {{ item.memo ? item.memo : '-'}}
            </div>
          </div>
          <div class="info-wrapper psc">
            <div class="label-wrapper">
              <label>{{'PSC 점수'}}</label>
            </div>
            <div 
              class="item-wrapper"
              v-if="item.pscList.length > 0"
            >
              <p 
                v-for="(item, index) in item.pscList"
                :key="index"
              >
                {{`${item.score}  (${item.date})`}}
              </p>
            </div>
            <div v-else>{{'-'}}</div>
          </div>
        </div>
      </template>
      <template v-slot:modal-footer>
        <el-button
          type="primary"
          @click="goEditCounseleeInfo"
        >
          보호자 수정
        </el-button>
      </template>
    </Modal>
    <!-- 조치 내역 -->
    <Modal v-else-if="modalOption.modalType === 'actionList'">
      <template v-slot:modal-title>
        <b class="modal-title">
          조치 내역
        </b>
      </template>
      <template v-slot:modal-body>
        <div class="action-info-box">
          <div class="action-wrapper">
            <div class="label-wrapper">
              <label>{{'조치 일시'}}</label>
            </div>
            <el-date-picker
              v-model="actionInfoData.actionInfo.careDate"
              format="YYYY-MM-DD HH:mm"
              type="datetime"
              placeholder="조치일시를 선택해주세요"
              :disabled-date="filters.disabledDate"
            />
          </div>
          <div class="action-wrapper">
            <div class="label-wrapper">
              <label>{{'상담사 이름'}}</label>
            </div>
            <el-select 
              v-model="actionInfoData.actionInfo.counselorId" 
              placeholder="상담사를 선택하세요"
            >
              <el-option
                v-for="(item, index) in actionInfoData.selectCounselorList"
                :key="index"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </div>
          <div class="action-wrapper">
            <div class="label-wrapper">
              <label>{{'보호자 이름'}}</label>
            </div>
            <el-select 
              v-model="actionInfoData.actionInfo.parentId" 
              placeholder="보호자를 선택하세요"
            >
              <el-option
                v-for="(item, index) in actionInfoData.selectGuardianList"
                :key="index"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </div>
          <div class="action-wrapper">
            <div class="label-wrapper">
              <label>{{'조치 내역'}}</label>
            </div>
            <el-input 
              type="textarea" 
              v-model="actionInfoData.actionInfo.content"
              placeholder="조치 내역을 입력해주세요"
            />
          </div>
        </div>
      </template>
      <template v-slot:modal-footer>
        <el-button
          type="primary"
          @click="handleClickCreateAction">
          조치 등록
        </el-button>
      </template>
    </Modal>
    <!-- 상담사 변경 -->
    <Modal v-if="modalOption.modalType === 'editCounselor'">
      <template v-slot:modal-title><b class="modal-title">상담사 변경</b></template>
      <template v-slot:modal-body>
        <div class="edit-counselor">
          <el-select 
            v-model="selectItem.counselor"
            placeholder="상담사를 선택하세요"
          >
            <el-option
              v-for="(item, index) in selectList.counselorList"
              :key="index"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </div>
      </template>
      <template v-slot:modal-footer>
        <el-button
          type="primary"
          @click="handleClickEditCounselor">
          {{ '변경' }}
        </el-button>
      </template>
    </Modal>
  </div>
</template>

<script lang="ts">
import { onBeforeMount, reactive } from "vue";
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import MessageBox from 'element-plus/lib/el-message-box';
import notify from '@/utils/notify';
import List from '@/components/List/List.vue';
import Modal from '@/components/Modal/Modal.vue'
import CounseleeAPI from '@/api/counselee';
import filters from '@/utils/filter';
import { getCookie } from '@/utils/cookie';

export default {
  name: 'counseleeDetail',
  components: {
    List,
    Modal
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const userLevel = getCookie('userLevel');
    const userCounselorId = getCookie('counselorId');
    const userOrganizationId = getCookie('organizationId');

    const selectList: {
      counselorList: any[]
    } = reactive({
      counselorList: []
    });

    const selectItem: {
      counselor: any,
      test: any
    } = reactive({
      counselor: null,
      test: null
    });

    /** 내담자 정보 */
    const counseleeInfoData: {
      counseleeInfo: {
        birthDate: string,
        careHistoryString: string,
        counselor: string,
        createDate: string,
        gender: string,
        name: string,
        tel: string,
        schoolKindString: string,
        schoolYear: number,
        testCount: number,
        agency: string,
        memo: string,
        qrCode: string,
        organizationId: any,
        id: any
      },
      guardianList: any[]
    } = reactive({
      counseleeInfo : {
        birthDate: '',
        careHistoryString: '',
        counselor: '',
        createDate: '',
        gender: '',
        name: '',
        tel: '',
        schoolKindString: '',
        schoolYear: 0,
        testCount: 0,
        agency: '',
        memo: '',
        qrCode: '',
        organizationId: null,
        id: null,
      },
      guardianList: []
    });
    /** 조치내역 정보 */
    const actionInfoData: any = reactive({
      actionInfo: {
        id: 0,
        counselorId: null,
        counselor: '',
        parentId: null,
        content: '',
        parent: '',
        testId: 0,
        careDate: '',
      },
      selectGuardianList: [],
      selectCounselorList: []
    });
    
    const listOption: {
      maxPage: number,
      totalCount: number,
      page: number,
      list: {}[],
      order: string,
      orderField: string
    } = reactive({
      maxPage: 10,
      totalCount: 0,
      page: 1,
      list: [],
      order: '',
      orderField: ''
    })
    const modalOption = reactive({ modalType: '' });

    /** 정보수정 클릭 */
    const handleClickEdit =() => {
      router.push({
        name: 'editCounseleeInfo', 
        params: { 
          id: counseleeInfoData.counseleeInfo.id, 
          name: counseleeInfoData.counseleeInfo.name
        },
        query: route.query
      })
    }

    /** 보고서 작성/수정 클릭 */
    const handleClickReport = (row: any) => {
      router.push({
        name: 'reportDetail', 
        params: {
          counseleeId: `${ counseleeInfoData.counseleeInfo.id }`, 
          name: `${ counseleeInfoData.counseleeInfo.name }`,
          testId: `${ row.id }`,
          status: `${ row.reportStatus === '미작성' ? 0 : 1 }`
        },
        query: route.query
      })
    }

    /** 테스트 기록 클릭 */
    const handleClickTestRecord = (id:any) => {
      router.push({
        name: 'testDetail', 
        params: {
          counseleeId: route.params.id,
          id: id, 
          name: counseleeInfoData.counseleeInfo.name
        },
        query: route.query
      }).catch(() => {})
    }

    /** 리스트 No */
    const getIndex = (index: number) => {
      const {
        totalCount,
        maxPage,
        page
      } = listOption;
      return totalCount - (maxPage * (page - 1)) - index;
    }


    /** 날짜 포맷 fnc */
    const getTableTime = (date: string) => {
      const dateObj:any = new Date(date);
      let result: string = '';
      (!date || dateObj.toString() === 'Invalid Date') 
      ? result = '-'
      : result = filters.dateToString(dateObj, 'tableTime');
      return result;
    }

    /** 페이지 변경 */
    const changePage = (page: number) => {
      listOption.page = page;
      store.commit('loadingBar/showLoadingBar');
      getTestHistoryList()
      .then(() => {
        store.commit('loadingBar/hideLoadingBar');
      })
      .catch(() => {
        router.push({name: 'error'}).catch(() => {});
      })
    }

    /** 팝업창 열기 */
    const handleOpenModal = (type: string) => {
      modalOption.modalType = type;
      store.commit('modal/openModal', 'type-popup')
    }
    /** 팝업창 닫기 */
    const closeModal = () => {
      store.commit('modal/closeModal')
    }

    /** 팝업창 클릭 */
    const handleOpenActionModal = (testId: number, orgId: number, isAction: boolean) => {

      modalOption.modalType ='actionList';
      actionInfoData.actionInfo.testId = testId;


      if (isAction) {
        store.commit('loadingBar/showLoadingBar');
        Promise.all([
          getTestAction(testId),
          getOrgCounselorList(orgId)
        ])
        .then(() => {
          store.commit('modal/openModal', 'type-popup');
          store.commit('loadingBar/hideLoadingBar');
        })
        .catch(() => {
          window.location.href = '/error';
        })
      } else {
        getOrgCounselorList(orgId)
        .then(() => {
          store.commit('modal/openModal', 'type-popup');
          store.commit('loadingBar/hideLoadingBar');
        })
        .catch(() => {
          window.location.href = '/error';
        })

      }
    }

    /** 조치내역 초기화 */
    const resetActionInfoData = () => {
      actionInfoData.actionInfo = {
        id: 0,
        counselorId: 0,
        counselor: '',
        parentId: 0,
        content: '',
        parent: '',
        testId: 0,
        careDate: '',
      };
      actionInfoData.selectGuardianList = [];
      actionInfoData.selectCounselorList = [];
    }

    /** 조치내역 등록 버튼 클릭 */
    const handleClickCreateAction = () => {
      store.commit('loadingBar/showLoadingBar');
      createAction()
      .then((res) => {
        if (res) {
          resetActionInfoData();
        }
      })
      .catch(() => {
        window.location.href = '/error';
      })

    }

    /** 내담자 정보 수정 페이지 이동 */
    const goEditCounseleeInfo = () =>{
      store.commit('modal/closeModal');
      router.push({
        name: 'editCounseleeInfo', 
        params: { 
          id: counseleeInfoData.counseleeInfo.id, 
          name: counseleeInfoData.counseleeInfo.name
        },
        query: route.query
      }).catch(() => {});
    }

    /** QR 코드 다운로드 */
    const downloadQrCode = (qrCodeURL: string, name: string) => {
      if (qrCodeURL && name) {
        let readerObj:any;
        const canvas = window.document.createElement('canvas');
        const context: any = canvas.getContext('2d');
        const img = window.document.createElement('img');
        canvas.width = 512;
        canvas.height = 512;
        img.crossOrigin = null;
        img.src = `${process.env.VUE_APP_BASE_URL}${qrCodeURL}`;
  
        
        setTimeout(() => {
          context.drawImage(img, 0, 0);
    
          readerObj = canvas.toDataURL('img/png').toString();
          let imgData;
    
          if (readerObj.split(',')[0].indexOf('base64') >= 0)
            imgData = atob(readerObj.split(',')[1]);
          else
            imgData = unescape(readerObj.split(',')[1]);
    
    
          const len = imgData.length;
          const view = new Uint8Array(len);
    
          for (let i = 0; i <len; i++) {
            view[i] = imgData.charCodeAt(i);
          }
    
          const blob = new Blob([view], {type: 'image/png'});
    
          const link: any = window.document.createElement('a');
          const url = window.URL.createObjectURL(blob);
          link.href = url;
          link.download = `${name}.png`;
          link.click();
          document.body.appendChild(link);
          setTimeout(() => {
            document.body.removeChild(link);
            URL.revokeObjectURL(url)
          }, 100)
        }, 500)
      }
    }

    /** 내담자 등록 params */
    const getCreateActionParams = () => {
      const actionInfo = actionInfoData.actionInfo
      const params: any = {
        id: actionInfo.testId,
      }

      if (actionInfo.counselorId !== null ) {
        params.counselorId = actionInfo.counselorId;
      } else {
        notify.warning('상담사를 입력해주세요');
        return;
      }

      if (actionInfo.parentId !== null ) {
        params.parentId = actionInfo.parentId;
      } else {
        notify.warning('보호자를 입력해주세요');
        return;
      }

      if (actionInfo.content !== '' ) {
        params.content = actionInfo.content;
      } else {
        notify.warning('조치내역을 입력해주세요');
        return;
      }

      if (actionInfo.careDate && filters.dateToString(actionInfo.careDate, 'yyyy-mm-dd hh:mm')) {
        params.careDate = filters.dateToString(actionInfo.careDate, 'yyyy-mm-dd hh:mm');
      } else {
        notify.warning('조치일시를 입력해주세요');
        return;
      }
      return params;
    }

    /** 조치내역 등록/ 수정 */
    const createAction = async() => {
      const params = getCreateActionParams();
      if (params) {
        await CounseleeAPI.postCreateAction(params)
        .then((response) => {
          const responseData = response.data
          if (responseData 
          && typeof responseData === 'object'
          && Object.keys(responseData).indexOf('result') > -1
          && responseData.result ) {
            store.commit('modal/closeModal');
            MessageBox({
              title: '조치내역 메시지',
              message: '조치내역 등록이 완료되었습니다.',
              callback: refresh
            });
            return 1;
          } else {
            return 0;
          }
        })
        .catch((error) => {
          throw error;
        })
      } else {
        store.commit('loadingBar/hideLoadingBar');
        return 0;
      }
      
    }

    /** 기관별 상담사 리스트 get api */
    const getOrgCounselorList = async (id:number) => {
      await CounseleeAPI.getOrgCounselorList({
        id: id,
      })
      .then((response) => {
        const responseData = response.data
        if (responseData 
        && typeof responseData === 'object'
        && Object.keys(responseData).indexOf('result') > -1
        && Object.keys(responseData).indexOf('response') > -1
        && responseData.result ) {
          const {
            list
          } = responseData.response;
          const tmpList:any = [];
          for (const item of list) {
            tmpList.push({
              label: item.name,
              value: item.id
            })
          }
          actionInfoData.selectCounselorList = tmpList;
          selectList.counselorList = tmpList;
        } else {
          throw 'error';
        }
      })
      .catch((error) => {
        throw error;
      })
    } 

    /** 조치내역 get api */
    const getTestAction = async (testId: number) => {
      const params = {
        id: testId
      }
      await CounseleeAPI.getTestAction(params)
      .then((response) => {
        const responseData = response.data;
        if (responseData 
        && typeof responseData === 'object'
        && Object.keys(responseData).indexOf('result') > -1
        && Object.keys(responseData).indexOf('response') > -1
        && responseData.result ) {
          const {
            id,
            counselorId,
            counselor,
            parentId,
            parent,
            content,
            careDate
          } = responseData.response;

          actionInfoData.actionInfo = {
            id,
            counselorId,
            counselor,
            parentId,
            parent,
            content,
            careDate,
            testId
          }
        }
      })
      .catch((error) => {
        throw error;
      })
    }

    /** 내담자 상세정보 get api */
    const getCounseleeDetail = async() => {
        const params: object = {
          id: route.params.id
        }

        await CounseleeAPI.getCounseleeDetail(params)
        .then((response) => {
          const responseData = response.data
          if (responseData 
          && typeof responseData === 'object'
          && Object.keys(responseData).indexOf('result') > -1
          && Object.keys(responseData).indexOf('response') > -1
          && responseData.result ) {
            const {
              birthDate,
              careHistory,
              careHistoryString,
              counselor,
              counselorId,
              createAt,
              gender,
              id,
              memo,
              name,
              organization,
              organizationId,
              parentsList,
              phone,
              qrCode,
              schoolKind,
              schoolKindString,
              schoolYear,
              testCnt
            } = responseData.response;

            /** 잘못된 이름url로 들어올 경우 */
            if (route.params.name !== name) {
              router.replace({
                name: 'counseleeDetail',
                params: {
                  id: route.params.id,
                  name: name
                }
              })
              .catch(() => {})
            }

            counseleeInfoData.counseleeInfo = {
              birthDate,
              careHistoryString,
              counselor,
              createDate: createAt,
              gender,
              name,
              tel: phone,
              schoolKindString,
              schoolYear,
              testCount: testCnt,
              agency: organization,
              memo,
              qrCode,
              id,
              organizationId
            };

            counseleeInfoData.guardianList = parentsList;
            

            // 조치내역 팝업용 보호자 select item list
            const tmpSelectGuardianList = [];

            for (const parent of counseleeInfoData.guardianList) {
              tmpSelectGuardianList.push({
                label: parent.name,
                value: parent.id
              })
            }

            actionInfoData.selectGuardianList = tmpSelectGuardianList;            
          } else {
            throw 'error';
          }
        })
        .catch((err) => {
          throw err;
        })
    }

    const getParams = () => {
      const params:any = {
        id: route.params.id,
        maxPage: listOption.maxPage,
        page: listOption.page,
      }

      if (listOption.order) {
        params.order = listOption.order;
      }

      if (listOption.orderField) {
        params.orderField = listOption.orderField;
      }

      return params
    }

    /** 테스트 내역 리스트 get api */
    const getTestHistoryList = async() => {
      const params = getParams();
      await CounseleeAPI.getTestHistoryList(params)
      .then((response) => {
        const responseData = response.data;
        if (responseData 
        && typeof responseData === 'object'
        && Object.keys(responseData).indexOf('result') > -1
        && Object.keys(responseData).indexOf('response') > -1
        && responseData.result ) {
          const {
            list,
            // next,
            // previous,
            totalCount,
            // firstPage,
            // endPage
          } = responseData.response;

          listOption.list = list;
          listOption.totalCount = totalCount;
        } else {
          throw 'error';
        }
      })
      .catch((err) => {
        throw err;
      })
    }

    /** 테스트 상담사 변경 */
    const putEditTestCounselor = async() => {
      const params = {
        id: selectItem.test,
        counselorId: selectItem.counselor
      }
      await CounseleeAPI.putEditTestCounselor(params)
      .then((response) => {
        const responseData = response.data;
        if (responseData 
        && typeof responseData === 'object'
        && Object.keys(responseData).indexOf('result') > -1
        && responseData.result ) {
          notify.info('테스트 상담사가 변경되었습니다.');
        } else {
          throw 'err';
        }
      })
      .catch((err) => {
        throw err;
      })
    }

    /** 화면 초기화 */
    const refresh = () => {
      store.commit('loadingBar/showLoadingBar');
      Promise.all([
        getCounseleeDetail(),
        getTestHistoryList()
      ])
      .then(() => {
        store.commit('loadingBar/hideLoadingBar');
      })
      .catch(() => {
        router.push({name: 'error'}).catch(() => {})
      })
    }

    /** 리스트 정렬 */
    const sort = (orderField: string) => {
      if (listOption.orderField === orderField) {
        (listOption.order === 'desc') ? listOption.order = 'asc' : listOption.order = 'desc';
      } else {
        listOption.orderField = orderField;
        listOption.order = 'desc';
      }
      store.commit('loadingBar/showLoadingBar');
      getTestHistoryList()
      .then(() => {
        store.commit('loadingBar/hideLoadingBar');
      })
      .catch(() => {
        router.push({name: 'error'}).catch(() => {});
      })
    }

    onBeforeMount(() => {
      refresh()
    })
    
    return {
      selectList,
      selectItem,
      counseleeInfoData,
      actionInfoData,
      changePage,
      listOption,
      modalOption,
      closeModal,
      handleOpenModal,
      getTableTime,
      downloadQrCode,
      goEditCounseleeInfo,
      handleOpenActionModal,
      handleClickCreateAction,
      sort,
      filters,
      getIndex,
      handleClickTestRecord,
      userCounselorId,
      userLevel,
      userOrganizationId,
      handleClickEdit,
      handleClickReport,
      handleClickCounselor(test:any){
        selectItem.test = test.id;
        store.commit('loadingBar/showLoadingBar');
        getOrgCounselorList(counseleeInfoData.counseleeInfo.organizationId)
        .then(() => {
          store.commit('loadingBar/hideLoadingBar');
          handleOpenModal('editCounselor');
        })
        .catch(() => {
          router.push({ name: 'error' }).catch(() => {});
        })
      },
      handleClickEditCounselor(){
        store.commit('loadingBar/showLoadingBar');
        putEditTestCounselor()
        .then(() => {
          store.commit('modal/closeModal');
          store.commit('loadingBar/hideLoadingBar');
          refresh();
        })
        .catch(() => {
          router.push({ name: 'error' }).catch(() => {});
        })
      }
    }
  }
}
</script>