<template>
  <div class="org-test-statistics-wrapper">
    <div class="search-box-wrapper">
      <label>{{'검색 옵션'}}</label>
      <div class="search-condition-wrapper">
        <div class="search-condition-item">
          <el-date-picker
            type="daterange"
            start-placeholder="시작일 선택"
            end-placeholder="종료일 선택"
            v-model="listOption.date"
            :disabled-date="filters.disabledDate"
          />
        </div>
        <div class="search-condition-item">
          <el-select 
            v-model="listOption.organization" 
            multiple collapse-tags 
            placeholder="기관정보를 선택하세요"
          >
            <el-option
              v-for="(item, index) in selectList.agencyList"
              :key="index"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </div>
        <el-button
          type="info"
          class="search"
          @click="handleClickSearch"
        >{{'검색'}}</el-button>
      </div>
    </div>
    <div v-if="chartData.data.labels.length > 0">
      <Chart
        type="line"
        :chartStyle="`max-width: ${chartSize.width}px; max-height: ${chartSize.height }px`"
        :data="chartData.data"
        :options="chartData.options"
      />
    </div>
    <div class="table-wrapper">
      <div class="table-upper-wrapper">
        <h2>{{`검색 결과 | ${orgInfo.isSelectAll ? '전체' : orgInfo.selectedOrgNames }`}}</h2>
        <el-button
          @click="downloadExcel"
          type="primary"
        >
        <i class="el-icon-download"/>
        {{'엑셀 다운로드'}}
      </el-button>
      </div>
      <div class="table-body-wrapper">
        <table 
          class="statistics-table" 
          v-if="listOption.list.length > 0"
        >
          <thead>
            <tr>
              <th class="fixed start">{{'기관'}}</th>
              <th 
                v-for="(item, index) in listOption.list" 
                :key="index"
              >{{ item.name }}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th class="fixed start">{{'합계'}}</th>
              <td
                v-for="(item, index) in listOption.list"
                :key="index"
              >{{ item.totalCount }}</td>
            </tr>
            <tr v-for="(item, index) in listOption.list[0].detailList" :key="index">
              <td class="fixed start">{{ item.date }}</td>
              <td
                v-for="(innerItem, innerIndex) in listOption.list" 
                :key="innerIndex"
              >{{ innerItem.detailList[index].count }}</td>
            </tr>
          </tbody>
        </table>
        <div class="no-data" v-else>데이터 없음</div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { onBeforeMount, reactive, watch } from 'vue';
import Chart from '@/components/Charts/Chart.vue';
import filters from '@/utils/filter';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import CounseleeAPI from '@/api/counselee';
import StatisticsAPI from '@/api/statistics';
import color from '@/utils/color';
import { koreanRegex } from '@/utils/regex';
export default {
  name: 'orgTestStatistics',
  components: {
    Chart
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const route = useRoute();

    const orgInfo: {
      isSelectAll: boolean,
      selectedOrgNames: string
    } = reactive({
      isSelectAll: true,
      selectedOrgNames: ''
    })

    const chartSize: {
      width: number,
      height: number
    } = reactive({
      width: 0,
      height: 0
    })

    const selectList: {
      agencyList: any[],
    } = reactive({
      agencyList: [],
    })

    const listOption: {
      maxPage: number,
      totalCount: number,
      page: number,
      list: any[],
      order: string,
      orderField: string,
      entireCount: number,
      search: string,
      date: any[],
      organization: any[],
      chart: any[]
    } = reactive({
      maxPage: 10,
      totalCount: 0,
      page: 1,
      list: [],
      order: '',
      orderField: '',
      entireCount: 0,
      search: '',
      date: [],
      organization: [],
      chart: []
    });

    const chartData: {
      data: {
        labels: string[],
        datasets: any[],
      }
      options: any
    } = reactive({
      data: {
        labels: [],
        datasets: [],
      },
      options: {
        // min: 0,
        // max: 100,
        // responsive: true,
        // plugins: {
        //   legend: {
        //     position: "top",
        //   },
        // },
        // scales: {
        //   y: {
        //     min: 0,
        //     max: 150,
        //     ticks: {
        //       callback: (value:number) => `${value}%`,
        //     },
        //   },
        // },
      }
    })

    /** 검색쿼리 url 저장 */
    const setUrlQueryString = () => {
      const {
        date,
        organization
      } = listOption;

      const query:any = {};

      if (Array.isArray(date)) {
        const tmpList = [];
        for (const item of date) {
          item && tmpList.push(filters.dateToString(item, 'yyyy-mm-dd'));
        }
        query.date = tmpList;
      }


      if (organization.length > 0)
        query.organization = organization;

      router.replace({
        name: 'orgTestStatistics',
        query
      }).catch(() => {});
    }

        /** 전체 체크 fnc */
    const checkAll = () => {
      const tmpList: any [] = []
      for (const item of selectList.agencyList) {
        tmpList.push(item.value)
      }
      listOption.organization = tmpList;
    }

    /** 기관별 상담사 통계 params */
    const getParams = () => {
      const {
        organization,
        date
      } = listOption;

      const params: any = {
      };

      if (date && date.length === 2) {
        params.gte = filters.dateToString(date[0], 'yyyy-mm-dd');
        params.lte = filters.dateToString(date[1], 'yyyy-mm-dd');
      }

      if (organization.length > 0) {
        let tmpList: any[] = [];
        for (const item of organization) {
          if ( item === 'all') {
            tmpList = [];
            break;
          } else {
            tmpList.push(item)
          }
        }
        if (tmpList.length > 0)
          params.organization = JSON.stringify(tmpList);

        if (tmpList.length > 0) {
          params.organization = JSON.stringify(tmpList);
          orgInfo.isSelectAll = false;
        } else {
          orgInfo.isSelectAll = true;
        }
      } else {
        orgInfo.isSelectAll = true;
      }

      return params;
    }

    /** 기관별 테스트 통계 get api */
    const getOrgTestStatistics = async() => {
      const params = getParams();
      if (params) {
        await StatisticsAPI.getOrgTestStatistics(params)
        .then((response) => {
          const responseData = response.data;
          if (responseData 
          && typeof responseData === 'object'
          && Object.keys(responseData).indexOf('result') > -1
          && Object.keys(responseData).indexOf('response') > -1
          && responseData.result ) {
            const {
              list
            } = responseData.response;

            listOption.list = list;
            

            if (Array.isArray(list)) {
              const tmpLabels: string[] = [];
              const tmpDatasets: any[] = [];
              let tmpHighestCount = 0;
              let tmpOrgNames: string = '';

              list.sort((a:any, b:any) => {
                const aString = a.name[0].toUpperCase();
                const bString = b.name[0].toUpperCase();

                if (a.name !== '전체' && b.name !== '전체') {
                  return aString.localeCompare(bString);
                }
                return 0;
              });

              for (let i = 0; i < list.length; i++) {
                const tmpColor: string = color.getRandomColor();
                const tmpDataList: number[] = [];
                for (const item of list[i].detailList) {
                  
                  if (i == 0) 
                    tmpLabels.push(item.date);
                  
                  tmpDataList.push(item.count);

                  if ( item.count > tmpHighestCount)
                    tmpHighestCount = item.count;
                }

                if (list[i].name !== '전체') {
                  if (i !== list.length - 1) {
                    tmpOrgNames += `${list[i].name}, `;
                  } else {
                    tmpOrgNames += list[i].name;
                  }
                }

                tmpLabels.reverse();
                tmpDataList.reverse();


                tmpDatasets.push({
                  label: list[i].name,
                  data: tmpDataList,
                  borderColor: tmpColor,
                  backgroundColor: tmpColor
                })

              }

              orgInfo.selectedOrgNames = tmpOrgNames;

              chartData.data.labels = tmpLabels;
              chartData.data.datasets = tmpDatasets;
              if (tmpHighestCount < 10) {
                chartData.options = {
                  scales: {
                    y: {
                      max: 10,
                      min: 0
                    }
                  }
                }
              }
            }
          } else {
            throw 'err';
          }
        })
        .catch(err => {
          throw err;
        })
      }
    }

    /** 기관정보 get api */
    const getOrganizationList = async() => {
      await CounseleeAPI.getOrganizationList()
      .then((response) => {
        const responseData = response.data
        if (responseData 
        && typeof responseData === 'object'
        && Object.keys(responseData).indexOf('result') > -1
        && Object.keys(responseData).indexOf('response') > -1
        && responseData.result ) {
          const {
            list
          } = responseData.response;
          const tmpList = [
            {
              label: '전체', 
              value: 'all'
            }
          ]
          for (const item of list) {
            tmpList.push({
              label: item.name,
              value: item.id
            })
          }
          selectList.agencyList = tmpList
        } else {
          throw 'err';
        }
      })
      .catch((err) => {
        throw err;
      })
    }

    /** 기관별 테스트 통계 Excel 다운로드 api */
    const getOrgTestStatisticsExcel = async() => {
      const params = getParams();
      if (params) {
        params.excel = true;
        
        await StatisticsAPI.getOrgTestStatisticsExcel(params)
        .then((response) => {
          if (response.data) {
            const blob = new Blob([response.data], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"})
            const a = window.document.createElement("a")

            a.href = window.URL.createObjectURL(blob)
            a.download = `기관별 상담사 통계_${filters.dateToString(new Date(), 'yyyy-mm-dd hh:mm:ss').replaceAll('-', '').replaceAll(':', '').replaceAll(' ', '')}.xlsx`;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
          } else {
            throw 'err';
          }
        })
        .catch((err) => {
          throw err;
        })
      }
    }

    /** 초기화 */
    const refresh = () => {
      store.commit('loadingBar/showLoadingBar');
      Promise.all([
        getOrgTestStatistics(),
        getOrganizationList()
      ])
      .then(() => {
        store.commit('loadingBar/hideLoadingBar')
      })
      .catch(() => {
        router.push({ name: 'error' }).catch(() => {});
      })
    }

    onBeforeMount(() => {
      /** chart size */
      chartSize.width = window.innerWidth;
      chartSize.height = 500;

      addEventListener('resize', () => {
        chartSize.width = window.innerWidth;
      });

      if (Object.keys(route.query).length > 0) {
        const {
          organization,
          date
        } = route.query;

        if (Array.isArray(organization)) {
          const tmpList = [];
          for (const item of organization) {
            if (item) {
              const tmpNum = Number.parseInt(item);
              (isNaN(tmpNum)) 
              ? tmpList.push(item)
              : tmpList.push(tmpNum);
            }
          }

          listOption.organization = tmpList;
        } else if (typeof organization === 'string' && organization !== '') {
          const tmpNum = Number.parseInt(organization);
          isNaN(tmpNum)
          ? listOption.organization = [organization]
          : listOption.organization = [tmpNum];
        }

        if (Array.isArray(date) && date.length > 0) {
          const tmpList = [];
          for (const item of date) {
            item && tmpList.push(new Date(item));
          }
          listOption.date = tmpList;
        } else {
          /** set one month */
          listOption.date = [
            new Date(new Date().getTime() - 2592000000),
            new Date(new Date().getTime() - 86400000)
          ]
        }
      } else {
        /** set one month */
          listOption.date = [
            new Date(new Date().getTime() - 2592000000),
            new Date(new Date().getTime() - 86400000)
          ]
      }
      refresh();
    })

    watch(() => listOption.organization, (agency: any, prevAgency: any) => {
      if (selectList.agencyList.length > 0) {
        const checkAllIndex = agency.indexOf('all');
        const prevCheckAllIndex = prevAgency.indexOf('all'); 
        if ( checkAllIndex > -1 
        && prevCheckAllIndex < 0) {
          checkAll();
        } else if (checkAllIndex < 0 
        && prevCheckAllIndex > -1
        && agency.length === (selectList.agencyList.length -1)) {
          listOption.organization = [];
        } else if (checkAllIndex < 0
        && prevCheckAllIndex < 0
        && agency.length === (selectList.agencyList.length -1)) {
          checkAll();
        } else if (checkAllIndex > -1
        && prevCheckAllIndex > -1
        && agency.length === (selectList.agencyList.length -1)) {
          const tmpList: any [] = []
          for (const item of agency) {
            if (item !== 'all') {
              tmpList.push(item)
            }
          }
          listOption.organization = tmpList;
        }
      }
    })

    return {
      listOption,
      filters,
      selectList,
      chartData,
      chartSize,
      orgInfo,
      getIndex(index: number){
        const {
          totalCount,
          maxPage,
          page
        } = listOption;
        return totalCount - (maxPage * (page - 1)) - index;
      },
      getTableTime(date: string) {
        const dateObj:any = new Date(date);
        let result: string = '';
        (!date || dateObj.toString() === 'Invalid Date') 
        ? result = '-'
        : result = filters.dateToString(dateObj, 'tableTime');
        return result.replaceAll('<br/>', ' ');
      },
      handleClickSearch() {
        listOption.page = 1;
        setUrlQueryString();
      },
      downloadExcel(){
        store.commit('loadingBar/showLoadingBar');
        getOrgTestStatisticsExcel()
        .then(() => {
          store.commit('loadingBar/hideLoadingBar');
        })
        .catch(() => {
          router.push({ name: 'error' }).catch(() => {});
        })
      }
    }
  }
}
</script>
