<template>
  <el-container class="basic-layout-wrapper"  v-loading="visible">
    <Header v-if="this.$route.name !== 'testHistory' && this.$route.name !== 'previewReport'"/>
    <el-container class="content-wrapper">
      <Breadcrumb/>
      <router-view :key="$route.fullPath"/> 
    </el-container>
    <Footer v-if="this.$route.name !== 'previewReport'"/>
  </el-container>
</template>

<script lang="ts">
import { computed } from 'vue';
import { useStore } from 'vuex';
import Header from '@/components/Header/Header.vue';
import Breadcrumb from '@/components/Breadcrumb/Breadcrumb.vue';
import Footer from '@/components/Footer/Footer.vue';
import 'element-plus/packages/theme-chalk/src/container.scss'

export default {
  name: 'BasicLayout',
  components: {
    Header,
    Breadcrumb,
    Footer
  },
  setup() {
    const store = useStore();

    return {
      visible: computed(() => store.getters['loadingBar/visible']),
    }
  },
  methods: {
  }
}
</script>

<style lang="scss">
#app {
  
  .el-container {
    flex-direction: column;
  }
  .content-wrapper {
    padding: 36px 107px;
    padding-bottom: 50px;
  }

}
</style>