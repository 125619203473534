import instance from '@/api/index';

const SetGroupAPI = {
  getSetGroupList: (query: object) => {
    const url = '/counsel/test/set/group/list';
    const params = query;
    const method = 'get';

    return instance({
      url,
      params,
      method,
    });
  },
  getSetGroupDetail: (query: object) => {
    const url = '/counsel/test/set/group/detail';
    const params = query;
    const method = 'get';

    return instance({
      url,
      params,
      method,
    });
  },
  deleteSetGroup: (query: object) => {
    const url = '/counsel/test/set/group';
    const params =  {
      data: query,
    };

    return instance.delete(
      url,
      params,
    );
  },
  postCreateSetGroup: (query: object) => {
    const url = '/counsel/test/set/group';
    const params = query;

    return instance.post(
      url,
      params,
    );
  },
  putEditSetGroup: (query: object) => {
    const url = '/counsel/test/set/group';
    const params = query;

    return instance.put(
      url,
      params,
    );
  },
  getTestSet: () => {
    const url = '/counsel/test/set';
    const method = 'get';

    return instance({
      url,
      method,
    });
  },
  getOwnTestSetList: (query: object) => {
    const url = '/counsel/test/set/group';
    const params = query;
    const method = 'get';


    return instance({
      url,
      params,
      method,
    });
  },

};

export default SetGroupAPI;
