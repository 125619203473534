
import { reactive, ref } from '@vue/reactivity';
import { onBeforeMount } from '@vue/runtime-core';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import AccountAPI from '@/api/account';
import MessageBox from 'element-plus/lib/el-message-box';
import notify from '@/utils/notify';
import filters from '@/utils/filter';
import { koreanEngRegex, passwordRegex, phoneRegex } from '@/utils/regex';
import { getCookie } from '@/utils/cookie';

export default {
  name: 'userInfo',
  setup() {
    const store = useStore();
    const router = useRouter();
    const level = ref('');
    const isFocused = ref(false); // 자동완성 방지
    const selectList: {
      licenseList: {
        label: string,
        value: number
      }[],
      agencyList: {
        label: string,
        value: number
      }[]
    } = reactive({
      licenseList: [
        {
          label: '임상심리사',
          value: 0
        },
        {
          label: '상담심리사',
          value: 1
        },
        {
          label: '사회복지사',
          value: 2
        },
        {
          label: '기타 전문 상담사',
          value: 3
        },
      ],
      agencyList: []
    });
    
    const userInfoData:{
      id: any,
      mail: string,
      name: string,
      password: string,
      checkPassword: string,
      phone: any,
      organizationId: any,
      organization: string,
      license: number[],
      memo: string
    } = reactive({
      id: null,
      mail: '',
      name: '',
      password: '',
      checkPassword: '',
      phone: '',
      organizationId: null,
      organization: '',
      license: [],
      memo: ''
    })

    /** 연락처 유효성 체크 및 '-' 추가 */
    const handleChangePhone = (word: string) => {
      const phone:any = word ? word.replaceAll('-', '') : '';
      if (phone.length > 11) {
        notify.warning('연락처는 11자 이내로 입력해주세요');
        userInfoData.phone = '';
        return;
      }
      if (!isNaN(phone)) {
        userInfoData.phone = filters.getFormedPhoneNumber(phone);
      } else {
        notify.warning('연락처를 정확히 입력해주세요');
        userInfoData.phone = '';
      }
    }

    /** 내정보 get api */
    const getUserInfo = async () => {
      await AccountAPI.getUserInfo()
      .then((response) => {
        const responseData = response.data
        if (responseData 
        && typeof responseData === 'object'
        && Object.keys(responseData).indexOf('result') > -1
        && Object.keys(responseData).indexOf('response') > -1
        && responseData.result ) {
          const {
            id,
            mail,
            name,
            phone,
            organizationId,
            organization,
            license,
            memo,
          } = responseData.response;

          if (typeof id === 'string') {
            userInfoData.id = Number.parseInt(id);
          } else if (typeof id === 'number') {
            userInfoData.id = id;
          }

          if (typeof mail === 'string') {
            userInfoData.mail = mail;
          }

          if (typeof name === 'string') {
            userInfoData.name = name;
          }

          if (typeof phone === 'string') {
            handleChangePhone(phone);
          }

          if (typeof organization === 'string') {
            userInfoData.organization = organization;
          }

          if (typeof organizationId === 'string') {
            userInfoData.organizationId = Number.parseInt(organizationId);
          } else if (typeof organizationId === 'number') {
            userInfoData.organizationId = organizationId;
          }

          if (Array.isArray(license)) {
            const tmpList = [];
            for (const item of license) {
              if (typeof item === 'string') {
                tmpList.push(Number.parseInt(item))
              } else if (typeof item === 'number') {
                tmpList.push(item);
              }
            }
            userInfoData.license = tmpList;
          }

          if (typeof memo === 'string') {
            userInfoData.memo = memo;
          }
        } else {
          throw 'error'
        }
      })
      .catch((err) => {
        throw err;
      })
    }

    /** 내정보 수정 params */
    const getEditParams = () => {
      const {
        password,
        checkPassword,
        name,
        phone,
        license,
        memo
      } = userInfoData;
      const params:any = {}

      if (password !== '' || checkPassword !== '') {
        if (password !== checkPassword || !passwordRegex.test(password)) {
          notify.warning('비밀번호를 정확히 입력해주세요.');
          return;
        } else {
          params.password = password;
        }
      }

      if (name !== '' && koreanEngRegex.test(name)) {
        params.name = name;
      } else {
        notify.warning('이름을 정확히 입력해주세요.');
        return;
      }
      if (phone && phone !== '' && phoneRegex.test(phone)) {
        const onlyPhoneNumber = phone.replaceAll('-', '');
        if (onlyPhoneNumber.length > 11) {
          notify.warning('연락처를 11자 이내로 입력해 주세요.');
          return;
        } else {
          params.phone = onlyPhoneNumber;
        }
      } else {
        notify.warning('연락처를 정확히 입력해주세요.');
        return;
      }

      params.license = license;

      params.memo = memo;

      return params;
    }

    /** 내정보 수정 api */
    const postEditUserInfo = async () => {
      try {
        const params = getEditParams();
        if (params) {
          await AccountAPI.putEditUserInfo(params)
          .then((response) => {
            const responseData = response.data
            if (responseData 
            && typeof responseData === 'object'
            && Object.keys(responseData).indexOf('result') > -1
            && Object.keys(responseData).indexOf('response') > -1
            && responseData.result ) {
              MessageBox({
                type: 'info',
                title: '내 정보 변경 완료',
                message: '내 정보가 변경 완료되었습니다. 이전 페이지로 이동합니다',
                callback: router.back
              })
            } 
          })
        }
      } catch(e) {

      }
    }

    /** 초기화 */
    const refresh = () => {
      store.commit('loadingBar/showLoadingBar');
      getUserInfo()
      .then(() => {
        store.commit('loadingBar/hideLoadingBar');
      })
      .catch(() => {
        router.push({ name: 'error' }).catch(() => {});
      })
    }

    /** 내정보 저장 클릭 */
    const handleClickSave = () => {
      store.commit('loadingBar/showLoadingBar');
      postEditUserInfo()
      .then(() => {
        store.commit('loadingBar/hideLoadingBar');
      })
    }

    onBeforeMount(() => {
      refresh();
    })

    return {
      isFocused,
      userInfoData,
      selectList,
      handleClickSave,
      handleChangePhone
    }
  }
}
