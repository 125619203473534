
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router'
import MessageBox from 'element-plus/lib/el-message-box';
import Modal from '@/components/Modal/Modal.vue';
import { VueDaumPostcode } from 'vue-daum-postcode';
import { VueDaumPostcodeCompleteResult } from 'vue-daum-postcode/src/interfaces';
import { reactive, ref } from '@vue/reactivity';
import OrgAPI from '@/api/organization';
import { onBeforeMount } from '@vue/runtime-core';
import notify from '@/utils/notify';
import filters from '@/utils/filter';
import { emailRegex, passwordRegex, phoneRegex } from '@/utils/regex';
import { getCookie } from '@/utils/cookie';
export default {
  name: 'adminMyPage',
  components: {
    VueDaumPostcode,
    Modal
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const isFocused = ref(false); // 자동완성 방지
    const selectList:{
      testGroupList: any[]
    } = reactive({
      testGroupList: []
    });

    const organizationInfo = reactive({
      id: 0,
      name: '',
      tel: '',
      mail: '',
      password: '',
      checkPassword: '',
      fax: '',
      postCode: '',
      address: '',
      addressDetail: '',
      managerName: '',
      managerPhone: '',
      testSetGroup: null,
      createAt: '',
      area1: '',
      area2: '',
      area3: '',
      memo: '',
    });

    const handleChangeFax = (word: string) => {
      const fax:any = word ? word.replaceAll('-', '') : '';
      if (fax.length > 11) {
        notify.warning('팩스 번호를 11자 이내로 입력해주세요');
        organizationInfo.fax = ''
        return;
      }

      if (!isNaN(fax)) {
        organizationInfo.fax = filters.getFormedPhoneNumber(fax);
      } else {
        notify.warning('팩스 번호를 정확히 입력해주세요')
        organizationInfo.fax = '';
      }
    }

    const handleChangeTel = (word: string) => {
      const tel:any = word ? word.replaceAll('-', '') : '';
      if (tel.length > 11) {
        notify.warning('대표 연락처를 11자 이내로 입력해주세요');
        organizationInfo.tel = ''
        return;
      }

      if (!isNaN(tel)) {
        organizationInfo.tel = filters.getFormedPhoneNumber(tel);
      } else {
        notify.warning('대표 연락처를 정확히 입력해주세요')
        organizationInfo.tel = '';
      }
    }

    const handleChangeManagerPhone = (word: string) => {
      const managerPhone:any = word ? word.replaceAll('-', '') : '';
      if (managerPhone.length > 11) {
        notify.warning('대표 연락처를 11자 이내로 입력해주세요');
        organizationInfo.managerPhone = ''
        return;
      }

      if (!isNaN(managerPhone)) {
        organizationInfo.managerPhone = filters.getFormedPhoneNumber(managerPhone);
      } else {
        notify.warning('대표 연락처를 정확히 입력해주세요')
        organizationInfo.managerPhone = '';
      }
    }

    /** 테스트 셋 그룹 list get api */
    const getTestSetGroupList = async() => {
      await OrgAPI.getTestSetGroupList()
      .then((response) => {
        const responseData = response.data;
        if (responseData 
        && typeof responseData === 'object'
        && Object.keys(responseData).indexOf('result') > -1
        && Object.keys(responseData).indexOf('response') > -1
        && responseData.result ) {
          const {
            list
          } = responseData.response;
          if (Array.isArray(list) && list.length > 0) {
            const tmpList:{
              label: string,
              value: any
            }[] = [
              {
                label: '선택안함',
                value: 0
              }
            ]
            for (const item of list) {
              const itemKeys = Object.keys(item);
              if (itemKeys.indexOf('id') > -1 && itemKeys.indexOf('name') > -1) {
                tmpList.push({
                  label: item.name,
                  value: item.id
                });
              }
            }

            selectList.testGroupList = tmpList;
          }
        }
      })
      .catch(err => {
        throw err;
      })
    }


    /** 기관 정보 get api */
    const getOrgDetail = async() => {
      const params = {
        id: getCookie('pk')
      }
      await OrgAPI.getOrgDetail(params)
      .then((response) => {
        const responseData = response.data;
        if (responseData 
        && typeof responseData === 'object'
        && Object.keys(responseData).indexOf('result') > -1
        && Object.keys(responseData).indexOf('response') > -1
        && responseData.result ) {
          const {
            // id,
            name,
            tel,
            fax,
            mail,
            // addressType,
            address1,
            address2,
            postCode,
            managerName,
            managerPhone,
            memo,
            testSetGroupId,
            // testSetGroup,
            area1,
            area2,
            area3
          } = responseData.response;

          if (typeof name === 'string')
            organizationInfo.name = name;
          
          if (typeof tel === 'string')
            handleChangeTel(tel);
          
          if (typeof fax === 'string')
            handleChangeFax(fax);
          
          if (typeof mail === 'string')
            organizationInfo.mail = mail;
          
          if (typeof address1 === 'string')
            organizationInfo.address = address1;
          
          if (typeof address2 === 'string')
            organizationInfo.addressDetail = address2;
          
          if (typeof postCode === 'string')
            organizationInfo.postCode = postCode;

          if (typeof area1 === 'string')
            organizationInfo.area1 = area1;

          if (typeof area2 === 'string')
            organizationInfo.area2 = area2;

          if (typeof area3 === 'string')
            organizationInfo.area3 = area3;

          if (typeof managerName === 'string')
            organizationInfo.managerName = managerName;

          if (typeof managerPhone === 'string')
            handleChangeManagerPhone(managerPhone);

          if (typeof memo === 'string')
            organizationInfo.memo = memo;

          organizationInfo.testSetGroup = testSetGroupId;
            
        } else {
          throw 'err';
        }
      })
      .catch((err) => {
        throw err;
      })
    }

    /** 기관 정보 수정 params */
    const getEditParams = () => {
      const params:any = {
        id: getCookie('pk')
      };

      const {
        name,
        tel,
        fax,
        testSetGroup,
        password,
        checkPassword,
        address,
        addressDetail,
        area1,
        area2,
        area3,
        postCode,
        managerName,
        managerPhone,
        memo
      } = organizationInfo;

      if (name) {
        params.name = name;
      } else {
        notify.warning('기관명을 입력해주세요');
        return;
      }

      if (tel !== '') {
        if (phoneRegex.test(tel)) {
          const onlyTelNumber = tel.replaceAll('-', '');
          if (onlyTelNumber.length > 11) {
            notify.warning('대표 연락처를 11자 이내로 입력해 주세요');
            return;
          } else {
            params.tel = onlyTelNumber;
          }
        } else {
          notify.warning('대표 연락처를 정확히 입력해주세요.');
          return;
        }
      } 

      if (fax !== '') {
        if (phoneRegex.test(fax)) {
          const onlyFaxNumber = fax.replaceAll('-', '');
          if (onlyFaxNumber.length > 11) {
            notify.warning('팩스번호를 11자 이내로 입력해 주세요');
            return;
          } else {
            params.fax = onlyFaxNumber;
          }
        } else {
          notify.warning('팩스번호를 정확히 입력해주세요.');
          return;
        }
      } else {
        params.fax = '';
      }

      if (testSetGroup !== null && testSetGroup !== 0)
        params.testSetGroupId = testSetGroup;
      else
        params.testSetGroupId = null;

      if (password && checkPassword) {
        if (password === checkPassword && passwordRegex.test(password)) {
          params.password = password;
        } else {
          notify.warning('비밀번호를 확인해주세요');
          return;
        }
      }

      if (address) {
        params.address1 = address;
      } else {
        notify.warning('주소를 입력해주세요');
        return;
      }

      if (addressDetail) 
        params.address2 = addressDetail;
      else
        params.address2 = '';

      params.area1 = area1;
    
      params.area2 = area2;
    
      params.area3 = area3;


      if (postCode)
        params.postCode = postCode;
      else {
        notify.warning('주소를 확인해주세요');
        return;
      }


      if (managerName)
        params.managerName = managerName;
      else 
        params.managerName = '';

      if (managerPhone)
        params.managerPhone = managerPhone.replaceAll('-', '');
      else
        params.managerPhone = '';
      
      if (managerPhone !== '') {
        if (phoneRegex.test(managerPhone)) {
          const onlyNumber = managerPhone.replaceAll('-', '');
          if (onlyNumber.length > 11) {
            notify.warning('담당자 연락처를 11자 이내로 입력해 주세요');
            return;
          } else {
            params.managerPhone = onlyNumber;
          }
        } else {
          notify.warning('담당자 연락처를 정확히 입력해주세요.');
          return;
        }
      } else {
        params.managerPhone = managerPhone;
      }

      params.memo = memo;


      return params;
    }

    /** 기관 정보 수정 api */
    const putEditOrg = async() => {
      const params = getEditParams();
      if (params) {
        await OrgAPI.putEditOrg(params)
        .then((response) => {
          const responseData = response.data;
          if (responseData 
          && typeof responseData === 'object'
          && Object.keys(responseData).indexOf('result') > -1
          && responseData.result ) {
            MessageBox({
              title: '기관 정보 수정',
              message: '기관 정보 수정이 완료되었습니다.',
              type: 'info',
              callback() {
                router.back();
              }
            })
          } else {
            throw 'err';
          }
        })
        .catch(error => {
          if (typeof error === 'object'
          && Object.keys(error).indexOf('response') > -1
          && typeof error.response === 'object'
          && Object.keys(error.response).indexOf('status') > -1
          && Object.keys(error.response).indexOf('data') > -1
          && Object.keys(error.response.data).indexOf('response') > -1
          && Object.keys(error.response.data.response).indexOf('message') > -1) {
            const status = error.response.status;
            if (status === 400) {
              if (error.response.data.response.message === 'Organization already exists.') {
                notify.error('이미 존재하는 기관 이름입니다.');
                return 1;
              } else if (error.response.data.response.message === 'User already exists.') {
                notify.error('이미 존재하는 이메일입니다.');
                return 1;
              } else if (error.response.data.response.message === 'Only master can do this.') {
                MessageBox({
                  type: 'error',
                  message: '잘못된 접근입니다.',
                  callback() {
                    router.push({ name: 'manageCounselee' }).catch(() => {});
                  }
                });
                return 1;
              } else {
                throw 'err';
              } 
            } else {
              throw error;
            }
          } else {
            throw error;
          }
        })
      }
    }

    /** 초기화 */
    const refresh = () => {
      store.commit('loadingBar/showLoadingBar');
      Promise.all([
        getOrgDetail(),
        getTestSetGroupList()
      ])
      .then(() => {
        store.commit('loadingBar/hideLoadingBar');
      })
      .catch(() => {
        router.push({ name: 'error' }).catch(() => {});
      })

    }

    onBeforeMount(() => {
      refresh();
    })



    return {
      selectList,
      isFocused,
      organizationInfo,
      handleChangeTel,
      handleChangeFax,
      handleChangeManagerPhone,
      handleClickCancel() {
        router.go(-1);
      },
      handleClickSave() {
        store.commit('loadingBar/showLoadingBar');
        putEditOrg()
        .then(() => {
          store.commit('loadingBar/hideLoadingBar');
        })
        .catch(() => {
          router.push({name: 'error'}).catch(() => {});
        })
      
      },
      handleClickZipCode() {
        store.commit('loadingBar/showLoadingBar');
        setTimeout(() => {
          store.commit('modal/openModal');
          setTimeout(() => {
            store.commit('loadingBar/hideLoadingBar');
          }, 300)
        }, 100)
      },
      onComplete (result: VueDaumPostcodeCompleteResult) {
        organizationInfo.postCode = result.zonecode;
        organizationInfo.address = result.roadAddress;
        organizationInfo.area1 = result.sido;
        organizationInfo.area2 = result.sigungu;
        organizationInfo.area3 = result.bname1;
        store.commit('modal/closeModal');
      },
    }
  }
}
