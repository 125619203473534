<template>
  <div class="counselee-report-wrapper">
    <div class="counselee-info-wrapper">
      <div class="counselee-info-button-wrapper">
        <label>{{'개인 정보'}}</label>
        <el-button
          type="info"
          @click="handleClickEdit"
        >
          <i class="el-icon-edit"/>
          {{'정보 수정'}}
        </el-button>
        <el-button
          type="info"
          disabled
          @click="handleOpenModal('counseleeDelete')"
        >
        <i class="el-icon-close"/>
          {{' 삭제'}}
        </el-button>
      </div>
      <table class="counselee-info-table">
        <thead>
          <tr>
            <th>생년월일</th>
            <th>성별</th>
            <th>연락처</th>
            <th>테스트 횟수</th>
            <th>등록일시</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{ filters.getFormedDate(counseleeInfoData.counseleeInfo.birthDate) }}</td>
            <td>{{ counseleeInfoData.counseleeInfo.gender === 'F' ? '여' : '남' }}</td>
            <td>{{ filters.getFormedPhoneNumber(counseleeInfoData.counseleeInfo.phone) }}</td>
            <td>{{counseleeInfoData.counseleeInfo.testCnt}}</td>
            <td>
              <span v-html="getTableTime(counseleeInfoData.counseleeInfo.createAt)" />
            </td>
          </tr>
        </tbody>
        <thead>
            <tr>
              <th>기관</th>
              <th>학교 / 학년(나이)</th>
              <th>치료력</th>
              <th>지정 상담자</th>
              <th>보호자 정보</th>
              <th>QR 코드</th>
            </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{counseleeInfoData.counseleeInfo.agency}}</td>
            <td>{{ `${counseleeInfoData.counseleeInfo.schoolKind} / ${counseleeInfoData.counseleeInfo.schoolYear}` }}</td>
            <td>{{counseleeInfoData.counseleeInfo.careHistory}}</td>
            <td>{{counseleeInfoData.counseleeInfo.counselor}}</td>
            <td> 
              <a
                @click="handleOpenModal('guardianInfo')"
              >
                {{'상세보기'}}
              </a>
            </td>
            <td>
              <a
                @click="downloadQrCode(
                  counseleeInfoData.counseleeInfo.qrCode, 
                  counseleeInfoData.counseleeInfo.name
                )"
              >
                {{'다운로드'}}
              </a>
            </td>
          </tr>
        </tbody>
        <tr 
          class="horizontal-row"
          v-if="counseleeInfoData.counseleeInfo.memo"
        >
          <th>메모</th>
          <td>{{ counseleeInfoData.counseleeInfo.memo }}</td>
        </tr>
        <tr v-else class="horizontal-row" style="border-top: 0;"></tr>
      </table>
    </div>
    <div class="counselee-test-info-wrapper">
      <table class="counselee-test-info-table">
        <label>{{'테스트 정보'}}</label>
        <tr>
          <th>상담자</th>
          <td>{{testInfoData.counselor}}</td>
          <th>테스트 일자</th>
          <td>{{ getTableTime(testInfoData.testDate)}}</td>
          <th>테스트 결과</th>
          <td>
            <el-button
              v-if="testInfoData.testResult === 0"
              type="success"
              class="medium"
              plain
            >
              {{'정기 상담 권유'}}
            </el-button>
            <el-button
              v-else-if="testInfoData.testResult === 1"
              type="danger"
              class="medium"
              plain
            >
              {{'조기 상담 권유'}}
            </el-button>
            <el-button
              v-else-if="testInfoData.testResult === 2"
              type="warning"
              class="medium"
              plain
            >
              {{'응급 상담 권유'}}
            </el-button>
            <div v-else
            >{{ testInfoData.testResult }}</div>
          </td>
        </tr>
      </table>
    </div>
    <div class="counselee-report-detail-wrapper">
      <div class="counselee-report-button-wrapper">
        <label>{{`보고서 ${mode.status === 1 ? '수정' : '작성' }`}}</label>
        
      </div>
      <div class="counselee-report-box">
        <div class="input-wrapper">
          <div class="label-wrapper">
            <label>{{'아이들의 이야기를 들어주는 친구 Joanne 의 공감 서비스란?'}}</label>
          </div>
          <el-input 
            type="textarea" 
            v-model="reportInfoData.info"
            disabled
          />
        </div>
        <div class="input-wrapper">
          <div class="label-wrapper">
            <label>{{'01 - Joanne 의 공감 상담 영역'}}</label>
          </div>
          <List
            :selectedPage="1"
            :maxPage="6"
            :totalCount="0"
            :pagination="false"
          >
            <template v-slot:list-table>
              <el-table
                :data="listOption.list"
              >
                <el-table-column
                  align ="center"
                  width="50"
                  label = "No"
                  type="index"
                  fixed
                />
                <el-table-column
                  align ="center"
                  prop="kind"
                  label="영역"
                >
                  <template #header>
                    {{'영역'}}
                  </template>
                </el-table-column>
                <el-table-column
                  align ="center"
                  prop="itemCnt"
                >
                  <template #header>
                    {{'항목'}}
                  </template>
                </el-table-column>
                <el-table-column
                  align ="center"
                  prop="expression"
                >
                  <template #header>
                    {{'표현'}}
                  </template>
                </el-table-column>
                <el-table-column
                  align ="center"
                  width="170"
                >
                  <template #header>
                    {{'상담 권유'}}
                  </template>
                  <template #default="scope">
                    <el-button
                      v-if="scope.row.recommendation === 2"
                      type="warning"
                      class="medium"
                      plain
                    >
                      {{'응급 상담 권유'}}
                    </el-button>
                    <el-button
                      v-if="scope.row.recommendation === 1"
                      type="danger"
                      class="medium"
                      plain
                    >
                      {{'조기 상담 권유'}}
                    </el-button>
                    <el-button
                      v-if="scope.row.recommendation === 0"
                      type="success"
                      class="medium"
                      plain
                    >
                      {{'정기 상담 권유'}}
                    </el-button>
                  </template>
                </el-table-column>
                <el-table-column
                  align ="center"
                  prop="testDate"
                  label="의미"
                  width="400"
                >
                  <template #header>
                    {{'의미'}}
                  </template>
                  <template #default="scope">
                    <el-select 
                      v-model="scope.row.meaning" 
                      multiple collapse-tags 
                      placeholder="의미 선택"
                    >
                      <el-option
                        v-for="(item, index) in scope.row.meaningOption"
                        :key="index"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                  </template>
                </el-table-column>
              </el-table>
            </template>
          </List>
        </div>
        <div class="input-wrapper">
          <div class="label-wrapper">
            <label>{{'02 - 보호자 관찰 의견'}}</label>
          </div>
          <el-input type="textarea" v-model="reportInfoData.parentsOpinion"
          />
        </div>
        <div class="input-wrapper last">
          <div class="label-wrapper">
            <label>{{'03 - Joanne 의 의견'}}</label>
            <div class="report-detail-button-wrapper">
              <el-button
                type="info"
                @click="handleOpenWindow"
              >
                {{'테스트 내역 상세 보기'}}
              </el-button>
            </div>
          </div>
          <el-input type="textarea" v-model="reportInfoData.joenOpinion"
          />
        </div>
        <div class="bottom-wrapper">
          <el-button
            type="danger"
            @click="handleClickDelete"
            :disabled="!mode.status"
          >
          <i class="el-icon-close"/>
            {{' 삭제'}}
          </el-button>
          <el-button
            type="primary"
            @click="handleClickSave"
          >
            {{ `보고서 ${mode.status === 1 ? '수정' : '저장' }`}}
          </el-button>
        </div>
      </div>
    </div>
        <!-- 내담자 삭제 -->
    <Modal v-if="modalOption.modalType === 'counseleeDelete'">
      <template v-slot:modal-title><b class="modal-title">내담자 삭제</b></template>
      <template v-slot:modal-body>
        <p>{{`[${counseleeInfoData.name}] 내담자를 삭제하면`}}<br>{{`모든 테스트 기록이 삭제됩니다.`}}</p>
        <br>
        <p>{{`삭제하시겠습니까?`}}</p>
      </template>
      <template v-slot:modal-footer>
        <el-button
          type="primary"
          @click="closeModal">
          삭제
        </el-button>
      </template>
    </Modal>
    <!-- 보호자 정보 -->
    <Modal v-else-if="modalOption.modalType === 'guardianInfo'">
      <template v-slot:modal-title><b class="modal-title">보호자 정보</b></template>
      <template v-slot:modal-body>
        <div 
          class="guardian-info-box"
          v-for="(item, index) in counseleeInfoData.guardianList"
          :key="index"
        >
          <div class="info-wrapper">
            <div class="label-wrapper">
              <label>{{'성명'}}</label>
            </div>
            <div class="item-wrapper">
              {{`${item.name} (${item.relationString}, ${item.age}세, ${item.gender === 'F' ? '여' : '남'})`}}
            </div>
          </div>
          <div class="info-wrapper">
            <div class="label-wrapper">
              <label>{{'연락처'}}</label>
            </div>
            <div class="item-wrapper">
              {{ filters.getFormedPhoneNumber(item.phone) }}
            </div>
          </div>
          <div class="info-wrapper">
            <div class="label-wrapper">
              <label>{{'메모'}}</label>
            </div>
            <div class="item-wrapper">
              {{ item.memo ? item.memo : '-'}}
            </div>
          </div>
          <div class="info-wrapper psc">
            <div class="label-wrapper">
              <label>{{'PSC 점수'}}</label>
            </div>
            <div 
              class="item-wrapper"
              v-if="item.pscList.length > 0"
            >
              <p 
                v-for="(item, index) in item.pscList"
                :key="index"
              >
                {{`${item.score}  (${item.date})`}}
              </p>
            </div>
            <div v-else>{{'-'}}</div>
          </div>
        </div>
      </template>
      <template v-slot:modal-footer>
        <el-button
          type="primary"
          @click="goEditCounseleeInfo"
        >
          보호자 수정
        </el-button>
      </template>
    </Modal>
  </div>
</template>

<script lang="ts">
import { onBeforeMount, reactive } from "vue";
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import List from '@/components/List/List.vue';
import Modal from '@/components/Modal/Modal.vue';
import ReportAPI from '@/api/report';
import CounseleeAPI from '@/api/counselee';
import filters from '@/utils/filter';
import MessageBox from 'element-plus/lib/el-message-box';
import notify from '@/utils/notify';
import { getCookie } from '@/utils/cookie';

export default {
  name: 'counseleeReportDetail',
  components: {
    List,
    Modal
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const userCounselorId = getCookie('counselorId');
    const userLevel = getCookie('userLevel');
    const userOrganizationId = getCookie('organizationId');
    /**
     * 0: 보고서 작성
     * 1: 보고서 수정
     */
    const mode:{
      status: number
    } = reactive({
      status: 0
    });

    const counseleeInfoData: any = reactive({
      counseleeInfo : {
        birthDate: '',
        careHistory: '',
        counselor: '',
        createAt: '',
        gender: '',
        name: '',
        phone: '',
        schoolKind: '',
        schoolYear: 0,
        testCnt: 0,
        agency: '',
        memo: '',
        qrCode: '',
      },
      guardianList: []
    });
    const testInfoData: any = reactive({
      id: null,
      counselor: '',
      testDate: '',
      testResult: null
    })
    const reportInfoData = reactive({
      info: 'J.O.A.N.N.E 공감서비스는 아이들에게 자연스러운 대화를 통해 아이들이 일상 생활에서 겪는 스트레스 신호를 발견하고, 상담 필요성을 A.I. 시스템을 이용하여 선별한 후 적합한 전문기관에서 도움을 주기 위해 실시하는 것입니다. 이 검사는 의학적 진단을 위한 평가가 아니며, 검사 결과 상담 필요성이 있는 아이들은 최근의 스트레스로 정서나 행동상의 어려움이 있을 가능성이 있으므로, 상황에 맞는 도움을 제공받을 필요가 있다는 것입니다. \n만일 J.O.A.N.N.E. 공감서비스 결과 상담 권유로 평가되었다면, 심리적 어려움과 스트레스, 우울, 신체적 불편감 등의 경향성이 높게 나타날 수 있으므로 양육자와 주변의 세심한 관심과 함께 전문기관에서의 심층 대면 진단을 권장해드립니다.',
      parentsOpinion: '',
      joenOpinion: '',
    });
    const listOption:{
      list:any[]
    } = reactive({
      list: []
    })
    const modalOption:{
      modalType: string
    } = reactive({
      modalType: ''
    });

    /** 정보수정 클릭 */
    const handleClickEdit =() => {
      router.push({
        name: 'editCounseleeInfo', 
        params: { 
          id: route.params.counseleeId, 
          name: counseleeInfoData.counseleeInfo.name
        },
        query: route.query
      })
    }


    /** 날짜 포맷 fnc */
    const getTableTime = (date: string) => {
      const dateObj:any = new Date(date);
      let result: string = '';
      (!date || dateObj.toString() === 'Invalid Date') 
      ? result = '-'
      : result = filters.dateToString(dateObj, 'tableTime');
      return result.replace('<br/>', ' ');
    }

    /** QR 코드 다운로드 */
    const downloadQrCode = (qrCodeURL: string, name: string) => {
      if (qrCodeURL && name) {
        let readerObj:any;
        const canvas = window.document.createElement('canvas');
        const context: any = canvas.getContext('2d');
        const img = window.document.createElement('img');
        canvas.width = 512;
        canvas.height = 512;
        img.crossOrigin = null;
        img.src = `${process.env.VUE_APP_BASE_URL}${qrCodeURL}`;
  
        
        setTimeout(() => {
          context.drawImage(img, 0, 0);
    
          readerObj = canvas.toDataURL('img/png').toString();
          let imgData;
    
          if (readerObj.split(',')[0].indexOf('base64') >= 0)
            imgData = atob(readerObj.split(',')[1]);
          else
            imgData = unescape(readerObj.split(',')[1]);
    
    
          const len = imgData.length;
          const view = new Uint8Array(len);
    
          for (let i = 0; i <len; i++) {
            view[i] = imgData.charCodeAt(i);
          }
    
          const blob = new Blob([view], {type: 'image/png'});
    
          const link: any = window.document.createElement('a');
          const url = window.URL.createObjectURL(blob);
          link.href = url;
          link.download = `${name}.png`;
          link.click();
          document.body.appendChild(link);
          setTimeout(() => {
            document.body.removeChild(link);
            URL.revokeObjectURL(url)
          }, 100)
        }, 500)
      }
    }

    /** 내담자 정보 수정 페이지 이동 */
    const goEditCounseleeInfo = () =>{
      store.commit('modal/closeModal');
      router.push({
        name: 'editCounseleeInfo', 
        params: { 
          id: route.params.counseleeId, 
          name: counseleeInfoData.counseleeInfo.name
        },
        query: route.query
      }).catch(() => {});
    }

    /** 보고서 조회 api */
    const getReport = () => {
      return new Promise((resolve:any, reject) => {
        const params = {
          id: route.params.testId
        }
        ReportAPI.getReport(params)
        .then((response) => {
          const responseData = response.data
          if (responseData 
          && typeof responseData === 'object'
          && Object.keys(responseData).indexOf('result') > -1
          && Object.keys(responseData).indexOf('response') > -1
          && responseData.result ) {
            const {
              id,
              no,
              name,
              birthDate,
              gender,
              phone,
              testCnt,
              createAt,
              organization,
              organizationId,
              schoolKind,
              schoolYear,
              careHistory,
              qrCode,
              counselor,
              memo,
              testId,
              testCounselor,
              testDate,
              testResult,
              author,
              authorAt,
              authorId,
              counselAreaList,
              reportStatus,
              parentOpinion,
              joenOpinion,
              printOrganization
            } = responseData.response;

            if (userLevel === '2' && (organizationId.toString() !== userOrganizationId.toString())) {
              store.commit('loadingBar/hideLoadingBar');
              MessageBox({
                title: '진입 에러 안내',
                type: 'error',
                message: '보고서 수정 권한이 없습니다',
                callback(){
                  router.go(-1);
                }
              })
            }
  
            /** 잘못된 이름url로 들어올 경우 */
            if (route.params.name !== name) {
              router.replace({
                name: 'reportDetail',
                params: {
                  counseleeId: route.params.counseleeId,
                  name: name,
                  testId: route.params.testId,
                  status: route.params.status
                }
              })
              .catch(() => {})
            }
  
            counseleeInfoData.counseleeInfo = {
              birthDate,
              careHistory,
              counselor,
              createAt,
              gender,
              name,
              phone,
              schoolKind,
              schoolYear,
              testCnt,
              memo,
              qrCode
            }
  
            const tmpAreaList:any[] = []
  
            for (const item of counselAreaList) {
              const tmpMeaningList: any[] = [];
              for (const meaningItem of item.meaning) {
                if (typeof meaningItem === 'string') {
                  tmpMeaningList.push(
                    Number.parseInt(meaningItem)
                  )
                }
              }
              tmpAreaList.push({
                id: item.id,
                expression: item.expression,
                itemCnt: item.itemCnt,
                kind: item.kind,
                meaning: tmpMeaningList,
                meaningOption: item.meaningOption,
                order: item.order,
                recommendation: item.recommendation,
              })
            }
  
            listOption.list = tmpAreaList;
  
  
            testInfoData.id = testId;
            testInfoData.counselor = testCounselor;
            testInfoData.testDate = testDate;
            testInfoData.testResult = testResult;
  
            if (joenOpinion) {
              reportInfoData.joenOpinion = joenOpinion;
            }
            if (parentOpinion) {
              reportInfoData.parentsOpinion = parentOpinion;
            }
            resolve();
          } else {
            reject();
          }
        })
        .catch((err) => {
          reject(err)
        })
      })
    }

    /** 보호자 list get api */
    const getGuardianList = async() => {
      const params = {
        id: route.params.counseleeId
      };
      await ReportAPI.getGuardianList(params)
      .then((response) => {
        const responseData = response.data
        if (responseData 
        && typeof responseData === 'object'
        && Object.keys(responseData).indexOf('result') > -1
        && Object.keys(responseData).indexOf('response') > -1
        && responseData.result ) {
          const {
            list
          } = responseData.response;

          counseleeInfoData.guardianList = list;
          return 'aaa';
        } else {
          throw 'error';
        }
      })
      .catch((err) => {
        throw err;
      })
    }

    /** 조치내역 get api */
    const getTestAction = async() => {
      const params = {
        id: route.params.testId
      }
      await CounseleeAPI.getTestAction(params)
      .then((response) => {
        const responseData = response.data
        if (responseData 
        && typeof responseData === 'object'
        && Object.keys(responseData).indexOf('result') > -1
        && Object.keys(responseData).indexOf('response') > -1
        && responseData.result ) {
          const {
            // id,
            // counselorId,
            // counselor,
            // parentId,
            // parent,
            content,
            // careDate
          } = responseData.response;

          if (content && reportInfoData.joenOpinion === '') {
            reportInfoData.joenOpinion += content;
          }

        }
      })
      .catch((err) => {
        throw err;
      })
    }

    /** 보고서 등록 api params */
    const getCreateReportParams = () => {
      const params:{
        id: number,
        parentOpinion: string,
        joenOpinion: string,
        counselAreaList: any[]
      } = {
        id: Number.parseInt(route.params.testId.toString()),
        parentOpinion: '',
        joenOpinion: '',
        counselAreaList: []
      }

      for(const item of listOption.list) {
        if (item.meaning.length === 0) {
          notify.warning('공감상담 영역을 모두 채워주세요.');
          store.commit('loadingBar/hideLoadingBar');
          return;
        }
        params.counselAreaList.push({
          id: item.id,
          meaning: item.meaning
        })
      }

      if (reportInfoData.parentsOpinion) {
        params.parentOpinion = reportInfoData.parentsOpinion;
      } else {
        notify.warning('보호자 의견을 채워주세요');
        store.commit('loadingBar/hideLoadingBar');
        return;
      }

      if (reportInfoData.joenOpinion) {
        params.joenOpinion = reportInfoData.joenOpinion;
      } else {
        notify.warning('Joanne 의견을 채워주세요');
        store.commit('loadingBar/hideLoadingBar');
        return;
      }

      return params;
    }

    /** 보고서 저장, 수정 api */
    const postCreateReport = async() => {
      const params = getCreateReportParams();
      if (params) {
        await ReportAPI.postCreateReport(params)
        .then((response) => {
          const responseData = response.data
          if (responseData 
          && typeof responseData === 'object'
          && Object.keys(responseData).indexOf('result') > -1
          && responseData.result ) {
            MessageBox({
              type: 'info',
              title: '보고서 저장 완료',
              message: '보고서 저장이 완료되었습니다.',
              callback: router.back
            })
          } else {
            throw 'error';
          }
        })
        .catch((err) => {
          if (typeof err === 'object' 
            && Object.keys(err).indexOf('response') > -1
            && typeof err.response === 'object'
            && Object.keys(err.response).indexOf('status') > -1
            && Object.keys(err.response).indexOf('data') > -1
            && Object.keys(err.response.data).indexOf('response') > -1
            && Object.keys(err.response.data.response).indexOf('message') > -1
          ) {
            if (err.response.status === 400
              && err.response.data.response.message === 'Only first counselor can do this.') {
              MessageBox({
                type: 'error',
                title: '보고서 저장 오류',
                message: '최초 작성 상담자만 보고서를 수정 할 수 있습니다.',
                callback: refresh
              })
            }
          } else {
            throw err;
          }
        })
      }
    }

    /** 보고서 삭제 api */
    const deleteReport = async() => {
      await ReportAPI.deleteReport({ id: Number.parseInt(route.params.testId.toString())})
      .then((response) => {
        const responseData = response.data;
        if (responseData 
        && typeof responseData === 'object'
        && Object.keys(responseData).indexOf('result') > -1
        && responseData.result ) {
          MessageBox({
            type: 'info',
            title: '보고서 삭제 완료',
            message: '보고서가 삭제되었습니다.',
            callback() {
              router.go(-1);
            }
          });
        } else {
          throw 'error';
        }
      })
      .catch((err) => {
        throw err;
      })
    }

    const closeModal = () => {
      store.commit('modal/closeModal')
    }
    const handleOpenModal = (type: string) => {
      modalOption.modalType = type
      store.commit('modal/openModal', 'type-popup')
    }

    /** 상세 테스트 내역 페이지 새탭 띄우기 */
    const handleOpenWindow = () => {
      window.open(`/counselee/test/history/${route.params.testId}`)
    }

    /** 보고서 저장, 수정 클릭 */
    const handleClickSave = () => {
      store.commit('loadingBar/showLoadingBar');
      postCreateReport()
      .then(() => {
        store.commit('loadingBar/hideLoadingBar');
      })
      .catch(() => {
        router.push({name: 'error'}).catch(() => {});
      })
    }

    /** 페이지 초기화 */
    const refresh =() => {
      store.commit('loadingBar/showLoadingBar');
      if (mode.status === 0) {
        Promise.all([
          getReport(),
          getGuardianList(),
          getTestAction()
        ])
        .then(() => {
          store.commit('loadingBar/hideLoadingBar');
        })
        .catch(() => {
          router.push({name: 'error'}).catch(() => {});
        })
      } else if (mode.status === 1) {
        Promise.all([
          getReport(),
          getGuardianList(),
        ])
        .then(() => {
          store.commit('loadingBar/hideLoadingBar');
        })
        .catch(() => {
          router.push({name: 'error'}).catch(() => {});
        })
      }

    }

    onBeforeMount(() => {
      mode.status = route.params.status === '0' ? 0 : 1;
      refresh();
    })

    return {
      counseleeInfoData,
      reportInfoData,
      listOption,
      modalOption,
      closeModal,
      handleOpenModal,
      handleOpenWindow,
      getTableTime,
      downloadQrCode,
      goEditCounseleeInfo,
      testInfoData,
      mode,
      handleClickSave,
      filters,
      handleClickEdit,
      handleClickDelete() {
        store.commit('loadingBar/showLoadingBar');
        deleteReport()
        .then(() => {
          store.commit('loadingBar/hideLoadingBar');
        })
        .catch(() => {
          router.push({ name: 'error' }).catch(() => {});
        })
      }
    }
  }
}
</script>