<template>
  <div class="manage-report-wrapper">
    <div class="search-box-wrapper">
      <label>{{'검색 옵션'}}</label>
      <div class="search-condition-wrapper">
        <div class="search-condition-item">
          <el-date-picker
            type="daterange"
            start-placeholder="테스트 일시(부터)"
            end-placeholder="테스트 일시(까지)"
            v-model="listOption.date"
            :disabled-date="filters.disabledDate"
          />
        </div>
        <div class="search-condition-item">
          <el-select 
            v-model="listOption.organization" 
            multiple collapse-tags 
            placeholder="기관정보 선택"
          >
            <el-option
              v-for="(item, index) in selectList.agencyList"
              :key="index"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </div>
        <div class="search-condition-item">
          <el-select 
            v-model="listOption.testResult" 
            multiple collapse-tags 
            placeholder="테스트 결과"
          >
            <el-option
              v-for="(item, index) in selectList.resultList"
              :key="index"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </div>
        <div class="search-condition-item">
          <el-input
            placeholder="검색어 입력(아동명, 상담자명)"
            v-model="listOption.search"
            @keydown.enter="handleClickSearch"
            maxlength="150"
          />
        </div>
        <el-button
          type="info"
          class="search"
          @click="handleClickSearch"
          :disabled="listOption.entireCount === 0"
        >{{'검색'}}</el-button>
      </div>
    </div>
    <div class="list-upper-wrapper">
      <label>{{`${listOption.totalCount} / ${listOption.entireCount}`}}</label>
    </div>
    <List
      :selectedPage="listOption.page"
      :maxPage="listOption.maxPage"
      :totalCount="listOption.totalCount"
      :pagination="true"
      @changePage="changePage"
    >
      <template v-slot:list-table>
        <el-table
          :data="listOption.list"
          empty-text="보고서 데이터가 없습니다."
        >
          <el-table-column
            align ="center"
            width="50"
            label = "No"
            type="index"
            :index="getIndex"
            fixed
          />
          <el-table-column
            align ="center"
            prop="testResult"
            width="170"
          >
            <template #header>
              {{'최근 테스트 결과'}}
              <span 
                class="icon pointer"
                @click="sort('testResult')"
              >
                <i 
                  v-if="listOption.orderField === 'testResult' 
                        && listOption.order === 'asc' 
                        ? true : false" 
                  class='el-icon-caret-top'
                />
                <i v-else class="el-icon-caret-bottom"/>
              </span>
            </template>
            <template #default="scope">
              <el-button
                v-if="scope.row.testResult === 2"
                type="warning"
                class="medium"
                plain
              >
                {{'응급 상담 권유'}}
              </el-button>
              <el-button
                v-if="scope.row.testResult === 1"
                type="danger"
                class="medium"
                plain
              >
                {{'조기 상담 권유'}}
              </el-button>
              <el-button
                v-if="scope.row.testResult === 0"
                type="success"
                class="medium"
                plain
              >
                {{'정기 상담 권유'}}
              </el-button>
            </template>
          </el-table-column>
          <el-table-column
            align ="center"
            prop="counselee"
            label="내담자"
          >
            <template #header>
              {{'내담자'}}
              <span 
                class="icon pointer"
                @click="sort('counselee')"
              >
                <i 
                  v-if="listOption.orderField === 'counselee' 
                        && listOption.order === 'asc' 
                        ? true : false" 
                  class='el-icon-caret-top'
                />
                <i v-else class="el-icon-caret-bottom"/>
              </span>
            </template>
          </el-table-column>
          <el-table-column
            align ="center"
            prop="progress"
          >
            <template #header>
              {{'진행도'}}
              <span 
                class="icon pointer"
                @click="sort('progress')"
              >
                <i 
                  v-if="listOption.orderField === 'progress' 
                        && listOption.order === 'asc' 
                        ? true : false" 
                  class='el-icon-caret-top'
                />
                <i v-else class="el-icon-caret-bottom"/>
              </span>
            </template>
            <template #default="scope">
              <p
                :class="scope.row.progress === 100 
                        ? 'complete-text' 
                        : ''"
              >
                {{`${scope.row.progress}%`}}
              </p>
            </template>
          </el-table-column>
          <el-table-column
            align ="center"
            prop="counselor"
          >
            <template #header>
              {{'상담자'}}
              <span 
                class="icon pointer"
                @click="sort('counselor')"
              >
                <i 
                  v-if="listOption.orderField === 'counselor' 
                        && listOption.order === 'asc' 
                        ? true : false" 
                  class='el-icon-caret-top'
                />
                <i v-else class="el-icon-caret-bottom"/>
              </span>
            </template>
          </el-table-column>
          <el-table-column
            align ="center"
            prop="organization"
          >
            <template #header>
              {{'기관'}}
              <span 
                class="icon pointer"
                @click="sort('organization')"
              >
                <i 
                  v-if="listOption.orderField === 'organization' 
                        && listOption.order === 'asc' 
                        ? true : false" 
                  class='el-icon-caret-top'
                />
                <i v-else class="el-icon-caret-bottom"/>
              </span>
            </template>
          </el-table-column>
          <el-table-column
            align ="center"
            prop="testDate"
            label="테스트 일시"
            width="180"
          >
            <template #header>
              {{'테스트 일시'}}
              <span 
                class="icon pointer"
                @click="sort('testDate')"
              >
                <i 
                  v-if="listOption.orderField === 'testDate' 
                        && listOption.order === 'asc' 
                        ? true : false" 
                  class='el-icon-caret-top'
                />
                <i v-else class="el-icon-caret-bottom"/>
              </span>
            </template>
            <template #default="scope">
              <span v-html="getTableTime(scope.row.testDate)" />
            </template>
          </el-table-column>
          <el-table-column
            align ="center"
            label="보고서 출력"
            width="170"
          >
            <template #header>
              {{'보고서 출력'}}
              <span 
                class="icon pointer"
                @click="sort('printFlag')"
              >
                <i 
                  v-if="listOption.orderField === 'printFlag' 
                        && listOption.order === 'asc' 
                        ? true : false" 
                  class='el-icon-caret-top'
                />
                <i v-else class="el-icon-caret-bottom"/>
              </span>
            </template>
            <template #default="scope">
              <a
                v-if="scope.row.printFlag"
                @click="handleClickPrint(scope.row.id)"
              >
                {{'출력하기'}}
              </a>
              <p v-if="scope.row.printCounselor">
                {{`(${scope.row.printCounselor})`}}
              </p>
            </template>
          </el-table-column>
          <el-table-column
            align ="center"
            prop="printDate"
            label="최근 출력일시"
            width="180"
          >
            <template #header>
              {{'최근 출력일시'}}
              <span 
                class="icon pointer"
                @click="sort('printDate')"
              >
                <i 
                  v-if="listOption.orderField === 'printDate' 
                        && listOption.order === 'asc' 
                        ? true : false" 
                  class='el-icon-caret-top'
                />
                <i v-else class="el-icon-caret-bottom"/>
              </span>
            </template>
            <template #default="scope">
              <span v-html="getTableTime(scope.row.printDate)" />
            </template>
          </el-table-column>
        </el-table>
      </template>
    </List>
  </div>
</template>

<script lang="ts">
import { reactive, ref, onBeforeMount, watch, onMounted } from "vue";
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import ReportAPI from '@/api/report';
import filters from '@/utils/filter';
import CounseleeAPI from '@/api/counselee';
import List from '@/components/List/List.vue';

export default {
  name: 'manageReport',
  components: {
    List
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const selectList: {
      agencyList: any[],
      resultList: any[]
    } = reactive({
      agencyList: [],
      resultList: []
    })
    const listOption:{
      entireCount: number,
      maxPage: number,
      totalCount: number,
      page: number,
      order: string,
      orderField: string,
      testResult: any[],
      organization: any[],
      search: string,
      list: any[],
      date: any[]
    } = reactive({
      entireCount: 0,
      maxPage: 10,
      totalCount: 0,
      page: 1,
      order: '',
      orderField: '',
      testResult: [],
      organization: [],
      search: '',
      list: [],
      date: [],
    });

    /** 검색쿼리 url 저장 */
    const setUrlQueryString = () => {
      const {
        page,
        order,
        orderField,
        testResult,
        organization,
        search,
        date
      } = listOption;
      const query:any = {};

      if (page !== 1)
        query.page = page;

      if (order)
        query.order = order;
      
      if (orderField)
        query.orderField = orderField;

      if (search)
        query.search = search;
      
      if (testResult.length > 0) 
        query.testResult = testResult;

      if (organization.length > 0) 
        query.organization = organization;

      if (Array.isArray(date)) {
        const tmpList = [];
        for (const item of date) {
          item && tmpList.push(filters.dateToString(item, 'yyyy-mm-dd'));
        }
        query.date = tmpList;
      }

      router.replace({
        name: 'manageReport',
        query
      }).catch(() => {});
    }

    /** 리스트 No */
    const getIndex = (index: number) => {
      const {
        totalCount,
        maxPage,
        page
      } = listOption;
      return totalCount - (maxPage * (page - 1)) - index;
    }

    /** 날짜 format fnc */
    const getTableTime = (date: string) => {
      const dateObj:any = new Date(date);
      let result: string = '';
      (!date || dateObj.toString() === 'Invalid Date') 
      ? result = '-'
      : result = filters.dateToString(dateObj, 'tableTime');
      return result.replace('<br/>', ' ');
    }

    /** 기관정보 get api */
    const getOrganizationList = async() => {
      await CounseleeAPI.getOrganizationList()
      .then((response) => {
        const responseData = response.data
        if (responseData 
        && typeof responseData === 'object'
        && Object.keys(responseData).indexOf('result') > -1
        && Object.keys(responseData).indexOf('response') > -1
        && responseData.result ) {
          const {
            list
          } = responseData.response;
          const tmpList = [
            {
              label: '전체', 
              value: 'all'
            }
          ]
          for (const item of list) {
            tmpList.push({
              label: item.name,
              value: item.id
            })
          }
          selectList.agencyList = tmpList
          selectList.resultList = [
            {
              label: '전체', 
              value: 'all'
            }, 
            {
              label: '결과 없음',
              value: -1
            },
            {
              label: '응급 상담 권유', 
              value: 2
            }, 
            {
              label: '조기 상담 권유', 
              value: 1
            },
            {
              label: '정기 상담 권유', 
              value: 0
            }
          ];
        } else {
          throw 'err';
        }
      })
      .catch((err) => {
        throw err;
      })
    }

    /** 보고서 리스트 api params */
    const getParams = () => {
      const {
        maxPage,
        page,
        organization,
        order,
        orderField,
        search,
        testResult,
        date
      } = listOption;

      const params: any = {
        maxPage: maxPage,
        page: page,
      };

      if (date && date.length === 2) {
        params.gte = filters.dateToString(date[0], 'yyyy-mm-dd');
        params.lte = filters.dateToString(date[1], 'yyyy-mm-dd');
      }

      if (organization.length > 0) {
        let tmpList: any[] = [];
        for (const item of organization) {
          if ( item === 'all') {
            tmpList = [];
            break;
          } else {
            tmpList.push(item)
          }
        }
        params.organization = JSON.stringify(tmpList);

      }

      if (testResult.length > 0 && testResult) {
        let tmpList: any[] = [];
        for (const item of testResult) {
          if ( item === 'all') {
            tmpList = [];
            break;
          } else {
            tmpList.push(item)
          }
        }
        params.testResult = JSON.stringify(tmpList);
      }

      if (order && orderField) {
        params.order = order;
        params.orderField = orderField;
      }

      if (search) {
        params.search = search;
      }

      return params;
    }

    /** 보고서 리스트 get api */
    const getReportList = () => {
      return new Promise((resolve:any, reject:any) => {
        const params = getParams();
        if (params) {
          ReportAPI.getReportList(params)
          .then((response) => {
            const responseData = response.data
            if (responseData 
            && typeof responseData === 'object'
            && Object.keys(responseData).indexOf('result') > -1
            && Object.keys(responseData).indexOf('response') > -1
            && responseData.result ) {
              const {
                entireCount,
                totalCount,
                // next,
                // previous,
                // startPage,
                // endPage,
                list
              } = responseData.response;

              listOption.entireCount = entireCount;
              listOption.totalCount = totalCount;
              listOption.list = list;
              resolve();

            }
          })
          .catch(() => {
            reject();
          })
        }
      })
      
    }

    /** list 정렬 */
    const sort = (orderField: string) => {
      if (listOption.orderField === orderField) {
        (listOption.order === 'desc') ? listOption.order = 'asc' : listOption.order = 'desc';
      } else {
        listOption.orderField = orderField;
        listOption.order = 'desc';
      }
      setUrlQueryString();
    }

    /** 페이지 이동 fnc */
    const changePage = (page: number) => {
      listOption.page = page;
      setUrlQueryString();
    }


    /** 검색버튼 클릭 */
    const handleClickSearch = () => {
      listOption.page = 1;
      setUrlQueryString();
    }

    /** 전체 체크 fnc */
    const checkAll = (type: string) => {
      if (type === 'org') {
        const tmpList: any [] = []
        for (const item of selectList.agencyList) {
          tmpList.push(item.value)
        }
        listOption.organization = tmpList;
      } else if (type === 'result') {
        const tmpList: any [] = []
        for (const item of selectList.resultList) {
          tmpList.push(item.value)
        }
        listOption.testResult = tmpList;
      }
    }

    /** 출력하기 클릭 */
    const handleClickPrint = (id:any) => {   
      router.push({
        name: 'printReport', 
        params: {
          id
        },
        query: route.query
      })
    }

    /** 초기화 */
    const refresh = () => {
      store.commit('loadingBar/showLoadingBar');
      getOrganizationList()
      .then(() => {
        getReportList()
        .then(() => {
          store.commit('loadingBar/hideLoadingBar');
        })
        .catch(() => {
          router.push({ name: 'error' }).catch(() => {});
        })
      })
      .catch(() => {
        router.push({ name: 'error' }).catch(() => {});
      })
    }

    onBeforeMount(() => {
      if (Object.keys(route.query).length > 0) {
        const {
          page,
          order,
          orderField,
          search,
          testResult,
          organization,
          date
        } = route.query;
        listOption.page = Number.parseInt(page ? page.toString() : '1');
        listOption.order = order ? order.toString() : '';
        listOption.orderField = orderField ? orderField.toString() : '';
        listOption.search = search ? search.toString() : '';

        if (Array.isArray(testResult)) {
          const tmpList = [];
          for (const item of testResult) {
            if (item) {
              const tmpNum = Number.parseInt(item);
              (isNaN(tmpNum)) 
              ? tmpList.push(item)
              : tmpList.push(tmpNum);
            }
          }
          listOption.testResult = tmpList;

        } else if (typeof testResult === 'string' && testResult !== '') {
          const tmpNum = Number.parseInt(testResult);
          isNaN(tmpNum)
          ? listOption.testResult = [testResult]
          : listOption.testResult = [tmpNum];

        }

        if (Array.isArray(organization)) {
          const tmpList = [];
          for (const item of organization) {
            if (item) {
              const tmpNum = Number.parseInt(item);
              (isNaN(tmpNum)) 
              ? tmpList.push(item)
              : tmpList.push(tmpNum);
            }
          }

          listOption.organization = tmpList;
        } else if (typeof organization === 'string' && organization !== '') {
          const tmpNum = Number.parseInt(organization);
          isNaN(tmpNum)
          ? listOption.organization = [organization]
          : listOption.organization = [tmpNum];
        }

        if (Array.isArray(date)) {
          const tmpList = [];
          for (const item of date) {
            item && tmpList.push(new Date(item));
          }
          listOption.date = tmpList;
        }
      }
    })
    
    onMounted(() => {
      refresh();
    })

    watch(() => listOption.organization, (agency: any, prevAgency: any) => {
      if (selectList.agencyList.length > 0) {
        const checkAllIndex = agency.indexOf('all');
        const prevCheckAllIndex = prevAgency.indexOf('all'); 
        if ( checkAllIndex > -1 
        && prevCheckAllIndex < 0) {
          checkAll('org')
        } else if (checkAllIndex < 0 
        && prevCheckAllIndex > -1
        && agency.length === (selectList.agencyList.length -1)) {
          listOption.organization = [];
        } else if (checkAllIndex < 0
        && prevCheckAllIndex < 0
        && agency.length === (selectList.agencyList.length -1)) {
          checkAll('org')
        } else if (checkAllIndex > -1
        && prevCheckAllIndex > -1
        && agency.length === (selectList.agencyList.length -1)) {
          const tmpList: any [] = []
          for (const item of agency) {
            if (item !== 'all') {
              tmpList.push(item)
            }
          }
          listOption.organization = tmpList;
        }
      }
    })

    watch(() => listOption.testResult, (testResult: any, prevTestResult: any) => {
      if (selectList.resultList.length > 0) {
        const checkAllIndex = testResult.indexOf('all');
        const prevCheckAllIndex = prevTestResult.indexOf('all'); 
        if ( checkAllIndex > -1 
        && prevCheckAllIndex < 0) {
          checkAll('result')
        } else if (checkAllIndex < 0 
        && prevCheckAllIndex > -1
        && testResult.length === (selectList.resultList.length -1)) {
          listOption.testResult = [];
        } else if (checkAllIndex < 0
        && prevCheckAllIndex < 0
        && testResult.length === (selectList.resultList.length -1)) {
          checkAll('result')
        } else if (checkAllIndex > -1
        && prevCheckAllIndex > -1
        && testResult.length === (selectList.resultList.length -1)) {
          const tmpList: any [] = []
          for (const item of testResult) {
            if (item !== 'all') {
              tmpList.push(item)
            }
          }
          listOption.testResult = tmpList;
        }
      }
    })

    return {
      changePage,
      listOption,
      sort,
      selectList,
      handleClickSearch,
      getTableTime,
      getIndex,
      handleClickPrint,
      filters,
    }
  }

}
</script>