<template>
  <div class="counselee-test-history-wrapper">
    <div class="counselee-test-info-wrapper">
      <table class="counselee-test-info-table">
        <tr>
          <th>상담자</th>
          <td>{{ counseleeInfoData.counselor }}</td>
          <th>테스트 일자</th>
          <td>{{ getTableTime(counseleeInfoData.testDate) }}</td>
        </tr>
      </table>
    </div>
    <div class="counselee-history-list-wrapper">
      <List
        :pagination="false"
      >
        <template v-slot:list-table>
          <el-table
            border
            :data="listOption.list"
          >
            <el-table-column
              align ="center"
              width="60"
              label = "No"
              prop="itemNo"
              fixed
            />
            <el-table-column
              align ="center"
              prop="content"
              label="질문"
              width="850"
            >
              <template #header>
                {{'질문'}}
              </template>
            </el-table-column>
            <el-table-column
              align ="center"
              width="250"
            >
              <template #header>
                {{'답변'}}
              </template>
                <el-table-column
                  align ="center"
                >
                  <template #header>
                    {{'Yes'}}
                  </template>
                  <template #default="scope">
                    <p v-if="scope.row.answerKind === 'Yes'">O</p>
                  </template>
                </el-table-column>
                <el-table-column
                  align ="center"
                  >
                    <template #header>
                      {{'No'}}
                    </template>
                    <template #default="scope">
                      <p v-if="scope.row.answerKind === 'No'">O</p>
                    </template>
                </el-table-column>
                <el-table-column
                  align ="center"
                  >
                    <template #header>
                      {{'무응답'}}
                    </template>
                    <template #default="scope">
                      <p v-if="scope.row.answerKind === '무응답'">O</p>
                    </template>
                </el-table-column>
            </el-table-column>
          </el-table>
        </template>
      </List>
    </div>
  </div>
</template>

<script lang="ts">
import List from '@/components/List/List.vue';
import { useRoute, useRouter } from 'vue-router';
import CounseleeAPI from '@/api/counselee';
import { onBeforeMount, reactive } from '@vue/runtime-core';
import filters from '@/utils/filter';

export default {
  name: 'counseleeTestHistory',
  components: {
    List,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const counseleeInfoData = reactive({
      counselor: '',
      testDate: '',
    });

    const listOption = reactive({
      list: []
    });

    /** 상세 테스트 내역 get api */
    const getTestHistoryDetailList = async() => {
      const params = {
        id: route.params.id
      }
      await CounseleeAPI.getTestHistoryDetailList(params)
      .then((response) => {
        const responseData = response.data
        if (responseData 
        && typeof responseData === 'object'
        && Object.keys(responseData).indexOf('result') > -1
        && Object.keys(responseData).indexOf('response') > -1
        && responseData.result ) {
          const {
            counselor,
            testDate,
            list
          } = responseData.response;

          listOption.list = list;
          counseleeInfoData.counselor = counselor;
          counseleeInfoData.testDate = testDate;
        } else {
          throw 'error';
        }
      })
      .catch((err) => {
        throw err;
      })
    }

    /** 날짜 포맷 fnc */
    const getTableTime = (date: string) => {
      const dateObj:any = new Date(date);
      let result: string = '';
      (!date || dateObj.toString() === 'Invalid Date') 
      ? result = '-'
      : result = filters.dateToString(dateObj, 'tableTime');
      return result.replace('<br/>', ' ');
    }

    onBeforeMount(() => {
      getTestHistoryDetailList()
      .catch(() => {
        router.push({name: 'error'}).catch(() => {});
      })
    })

    
    return {
      counseleeInfoData,
      listOption,
      getTableTime
    }
  }
}
</script>