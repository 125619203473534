<template>
  <div class="chart-wrapper">
    <BarChart
      v-if="type === 'bar'"
      :chartStyle="chartStyle"
      :data="data"
      :options="options"
    />
    <DoughnutChart
      v-if="type === 'doughnut'"
      :chartStyle="chartStyle"
      :data="data"
      :options="options"
    />
    <PieChart
      v-if="type === 'pie'"
      :chartStyle="chartStyle"
      :data="data"
      :options="options"
    />
    <LineChart
      v-if="type === 'line'"
      :chartStyle="chartStyle"
      :data="data"
      :options="options"
    />
  </div>
</template>

<script lang="ts">
import BarChart from './BarChart.vue';
import DoughnutChart from './DoughnutChart.vue';
import PieChart from './PieChart.vue';
import LineChart from './LineChart.vue';
export default {
  name: 'chart',
  components: {
    BarChart,
    DoughnutChart,
    PieChart,
    LineChart
  },
  props: {
    chartStyle: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true
    },
    data: {
      type: Object,
      required: true
    },
    options: {
      type: Object,
      default: () => ({})
    },
  },
}
</script>
