<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script lang="ts">
export default {
  name: 'App'
}
</script>

<style lang="scss">
@import 'App.scss';
</style>

