
import { onBeforeMount, reactive, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import CounseleeAPI from '@/api/counselee';
import CounselorAPI from '@/api/counselor';
import MessageBox from 'element-plus/lib/el-message-box';
import notify from '@/utils/notify';
import { emailRegex, koreanEngRegex, passwordRegex, phoneRegex } from '@/utils/regex';
import filters from '@/utils/filter';

export default {
  name: 'createEditUser',
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const isFocused = ref(false);
    const mode = reactive({
      status: 0
    });
    const selectList: {
      licenseList: {
        label: string,
        value: number
      }[],
      agencyList: {
        label: string,
        value: number
      }[]
    } = reactive({
      licenseList: [
        {
          label: '임상심리사',
          value: 0
        },
        {
          label: '상담심리사',
          value: 1
        },
        {
          label: '사회복지사',
          value: 2
        },
        {
          label: '기타 전문 상담사',
          value: 3
        },
      ],
      agencyList: []
    });
    const counselorInfoData: {
      mail: string,
      password: string,
      checkPassword: string,
      name: string,
      phone: any,
      organizationId: any,
      license: number[],
      memo: string
    } = reactive({
      mail: '',
      password: '',
      checkPassword: '',
      name: '',
      phone: '',
      organizationId: null,
      license: [],
      memo: ''
    })

    /** 연락처 유효성 체크 및 '-' 추가 */
    const handleChangePhone = (word: string) => {
      const phone:any = word ? word.replaceAll('-', '') : '';
      if (phone.length > 11) {
        notify.warning('연락처는 11자 이내로 입력해주세요');
        counselorInfoData.phone = '';
        return;
      }
      if (!isNaN(phone)) {
        counselorInfoData.phone = filters.getFormedPhoneNumber(phone);
      } else {
        notify.warning('연락처를 정확히 입력해주세요');
        counselorInfoData.phone = '';
      }
    }

     /** 기관정보 get api */
    const getOrganizationList = () => {
      return new Promise((resolve: any, reject: any) => {
        CounseleeAPI.getOrganizationList()
        .then((response) => {
          const responseData = response.data
          if (responseData 
          && typeof responseData === 'object'
          && Object.keys(responseData).indexOf('result') > -1
          && Object.keys(responseData).indexOf('response') > -1
          && responseData.result ) {
            const {
              list
            } = responseData.response;
            const tmpList = []
            for (const item of list) {
              tmpList.push({
                label: item.name,
                value: item.id
              })
            }
            selectList.agencyList = tmpList
            resolve();
          }
        })
        .catch(() => {
          reject();
        })
      })
    }

    /** 상담사 정보 get api */
    const getCounselorInfo = () => {
      return new Promise((resolve:any, reject:any) => {
        const params = {
          id: route.params.id
        }
        CounselorAPI.getCounselorInfo(params)
        .then((response) => {
          const responseData = response.data
          if (responseData 
          && typeof responseData === 'object'
          && Object.keys(responseData).indexOf('result') > -1
          && Object.keys(responseData).indexOf('response') > -1
          && responseData.result ) {
            const {
              id,
              mail,
              name,
              phone,
              organizationId,
              organization,
              license,
              memo
            } = responseData.response;
            if (typeof mail === 'string') {
              counselorInfoData.mail = mail;
            }

            if (typeof name === 'string') {
              counselorInfoData.name = name;
            }

            if (typeof phone === 'string') {
              handleChangePhone(phone)
            }

            if (typeof organizationId === 'string') {
              counselorInfoData.organizationId = Number.parseInt(organizationId);
            } else if (typeof organizationId === 'number') {
              counselorInfoData.organizationId = organizationId;
            }

            if (Array.isArray(license)) {
              const tmpList = [];
              for (const item of license) {
                if (typeof item === 'string') {
                  tmpList.push(Number.parseInt(item))
                } else if (typeof item === 'number') {
                  tmpList.push(item);
                }
              }
              counselorInfoData.license = tmpList;
            }

            if (typeof memo === 'string') {
              counselorInfoData.memo = memo;
            }
            resolve();
          }
        })
        .catch(() => {
          reject();
        })
      })
    }

    /** 상담사 등록 params */
    const getCreateParams = () => {
      const {
        mail,
        password,
        checkPassword,
        name,
        phone,
        organizationId,
        license,
        memo
      } = counselorInfoData
      const params:any = {};

      if (mail !== '' && emailRegex.test(mail)) {
        params.mail = mail;
      } else {
        notify.warning('이메일을 정확히 입력해주세요.');
        return;
      }
      if (password !== '' && password === checkPassword && passwordRegex.test(password)) {
        params.password = password;
      } else {
        notify.warning('비밀번호를 확인해주세요.');
        return;
      }
      if (name !== '' && koreanEngRegex.test(name)) {
        params.name = name;
      } else {
        notify.warning('이름을 정확히 입력해주세요.');
        return;
      }
      if (phone && phone !== '' && phoneRegex.test(phone)) {
        if (phone.replaceAll('-', '').length > 11) {
          notify.warning('연락처는 11자 이내로 입력해주세요');
          return;
        } else {
          params.phone = phone.replaceAll('-', '');
        }
      } else {
        notify.warning('연락처를 정확히 입력해주세요.');
        return;
      }
      if (organizationId !== null) {
        params.organizationId = organizationId;
      } else {
        notify.warning('기관정보를 입력해주세요.');
        return;
      }
      params.license = license;
      
      params.memo = memo;
      

      return params;
    }

    /** 상담사 등록 api */
    const postCreateCounselor = () => {
      return new Promise((resolve:any, reject:any) => {
        const params = getCreateParams();
        if (params) {
          CounselorAPI.postCreateCounselor(params)
          .then((response) => {
            const responseData = response.data;
            if (responseData 
            && typeof responseData === 'object'
            && Object.keys(responseData).indexOf('result') > -1
            && Object.keys(responseData).indexOf('response') > -1
            && responseData.result ) {
              resolve();
            }
          })
          .catch(() => {
            reject();
          })
        } else {
          store.commit('loadingBar/hideLoadingBar');
        }
      })
    }
    /** 상담사 수정 params */
    const getEditParams = () => {
      const {
        password,
        checkPassword,
        name,
        phone,
        organizationId,
        license,
        memo
      } = counselorInfoData
      const params:any = {};

      params.id = Number.parseInt(route.params.id.toString());

      if (password !== '' || checkPassword !== '') {
        if (password === checkPassword && passwordRegex.test(password)) {
          params.password = password;
        } else {
          notify.warning('비밀번호를 확인해주세요.');
          return;
        }
      }

      if (name !== '' && koreanEngRegex.test(name)) {
        params.name = name;
      } else {
        notify.warning('이름을 정확히 입력해주세요.');
        return;
      }
      if (phone && phone !== '' && phoneRegex.test(phone)) {
        const onlyPhoneNumber = phone.replaceAll('-', '');
        if (onlyPhoneNumber.length > 11) {
          notify.warning('연락처를 11자 이내로 입력해 주세요');
          return;
        } else {
          params.phone = onlyPhoneNumber;
        }
      } else {
        notify.warning('연락처를 정확히 입력해주세요.');
        return;
      }

      if (organizationId !== null) {
        params.organizationId = organizationId;
      } else {
        notify.warning('기관정보를 입력해주세요.');
        return;
      }

      params.license = license;

      params.memo = memo;

      return params;
    }

    /** 상담사 수정 api */
    const putEditCounselor = () => {
      return new Promise((resolve:any, reject:any) => {
        const params = getEditParams();
        if (params) {
          CounselorAPI.putEditCounselor(params)
          .then((response) => {
            const responseData = response.data;
            if (responseData 
            && typeof responseData === 'object'
            && Object.keys(responseData).indexOf('result') > -1
            && Object.keys(responseData).indexOf('response') > -1
            && responseData.result ) {
              resolve();
            }
          })
          .catch(() => {
            reject();
          })
        } else {
          store.commit('loadingBar/hideLoadingBar');
        }
      })
    }

    /** 취소 클릭 */
    const handleClickCancel = () => {
      router.back();
    }

    /** 저장, 수정 클릭 */
    const handleClickSave = () => {
      store.commit('loadingBar/showLoadingBar');
      if (mode.status) {
        putEditCounselor()
        .then(() => {
          store.commit('loadingBar/hideLoadingBar');
          MessageBox({
            title: '상담사 수정',
            message: '상담사 수정이 완료되었습니다',
            callback: router.back
          })
        })
        .catch(() => {
            router.push({name: 'error'}).catch(() => {})
          })
      } else {
        postCreateCounselor()
        .then(() => {
          store.commit('loadingBar/hideLoadingBar');
          MessageBox({
            title: '상담사 등록',
            message: '상담사 등록이 완료되었습니다',
            callback: router.back
          })
        })
        .catch(() => {
            router.push({name: 'error'}).catch(() => {})
          })
      }

    }

    /** 페이지 초기화 */
    const refresh = () => {
      store.commit('loadingBar/showLoadingBar');
      if (mode.status) {
        Promise.all([
          getOrganizationList(),
          getCounselorInfo()
        ])
        .then(() => {
          store.commit('loadingBar/hideLoadingBar');
        })
        .catch(() => {
          router.push({ name: 'error' }).catch(() => {});
        })
      } else {
        getOrganizationList()
        .then(() => {
          store.commit('loadingBar/hideLoadingBar');
        })
        .catch(() => {
          router.push({ name: 'error' }).catch(() => {});
        })
      }
    }

    onBeforeMount(() => {
      route.params.status === '1' ? mode.status = 1 : mode.status = 0;
      refresh();
    });

    return {
      selectList,
      counselorInfoData,
      handleClickSave,
      handleChangePhone,
      isFocused,
      handleClickCancel,
    }
  }
}
