<template>
  <div class="breadcrumb-wrapper">
    <el-breadcrumb v-if="getCurrentDetailName() === ''">
      <el-breadcrumb-item>
        {{ getCurrentName() }}
      </el-breadcrumb-item>
    </el-breadcrumb>
    <el-breadcrumb v-else-if="getCurrentDetailName() === '상세 테스트 내역'">
      <el-breadcrumb-item>
        {{ getCurrentDetailName() }}
      </el-breadcrumb-item>
    </el-breadcrumb>
    <el-breadcrumb v-else-if="getCurrentDetailName() === '미리보기'">
      <el-breadcrumb-item>
        {{ '' }}
      </el-breadcrumb-item>
    </el-breadcrumb>
    <el-page-header v-else @back="handleClickBack" :content="getCurrentDetailName()" title=""/>
  </div>
</template>

<script lang="ts">
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router';
export default {
  name: 'Breadcrumb',
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    /** 뒤로가기 클릭 */
    const handleClickBack = () => {
      const currentPathName = route.name
      switch(currentPathName) {
        case 'createCounselee': 
        case 'counseleeDetail': router.push({ 
          name: 'manageCounselee', 
          query: route.query
        }); break;
        case 'editCounseleeInfo':
          router.push({
            name: 'counseleeDetail',
            params: {
              id: route.params.id,
              name: route.params.name
            },
            query: route.query
          }).catch(() => {});
          break;
        case 'testDetail': 
          router.push({
            name: 'counseleeDetail',
            params: {
              id: route.params.counseleeId,
              name: route.params.name
            },
            query: route.query
          }).catch(() => {}); break;
        case 'reportDetail': 
          router.push({
            name: 'counseleeDetail',
            params: {
              id: route.params.counseleeId,
              name: route.params.name
            },
            query: route.query
          }).catch(() => {}); break;
        default: router.go(-1);
      }
    }

    /** 대메뉴 이름 */
    const getCurrentName = () => {
      const currentPathName = route.matched[1].path.split('/')[1];
      switch(currentPathName) {
        case 'counselee': return '내담자 관리';
        case 'user': return '상담사 관리';
        case 'report': return '보고서 출력';
        case 'org': return '기관 관리';
        case 'master': return '관리자 관리';
        case 'setGroup': return '셋 그룹 관리';
        case 'testSet': return '테스트 셋 관리';
        case 'app': return '앱 관리';
        case 'statistics': return `${
          route.name !== 'testSetStatistics'
          ? '기관별'
          : ''
        } 
        ${
          route.name === 'testSetStatistics'
          ? '테스트 셋'
          : route.name === 'orgTestStatistics'
          ? '테스트'
          : route.name === 'counselorCounseleeStatistics'
          ? '상담사/내담자'
          : ''
        } 통계`;
        default: return '';
      }
    }

    /** 페이지 상세 이름 */
    const getCurrentDetailName = () => {
      switch(route.name) {
        case 'createCounselee': return '내담자 등록';
        case 'counseleeDetail': return `${route.params.name}님 상세 정보`;
        case 'testDetail': return `${route.params.name}님 상세 정보`;
        case 'reportDetail': return `${route.params.name}님 보고서 ${route.params.status === '1' ? '수정' : '작성'}`;
        case 'testHistory': return '상세 테스트 내역';
        case 'createEditUser': return `상담사 ${route.params.status === '1' ? '수정' : '등록' }`;
        case 'counselorMyPage': case 'adminMyPage': case 'masterMyPage': return '내 정보 수정';
        case 'printReport': return '보고서 출력';
        case 'previewReport': return '미리보기';
        case 'editCounseleeInfo': return `${route.params.name}님 정보 수정`;
        case 'createEditOrg': return `기관 ${route.params.status === '1' ? '수정' : '등록' }`;
        case 'createEditMaster': return `관리자 ${route.params.status === '1' ? '수정' : '등록' }`;
        case 'createEditSetGroup': return `셋그룹 ${route.params.status === '1' ? '수정' : '등록'}`;
        default: return '';
      }
    }
    return {
      handleClickBack,
      getCurrentName,
      getCurrentDetailName
    }
  },
  
}
</script>
