
import { computed } from 'vue';
import { useStore } from 'vuex';
import Header from '@/components/Header/Header.vue';
import Breadcrumb from '@/components/Breadcrumb/Breadcrumb.vue';
import Footer from '@/components/Footer/Footer.vue';
import 'element-plus/packages/theme-chalk/src/container.scss'

export default {
  name: 'BasicLayout',
  components: {
    Header,
    Breadcrumb,
    Footer
  },
  setup() {
    const store = useStore();

    return {
      visible: computed(() => store.getters['loadingBar/visible']),
    }
  },
  methods: {
  }
}
