<template>
  <div class="manage-counselee-wrapper">
    <div class="search-box-wrapper">
      <label>{{'검색 옵션'}}</label>
      <div class="search-condition-wrapper">
        <div class="search-condition-item">
          <el-select 
            v-model="listOption.dateOption" 
            default-first-option
          >
            <el-option
              v-for="(item, index) in selectList.testDateSelectList"
              :key="index"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </div>
        <div class="search-condition-item">
          <el-date-picker
            type="daterange"
            start-placeholder="시작일 선택"
            end-placeholder="종료일 선택"
            v-model="listOption.date"
            :disabled-date="filters.disabledDate"
          />
        </div>
        <div class="search-condition-item">
          <el-select 
            v-model="listOption.organization" 
            multiple collapse-tags 
            placeholder="기관정보 선택"
          >
            <el-option
              v-for="(item, index) in selectList.agencyList"
              :key="index"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </div>
        <div class="search-condition-item">
          <el-select 
            v-model="listOption.testResult" 
            multiple collapse-tags 
            placeholder="테스트 결과"
          >
            <el-option
              v-for="(item, index) in selectList.resultList"
              :key="index"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </div>
        <div class="search-condition-item">
          <el-tooltip placement="top">
            <template #content>
              {{'전화번호는 전체번호를 입력해주세요'}}
            </template>
            <el-input
              placeholder="검색어(아동명, 핸드폰, 상담자)"
              @keydown.enter="handleClickSearch"
              v-model="listOption.search"
              maxlength="150"
            />
          </el-tooltip>
        </div>
        <el-button
          type="info"
          class="search"
          @click="handleClickSearch"
          :disabled="listOption.entireCount === 0"
        >{{'검색'}}</el-button>
      </div>
    </div>
    <div class="list-upper-wrapper">
      <label>{{`${listOption.totalCount} / ${listOption.entireCount}`}}</label>
      <el-button
        type="primary"
        @click="handleClickCreateCounselee"
      >
        <i class="el-icon-plus"/>
        {{' 내담자 등록'}}
      </el-button>
    </div>
    <List
      :selectedPage="listOption.page"
      :maxPage="listOption.maxPage"
      :totalCount="listOption.totalCount"
      :pagination="true"
      @changePage="changePage"
    >
      <template v-slot:list-table>
        <el-table
          :data="listOption.list"
          @row-click="handleRowClick"
          style="cursor:pointer;"
          empty-text="게시물이 없습니다."
        >
          <el-table-column
            align ="center"
            width="50"
            label = "No"
            type="index"
            :index="getIndex"
            fixed
          />
          <el-table-column
            align ="center"
            prop="name"
            label="이름"
          >
            <template #header>
              {{'이름'}}
              <span 
                class="icon pointer"
                @click="sort('name')"
              >
                <i 
                  v-if="listOption.orderField === 'name' 
                        && listOption.order === 'asc' 
                        ? true : false" 
                  class='el-icon-caret-top'
                />
                <i v-else class="el-icon-caret-bottom"/>
              </span>
            </template>
            <template #default="scope">
              <a>
                {{ scope.row.name }}
              </a>
            </template>
          </el-table-column>
          <el-table-column
            align ="center"
            prop="birthDate"
          >
            <template #header>
              {{'생년월일'}}
              <span 
                class="icon pointer"
                @click="sort('birthDate')"
              >
                <i 
                  v-if="listOption.orderField === 'birthDate' 
                        && listOption.order === 'asc' 
                        ? true : false" 
                  class='el-icon-caret-top'
                />
                <i v-else class="el-icon-caret-bottom"/>
              </span>
            </template>
            <template #default="scope">
              {{ filters.getFormedDate(scope.row.birthDate) }}
            </template>
          </el-table-column>
          <el-table-column
            align ="center"
            prop="phone"
          >
            <template #header>
              {{'핸드폰번호'}}
              <span 
                class="icon pointer"
                @click="sort('phone')"
              >
                <i 
                  v-if="listOption.orderField === 'phone' 
                        && listOption.order === 'asc' 
                        ? true : false" 
                  class='el-icon-caret-top'
                />
                <i v-else class="el-icon-caret-bottom"/>
              </span>
            </template>
            <template #default="scope">
              {{ scope.row.phone ? filters.getFormedPhoneNumber(scope.row.phone) : '-' }}
            </template>
          </el-table-column>
          <el-table-column
            align ="center"
            prop="organization"
          >
            <template #header>
              {{'기관'}}
              <span 
                class="icon pointer"
                @click="sort('organization')"
              >
                <i 
                  v-if="listOption.orderField === 'organization' 
                        && listOption.order === 'asc' 
                        ? true : false" 
                  class='el-icon-caret-top'
                />
                <i v-else class="el-icon-caret-bottom"/>
              </span>
            </template>
          </el-table-column>
          <el-table-column
            align ="center"
            prop="counselor"
          >
            <template #header>
              {{'지정 상담사'}}
              <span 
                class="icon pointer"
                @click="sort('counselor')"
              >
                <i 
                  v-if="listOption.orderField === 'counselor' 
                        && listOption.order === 'asc' 
                        ? true : false" 
                  class='el-icon-caret-top'
                />
                <i v-else class="el-icon-caret-bottom"/>
              </span>
            </template>
          </el-table-column>
          <el-table-column
            align ="center"
            prop="testCnt"
          >
            <template #header>
              {{'테스트 횟수'}}
              <span 
                class="icon pointer"
                @click="sort('testCnt')"
              >
                <i 
                  v-if="listOption.orderField === 'testCnt' 
                        && listOption.order === 'asc' 
                        ? true : false" 
                  class='el-icon-caret-top'
                />
                <i v-else class="el-icon-caret-bottom"/>
              </span>
            </template>
          </el-table-column>
          <el-table-column
            align ="center"
            prop="testResult"
            width="170"
          >
            <template #header>
              {{'최근 테스트 결과'}}
              <span 
                class="icon pointer"
                @click="sort('testResult')"
              >
                <i 
                  v-if="listOption.orderField === 'testResult' 
                        && listOption.order === 'asc' 
                        ? true : false" 
                  class='el-icon-caret-top'
                />
                <i v-else class="el-icon-caret-bottom"/>
              </span>
              <!-- <i class="el-icon-d-caret"/> -->
            </template>
            <template #default="scope">
              <el-button
                v-if="scope.row.testResult === 0"
                type="success"
                class="medium"
                plain
              >
                {{'정기 상담 권유'}}
              </el-button>
              <el-button
                v-else-if="scope.row.testResult === 1"
                type="danger"
                class="medium"
                plain
              >
                {{'조기 상담 권유'}}
              </el-button>
              <el-button
                v-else-if="scope.row.testResult === 2"
                type="warning"
                class="medium"
                plain
              >
                {{'응급 상담 권유'}}
              </el-button>
              <div v-else>{{'-'}}</div>
            </template>
          </el-table-column>
          <el-table-column
            align ="center"
            prop="testDate"
            label="최근 테스트 일시"
            width="150"
          >
            <template #header>
              {{'최근 테스트 일시'}}
              <span 
                class="icon pointer"
                @click="sort('testDate')"
              >
                <i 
                  v-if="listOption.orderField === 'testDate' 
                        && listOption.order === 'asc' 
                        ? true : false" 
                  class='el-icon-caret-top'
                />
                <i v-else class="el-icon-caret-bottom"/>
              </span>
            </template>
            <template #default="scope">
              <span v-html="getTableTime(scope.row.testDate)" />
            </template>
          </el-table-column>
          <el-table-column
            align ="center"
            prop="createAt"
            label="등록일시"
          >
            <template #header>
              {{'등록일시'}}
              <span 
                class="icon pointer"
                @click="sort('createAt')"
              >
                <i 
                  v-if="listOption.orderField === 'createAt' 
                        && listOption.order === 'asc' 
                        ? true : false" 
                  class='el-icon-caret-top'
                />
                <i v-else class="el-icon-caret-bottom"/>
              </span>
            </template>
            <template #default="scope">
              <span v-html="getTableTime(scope.row.createAt)" />
            </template>
          </el-table-column>
        </el-table>
      </template>
    </List>
  </div>
</template>

<script lang="ts">
import { onBeforeMount, onMounted, reactive, watch } from "vue";
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import List from '@/components/List/List.vue';
import CounseleeAPI from '@/api/counselee';
import filters from '@/utils/filter';


export default {
  name: 'manageCounselee',
  components: {
    List
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const selectList: {
      agencyList: any[],
      resultList: any[],
      testDateSelectList: {}[]
    } = reactive({
      agencyList: [],
      resultList: [],
      testDateSelectList: [
      {
        label: '테스트일',
        value: 'test'
      },
      {
        label: '등록일',
        value: 'create'
      }
    ]
    });

    const listOption: {
      maxPage: number,
      totalCount: number,
      page: number,
      list: any[],
      order: string,
      orderField: string,
      testResult: any[],
      organization: any[],
      entireCount: number,
      search: string,
      dateOption: string,
      date: any[]
    } = reactive({
      maxPage: 10,
      totalCount: 0,
      page: 1,
      list: [],
      order: '',
      orderField: '',
      testResult: [],
      organization: [],
      entireCount: 0,
      search: '',
      dateOption: 'test',
      date: []
    });

    /** 검색쿼리 url 저장 */
    const setUrlQueryString = () => {
      const {
        page,
        order,
        orderField,
        testResult,
        organization,
        search,
        dateOption,
        date
      } = listOption;
      const query:any = {
        dateOption
      };

      if (page !== 1)
        query.page = page;

      if (order)
        query.order = order;
      
      if (orderField)
        query.orderField = orderField;

      if (search)
        query.search = search;
      
      if (testResult.length > 0) 
        query.testResult = testResult;

      if (organization.length > 0) 
        query.organization = organization;

      if (Array.isArray(date)) {
        const tmpList = [];
        for (const item of date) {
          item && tmpList.push(filters.dateToString(item, 'yyyy-mm-dd'));
        }
        query.date = tmpList;
      }

      router.replace({
        name: 'manageCounselee',
        query
      }).catch(() => {});
    }

    /** 내담자 list get params */
    const getParams = () => {
      const {
        organization,
        date,
        testResult,
        orderField,
        order,
        search,
        maxPage,
        page,
        dateOption
      } = listOption;
      const params: any = {
        maxPage,
        page,
      };

      if (date && date.length === 2) {
        params.gte = filters.dateToString(date[0], 'yyyy-mm-dd');
        params.lte = filters.dateToString(date[1], 'yyyy-mm-dd');
        params.dateOption = dateOption;
      } 

      if (organization.length > 0) {
        let tmpList: any[] = [];
        for (const item of organization) {
          if ( item === 'all') {
            tmpList = [];
            break;
          } else {
            tmpList.push(item)
          }
        }
        if (tmpList.length > 0)
          params.organization = JSON.stringify(tmpList);

      }

      if (testResult.length > 0 && testResult) {
        let tmpList: any[] = [];
        for (const item of testResult) {
          if ( item === 'all') {
            tmpList = [];
            break;
          } else {
            tmpList.push(item)
          }
        }

        if (tmpList.length > 0)
          params.testResult = JSON.stringify(tmpList);
      }

      if (orderField) 
        params.orderField = orderField;
      
      
      if (order) 
        params.order = order;
      

      if (search) 
        params.search = search.replaceAll('-', '');
      
      return params;
    }

    /** 내담자 list get api */
    const getCounseleeList = () => {
      const params = getParams();
      return new Promise((resolve: any, reject:any) => {
        CounseleeAPI.getCounseleeList(params)
        .then((response) => {
          const responseData = response.data
          if (responseData 
          && typeof responseData === 'object'
          && Object.keys(responseData).indexOf('result') > -1
          && Object.keys(responseData).indexOf('response') > -1
          && responseData.result ) {
            const {
              totalCount,
              // next,
              // previous,
              // startPage,
              // endPage,
              entireCount,
              list
            } = responseData.response;

            listOption.totalCount = totalCount;
            listOption.list = list;
            listOption.entireCount = entireCount;
            resolve();
          } else {
            reject();
          }
        })
        .catch(() => {
          reject();
        })
      })
    }

    /** 기관정보 get api */
    const getOrganizationList = () => {
      return new Promise((resolve: any, reject: any) => {
        CounseleeAPI.getOrganizationList()
        .then((response) => {
          const responseData = response.data
          if (responseData 
          && typeof responseData === 'object'
          && Object.keys(responseData).indexOf('result') > -1
          && Object.keys(responseData).indexOf('response') > -1
          && responseData.result ) {
            const {
              list
            } = responseData.response;
            const tmpList = [
              {
                label: '전체', 
                value: 'all'
              }
            ]
            for (const item of list) {
              tmpList.push({
                label: item.name,
                value: item.id
              })
            }
            selectList.agencyList = tmpList
            selectList.resultList = [
              {
                label: '전체', 
                value: 'all'
              }, 
              {
                label: '결과없음',
                value: -1
              },
              {
                label: '정기 상담 권유', 
                value: 0
              }, 
              {
                label: '조기 상담 권유', 
                value: 1
              },
              {
                label: '응급 상담 권유', 
                value: 2
              }
            ]
            resolve();
          }
        })
      })
    }


    /** 전체 체크 fnc */
    const checkAll = (type: string) => {
      if (type === 'org') {
        const tmpList: any [] = []
        for (const item of selectList.agencyList) {
          tmpList.push(item.value)
        }
        listOption.organization = tmpList;
      }
      if (type === 'result') {
        const tmpList: any [] = []
        for (const item of selectList.resultList) {
          tmpList.push(item.value)
        }
        listOption.testResult = tmpList;
      }
    }

    /** 초기화 */
    const refresh = () => {
      store.commit('loadingBar/showLoadingBar');
      getOrganizationList()
      .then(() => {
        getCounseleeList()
        .then(() => {
          store.commit('loadingBar/hideLoadingBar');
        })
        .catch(() => {
          router.push({ name: 'error' }).catch(() => {});
        })
      })
      .catch(() => {
        router.push({ name: 'error' }).catch(() => {});
      })
    }

    onBeforeMount(() => {
      const {
        page,
        order,
        orderField,
        search,
        dateOption,
        testResult,
        organization,
        date
      } = route.query;
      if (Object.keys(route.query).length > 0) {
        listOption.page = Number.parseInt(page ? page.toString() : '1');
        listOption.order = order ? order.toString() : '';
        listOption.orderField = orderField ? orderField.toString() : '';
        listOption.search = search ? search.toString() : '';
        listOption.dateOption = dateOption ? dateOption.toString() : '';
        if (Array.isArray(testResult)) {
          const tmpList = [];
          for (const item of testResult) {
            if (item) {
              const tmpNum = Number.parseInt(item);
              (isNaN(tmpNum)) 
              ? tmpList.push(item)
              : tmpList.push(tmpNum);
            }
          }
          listOption.testResult = tmpList;
        } else if (typeof testResult === 'string' && testResult !== '') {
          const tmpNum = Number.parseInt(testResult);
          isNaN(tmpNum)
          ? listOption.testResult = [testResult]
          : listOption.testResult = [tmpNum];
        }

        if (Array.isArray(organization)) {
          const tmpList = [];
          for (const item of organization) {
            if (item) {
              const tmpNum = Number.parseInt(item);
              (isNaN(tmpNum)) 
              ? tmpList.push(item)
              : tmpList.push(tmpNum);
            }
          }
          listOption.organization = tmpList;
        } else if (typeof organization === 'string' && organization !== '') {
          const tmpNum = Number.parseInt(organization);
          isNaN(tmpNum)
          ? listOption.organization = [organization]
          : listOption.organization = [tmpNum];
        }

        if (Array.isArray(date)) {
          const tmpList = [];
          for (const item of date) {
            item && tmpList.push(new Date(item));
          }
          listOption.date = tmpList;
        }
      }
    })

    onMounted(() => {
      refresh();
    })

    watch(() => listOption.organization, (organization: any, prevOrgList: any) => {
      if (selectList.agencyList.length > 0) {
        const checkAllIndex = organization.indexOf('all');
        const prevCheckAllIndex = prevOrgList.indexOf('all'); 
        if ( checkAllIndex > -1 
        && prevCheckAllIndex < 0) {
          checkAll('org')
        } else if (checkAllIndex < 0 
        && prevCheckAllIndex > -1
        && organization.length === (selectList.agencyList.length -1)) {
          listOption.organization = [];
        } else if (checkAllIndex < 0
        && prevCheckAllIndex < 0
        && organization.length === (selectList.agencyList.length -1)) {
          checkAll('org')
        } else if (checkAllIndex > -1
        && prevCheckAllIndex > -1
        && organization.length === (selectList.agencyList.length -1)) {
          const tmpList: any [] = []
          for (const item of organization) {
            if (item !== 'all') {
              tmpList.push(item)
            }
          }
          listOption.organization = tmpList;
        }
      }
    })

    watch(() => listOption.testResult, (testResult: any, prevTestResult: any) => {
      if (selectList.resultList.length > 0) {
        const checkAllIndex = testResult.indexOf('all');
        const prevCheckAllIndex = prevTestResult.indexOf('all'); 
        if ( checkAllIndex > -1 
        && prevCheckAllIndex < 0) {
          checkAll('result')
        } else if (checkAllIndex < 0 
        && prevCheckAllIndex > -1
        && testResult.length === (selectList.resultList.length -1)) {
          listOption.testResult = [];
        } else if (checkAllIndex < 0
        && prevCheckAllIndex < 0
        && testResult.length === (selectList.resultList.length -1)) {
          checkAll('result')
        } else if (checkAllIndex > -1
        && prevCheckAllIndex > -1
        && testResult.length === (selectList.resultList.length -1)) {
          const tmpList: any [] = []
          for (const item of testResult) {
            if (item !== 'all') {
              tmpList.push(item)
              }
          }
          listOption.testResult = tmpList;
        }
      }
    });


    return {
      filters,
      selectList,
      listOption,
      changePage(page: number) {
        listOption.page = page;
        setUrlQueryString();
      },
      handleRowClick(row: any) {
        router.push({
          name: 'counseleeDetail', 
          params: {
            id: row.id, 
            name: row.name,
          },
          query: route.query
        }).catch(() => {})
      },
      handleClickSearch() {
        listOption.page = 1;
        setUrlQueryString();
      },
      sort(orderField: string) {
        if (listOption.orderField === orderField) {
          (listOption.order === 'desc') ? listOption.order = 'asc' : listOption.order = 'desc';
        } else {
          listOption.orderField = orderField;
          listOption.order = 'desc';
        }
        setUrlQueryString();

      },
      getTableTime(date: string) {
        const dateObj:any = new Date(date);
        let result: string = '';
        (!date || dateObj.toString() === 'Invalid Date') 
        ? result = '-'
        : result = filters.dateToString(dateObj, 'tableTime');
        return result;
      },
      getIndex(index: number) {
        const {
          totalCount,
          maxPage,
          page
        } = listOption;
        return totalCount - (maxPage * (page - 1)) - index;
      },
      handleClickCreateCounselee() {
        router.push({
          name: 'createCounselee',
          query: route.query
        })
      },
    }
  }

}
</script>