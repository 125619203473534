
import { onBeforeMount, reactive, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import MasterAPI from '@/api/master';
import notify from '@/utils/notify';
import { phoneRegex, emailRegex, passwordRegex } from '@/utils/regex';
import MessageBox from 'element-plus/lib/el-message-box';
import filters from '@/utils/filter';
import { getCookie } from '@/utils/cookie';
export default {
  name: 'masterMyPage',
  setup() {
    const store = useStore();
    const route = useRoute()
    const router = useRouter();
    const isFocused = ref(false); // 자동완성 방지

    const mode = reactive({
      status: 0
    }); // 1: 수정, 0: 등록

    const masterInfo = reactive({
      name: '',
      phone: '',
      mail: '',
      memo: '',
      password: '',
      checkPassword: ''
    });

    const handleChangePhone = (word: string) => {
      const phone:any = word ? word.replaceAll('-', '') : '';
      if (phone.length > 11) {
        notify.warning('대표 연락처를 11자 이내로 입력해주세요');
        masterInfo.phone = ''
        return;
      }

      if (!isNaN(phone)) {
        masterInfo.phone = filters.getFormedPhoneNumber(phone);
      } else {
        notify.warning('대표 연락처를 정확히 입력해주세요')
        masterInfo.phone = '';
      }
    }

    /** 마스터 정보 get api */
    const getMasterDetail = async() => {
      const params = {
        id: getCookie('pk')
      };
      await MasterAPI.getMasterDetail(params)
      .then((response) => {
        const responseData = response.data;
          if (responseData 
          && typeof responseData === 'object'
          && Object.keys(responseData).indexOf('result') > -1
          && responseData.result ) {
            const {
              // id,
              name,
              phone,
              mail,
              memo
            } = responseData.response;

            if (typeof name === 'string')
              masterInfo.name = name;

            if (typeof phone === 'string')
              handleChangePhone(phone);

            if (typeof mail === 'string')
              masterInfo.mail = mail;

            if (typeof memo === 'string')
              masterInfo.memo = memo;
          }
      })
    }

    /** 마스터 수정 params */
    const getEditParams = () => {
      const {
        name,
        phone,
        mail,
        memo,
        password,
        checkPassword
      } = masterInfo;
      const params:any = {
        id: getCookie('pk')
      };

      if (name) {
        params.name = name;
      } else {
        notify.warning('관리자명을 입력해주세요');
        return;
      }

      if (phone !== '') {
        if (phoneRegex.test(phone)) {
          const onlyNumber = phone.replaceAll('-', '');
          if (onlyNumber.length > 11) {
            notify.warning('연락처를 11자 이내로 입력해 주세요');
            return;
          } else {
            params.phone = onlyNumber;
          }
        } else {
          notify.warning('연락처를 정확히 입력해주세요.');
          return;
        }
      } else {
        notify.warning('연락처를 입력해주세요');
        return;
      }

      if (mail !== '' && emailRegex.test(mail)) {
        params.mail = mail;
      } else {
        notify.warning('이메일을 정확히 입력해주세요');
        return;
      }

      if (password && checkPassword) {
        if (password === checkPassword && passwordRegex.test(password)) {
          params.password = password;
        } else {
          notify.warning('비밀번호를 확인해주세요');
          return;
        }
      } else if (password || checkPassword) {
        notify.warning('비밀번호를 확인해주세요');
        return;
      }

      params.memo = memo;

      return params;
    }

    /** 마스터 수정 api */
    const putEditMaster = async() => {
      const params = getEditParams();
      if (params) {
        await MasterAPI.putEditMaster(params)
        .then((response) => {
          const responseData = response.data;
          if (responseData 
          && typeof responseData === 'object'
          && Object.keys(responseData).indexOf('result') > -1
          && responseData.result ) {
            MessageBox({
              title: '내정보 수정',
              type: 'info',
              message: '내정보 수정이 완료되었습니다.',
              callback: router.back
            });
          }
        })
        .catch(error => {
          if (typeof error === 'object'
          && Object.keys(error).indexOf('response') > -1
          && typeof error.response === 'object'
          && Object.keys(error.response).indexOf('status') > -1
          && Object.keys(error.response).indexOf('data') > -1
          && Object.keys(error.response.data).indexOf('response') > -1
          && Object.keys(error.response.data.response).indexOf('message') > -1) {
            const status = error.response.status;
            if (status === 400) {
              if (error.response.data.response.message === 'User already exists.') {
                notify.error('이미 존재하는 이메일입니다.');
              } else if (error.response.data.response.message === 'Only master can do this.') {
                MessageBox({
                  type: 'error',
                  message: '잘못된 접근입니다.',
                  callback() {
                    router.push({ name: 'manageCounselee' }).catch(() => {});
                  }
                });
              } else {
                throw 'err';
              }
            } else {
              throw error;
            }
          } else {
            throw error;
          }
        })
      }
    }


    onBeforeMount(() => {
      store.commit('loadingBar/showLoadingBar');
      getMasterDetail()
      .then(() => {
        store.commit('loadingBar/hideLoadingBar');
      })
      .catch(() => {
        router.push({ name: 'error' }).catch(() => {});
      })
    
    })

    return {
      isFocused,
      masterInfo,
      handleChangePhone,
      handleClickCancel() {
        router.go(-1);
      },
      handleClickSave() {
        store.commit('loadingBar/showLoadingBar');
        putEditMaster()
        .then(() => {
          store.commit('loadingBar/hideLoadingBar');
        })
        .catch(() => {
          router.push({ name: 'error' }).catch(() => {});
        })
        
      },
    }
  }
}
