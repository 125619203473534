import instance from '@/api/index';

const OrgAPI = {
  getOrgList: (query: object) => {
    const url = '/account/organization/list';
    const params = query;
    const method = 'get';

    return instance({
      url,
      params,
      method,
    });
  },
  getOrgDetail: (query: object) => {
    const url = '/account/organization';
    const params = query;
    const method = 'get';

    return instance({
      url,
      params,
      method,
    });
  },
  postCreateOrg: (query: object) => {
    const url = '/account/organization';
    const params = query;

    return instance.post(
      url,
      params,
    );
  },
  putEditOrg: (query: object) => {
    const url = '/account/organization';
    const params = query;

    return instance.put(
      url,
      params,
    );
  },
  deleteOrg: (query: object) => {
    const url = '/account/organization';
    const params =  {
      data: query,
    };

    return instance.delete(
      url,
      params,
    );
  },
  getTestSetGroupList: () => {
    const url = '/counsel/test/set/group';
    const method = 'get';

    return instance({
      url,
      method,
    });
  },
};

export default OrgAPI;
