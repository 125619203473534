<template>
  <div v-if="modalVisible" class="modal-shadow">
    <div 
      class="modal-wrap" 
      :class="modalType"
    >
      <!--모달 헤더-->
      <header>
        <div class="modal-title">
          <slot name="modal-title">
            <b>{{ modalOption.title }}</b>
          </slot>
        </div>
        <div class="modal-close">
          <!-- <button> -->
            <el-button @click="closeModal">
              <i class="el-icon-close" />
            </el-button>
          <!-- </button> -->
        </div>
      </header>
      <!--모달 바디-->
      <div class="modal-body">
        <slot name="modal-body">Dialog Body</slot>
      </div>
      <!--모달 푸터-->
      <footer class="modal-button">
        <slot name="modal-footer">
          <el-button @click="modalOption.rightButtonCallBack">{{ modalOption.rightButtonName }}</el-button>
        </slot>
      </footer>
    </div>
  </div>
</template>

<script lang="ts">
import { useStore } from 'vuex'
import { computed } from 'vue';

export default {
  name: 'Modal',
  props: {
    options:{}
  },
  setup (props:any) {
    const store = useStore();
    const closeModal = () => {
      store.commit('modal/closeModal')
    }
    const modalOption = props.options;
    return {
      modalVisible: computed(() => store.getters['modal/modalVisible']),
      modalType: computed(() => store.getters['modal/modalType']),
      closeModal,
      modalOption,
      
    }
  },
}
</script>