
import { useRouter } from 'vue-router'
export default {
  name: 'errorPage',
  setup(){
    const router = useRouter();
    return {
      description: '페이지를 찾을수가 없거나, <br/>페이지 처리에 오류가 발생하였습니다.',
      goMain(){
        router.push('/').catch(() => {});
      }
    }
  }
}
