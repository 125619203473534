import Notify from 'element-plus/lib/el-notification';

/** 알람 토스트 */
const notify = {
  warning(message: string) {
    Notify({
      title: 'Warning',
      message,
      position: 'bottom-right',
      type: 'warning',
    });
  },
  info(message: string) {
    Notify({
      title: 'Info',
      message,
      position: 'bottom-right',
      type: 'info',
    });
  },
  error(message: string) {
    Notify({
      title: 'Error',
      message,
      position: 'bottom-right',
      type: 'error',
    });
  },
  success(message: string) {
    Notify({
      title: 'Success',
      message,
      position: 'bottom-right',
      type: 'success',
    });
  },
};

export default notify;
