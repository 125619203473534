import { ActionContext } from 'vuex';

interface State {
  modalVisible: boolean;
  modalType: string;
}

export default {
  namespaced: true,
  state: {
    modalVisible: false,
    modalType: 'type-alert',
  },
  getters: {
    modalVisible: (state: State) => (state.modalVisible),
    modalType: (state: State) => (state.modalType),
  },
  mutations: {
    openModal(state: State, type: string) {
      state.modalVisible = true;
      state.modalType = type;
    },
    closeModal(state: State) {
      state.modalVisible = false;
    },
  },
  actions: {
    openModal({ commit }: ActionContext<null, null>, type: string) {
      commit('openModal', type);
    },
    closeModal({ commit }: ActionContext<null, null>) {
      commit('closeModal');
    },
  },
};

