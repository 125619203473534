
import List from '@/components/List/List.vue';
import { useRoute, useRouter } from 'vue-router';
import CounseleeAPI from '@/api/counselee';
import { onBeforeMount, reactive } from '@vue/runtime-core';
import filters from '@/utils/filter';

export default {
  name: 'counseleeTestHistory',
  components: {
    List,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const counseleeInfoData = reactive({
      counselor: '',
      testDate: '',
    });

    const listOption = reactive({
      list: []
    });

    /** 상세 테스트 내역 get api */
    const getTestHistoryDetailList = async() => {
      const params = {
        id: route.params.id
      }
      await CounseleeAPI.getTestHistoryDetailList(params)
      .then((response) => {
        const responseData = response.data
        if (responseData 
        && typeof responseData === 'object'
        && Object.keys(responseData).indexOf('result') > -1
        && Object.keys(responseData).indexOf('response') > -1
        && responseData.result ) {
          const {
            counselor,
            testDate,
            list
          } = responseData.response;

          listOption.list = list;
          counseleeInfoData.counselor = counselor;
          counseleeInfoData.testDate = testDate;
        } else {
          throw 'error';
        }
      })
      .catch((err) => {
        throw err;
      })
    }

    /** 날짜 포맷 fnc */
    const getTableTime = (date: string) => {
      const dateObj:any = new Date(date);
      let result: string = '';
      (!date || dateObj.toString() === 'Invalid Date') 
      ? result = '-'
      : result = filters.dateToString(dateObj, 'tableTime');
      return result.replace('<br/>', ' ');
    }

    onBeforeMount(() => {
      getTestHistoryDetailList()
      .catch(() => {
        router.push({name: 'error'}).catch(() => {});
      })
    })

    
    return {
      counseleeInfoData,
      listOption,
      getTableTime
    }
  }
}
