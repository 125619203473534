import instance from '@/api/index';

const TestSetAPI = {
  getTestSetList: (query: object) => {
    const url = '/counsel/test/set/list';
    const params = query;
    const method = 'get';

    return instance({
      url,
      params,
      method,
    });
  },
  getTestSetDetail: (query: object) => {
    const url = '/counsel/test/set/detail';
    const params = query;
    const method = 'get';

    return instance({
      url,
      params,
      method,
    });
  },
};

export default TestSetAPI;
