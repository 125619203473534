import instance from '@/api/index';

const ReportAPI = {
  postCreateReport: (query: object) => {
    const url = '/report';
    const params = query;

    return instance.post(
      url,
      params,
    );
  },
  getReport: (query: object) => {
    const url = '/report';
    const params = query;
    const method = 'get';

    return instance({
      url,
      params,
      method,
    });
  },
  deleteReport: (query: object) => {
    const url = '/report';
    const params =  {
      data: query,
    };

    return instance.delete(
      url,
      params,
    );
  },
  getGuardianList: (query: object) => {
    const url = '/account/parent';
    const params = query;
    const method = 'get';

    return instance({
      url,
      params,
      method,
    });
  },
  getReportList: (query: object) => {
    const url = '/report/list';
    const params = query;
    const method = 'get';

    return instance({
      url,
      params,
      method,
    });
  },
  postPrintReport: (query: object) => {
    const url = '/report/print';
    const params = query;

    return instance.post(
      url,
      params,
    );
  },
};

export default ReportAPI;
