<template>
  <div class="create-master-wrapper">
    <div class="master-info-wrapper">
      <div class="item-header-wrapper">
        <label>{{'관리자 정보'}}</label>
      </div>
      <div class="master-info-box">
        <div class="top-area">
          <div class="left-box">
            <div class="input-wrapper">
              <div class="label-wrapper">
                <label>{{'관리자명'}}</label>
              </div>
              <el-input
                tabindex="1"
                type="name"
                placeholder="관리자명을 입력하세요"
                maxlength="50"
                v-model="masterInfo.name"
              />
            </div>
            <div class="input-wrapper">
              <div class="label-wrapper">
                <label>{{'이메일(ID)'}}</label>
              </div>
              <el-input
                tabindex="3"
                type="name"
                placeholder="이메일 형식에 맞춰주세요"
                :readonly="!isFocused"
                @focus="isFocused = true"
                @blur="isFocused = false"
                v-model="masterInfo.mail"
                maxlength="50"
              />
            </div>
            <div class="input-wrapper">
              <div class="label-wrapper">
                <label>{{'비밀번호'}}</label>
              </div>
              <el-tooltip placement="right">
                <template #content>
                  {{ '8~20자, 영문, 숫자, 특수문자 혼합' }}
                </template>
                <el-input
                  tabindex="4"
                  type="password"
                  placeholder="비밀번호를 입력하세요"
                  maxlength="20"
                  v-model="masterInfo.password"
                  :readonly="!isFocused"
                  @focus="isFocused = true"
                  @blur="isFocused = false"
                />
              </el-tooltip>
            </div>
          </div>
          <div class="right-box">
            <div class="input-wrapper">
              <div class="label-wrapper">
                <label>{{'연락처'}}</label>
              </div>
              <el-input
                tabindex="2"
                placeholder="연락처를 입력해 주세요(-제외)"
                maxlength="20"
                v-model="masterInfo.phone"
                @change="handleChangePhone"
              />
            </div>
            <div class="input-wrapper" style="height:42px;"/>
            <div class="input-wrapper">
              <div class="label-wrapper">
                <label>{{'비밀번호 확인'}}</label>
              </div>
              <el-input
                tabindex="5"
                type="password"
                placeholder="비밀번호를 재입력하세요"
                v-model="masterInfo.checkPassword"
                maxlength="20"
                :readonly="!isFocused"
                @focus="isFocused = true"
                @blur="isFocused = false"
              />
            </div>
          </div>
        </div>
        <div class="bottom-area">
          <div class="input-wrapper">
            <div class="label-wrapper">
              <label>{{'메모'}}</label>
            </div>
            <el-input
              tabindex="6"
              type="textarea"
              placeholder="관리자 특이사항 입력(1000자 이내)"
              maxlength="1000"
              v-model="masterInfo.memo"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="bottom-button-wrapper">
      <div class="left-area">
        <el-button
          v-if="$route.params.status === '1'"
          class="medium black"
          @click="handleClickDelete"
        >{{'삭제'}}</el-button>
      </div>
      <div class="right-area">
        <el-button
          type="info"
          class="medium"
          @click="handleClickCancel"
        >{{'취소'}}</el-button>
        <el-button
          type="primary"
          class="medium"
          @click="handleClickSave"
        >{{$route.params.status === '1' ? '수정' : '저장'}}</el-button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { onBeforeMount, reactive, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import MasterAPI from '@/api/master';
import notify from '@/utils/notify';
import { phoneRegex, emailRegex, passwordRegex } from '@/utils/regex';
import MessageBox from 'element-plus/lib/el-message-box';
import filters from '@/utils/filter';
export default {
  name: 'createEditMaster',
  setup() {
    const store = useStore();
    const route = useRoute()
    const router = useRouter();
    const isFocused = ref(false); // 자동완성 방지

    const mode = reactive({
      status: 0
    }); // 1: 수정, 0: 등록

    const masterInfo = reactive({
      name: '',
      phone: '',
      mail: '',
      memo: '',
      password: '',
      checkPassword: ''
    });

    const handleChangePhone = (word: string) => {
      const phone:any = word ? word.replaceAll('-', '') : '';
      if (phone.length > 11) {
        notify.warning('대표 연락처를 11자 이내로 입력해주세요');
        masterInfo.phone = ''
        return;
      }

      if (!isNaN(phone)) {
        masterInfo.phone = filters.getFormedPhoneNumber(phone);
      } else {
        notify.warning('대표 연락처를 정확히 입력해주세요')
        masterInfo.phone = '';
      }
    }

    /** 마스터 등록 파람 */
    const getCreateParams = () => {
      const {
        name,
        phone,
        mail,
        memo,
        password,
        checkPassword
      } = masterInfo;
      const params:any = {};

      if (name) {
        params.name = name;
      } else {
        notify.warning('관리자명을 입력해주세요');
        return;
      }

      if (phone !== '') {
        if (phoneRegex.test(phone)) {
          const onlyNumber = phone.replaceAll('-', '');
          if (onlyNumber.length > 11) {
            notify.warning('연락처를 11자 이내로 입력해 주세요');
            return;
          } else {
            params.phone = onlyNumber;
          }
        } else {
          notify.warning('연락처를 정확히 입력해주세요.');
          return;
        }
      } else {
        notify.warning('연락처를 입력해주세요');
        return;
      }

      if (mail !== '' && emailRegex.test(mail)) {
        params.mail = mail;
      } else {
        notify.warning('이메일을 정확히 입력해주세요');
        return;
      }

      if (password && checkPassword) {
        if (password === checkPassword && passwordRegex.test(password)) {
          params.password = password;
        } else {
          notify.warning('비밀번호를 확인해주세요');
          return;
        }
      } else {
        notify.warning('비밀번호를 입력해주세요');
        return;
      }

      if (memo)
        params.memo = memo;

      return params;
    }

    /** 마스터 등록 api */
    const postCreateMaster = async() => {
      const params = getCreateParams();
      if (params) {
        await MasterAPI.postCreateMaster(params)
        .then((response) => {
          const responseData = response.data;
          if (responseData 
          && typeof responseData === 'object'
          && Object.keys(responseData).indexOf('result') > -1
          && responseData.result ) {
            MessageBox({
              title: '관리자 등록',
              type: 'info',
              message: '관리자 등록이 완료되었습니다.',
              callback: router.back
            });
          } else {
            throw 'err';
          }
        })
        .catch(error => {
          if (typeof error === 'object'
          && Object.keys(error).indexOf('response') > -1
          && typeof error.response === 'object'
          && Object.keys(error.response).indexOf('status') > -1
          && Object.keys(error.response).indexOf('data') > -1
          && Object.keys(error.response.data).indexOf('response') > -1
          && Object.keys(error.response.data.response).indexOf('message') > -1) {
            const status = error.response.status;
            if (status === 400) {
              if (error.response.data.response.message === 'User already exists.') {
                notify.error('이미 존재하는 이메일입니다.');
              } else if (error.response.data.response.message === 'Only master can do this.') {
                MessageBox({
                  type: 'error',
                  message: '잘못된 접근입니다.',
                  callback() {
                    router.push({ name: 'manageCounselee' }).catch(() => {});
                  }
                });
              } else {
                throw 'err';
              }
            } else {
              throw error;
            }
          } else {
            throw error;
          }
        })
      }
    }

    /** 마스터 정보 get api */
    const getMasterDetail = async() => {
      const params = {
        id: route.params.id
      };
      await MasterAPI.getMasterDetail(params)
      .then((response) => {
        const responseData = response.data;
          if (responseData 
          && typeof responseData === 'object'
          && Object.keys(responseData).indexOf('result') > -1
          && responseData.result ) {
            const {
              // id,
              name,
              phone,
              mail,
              memo
            } = responseData.response;

            if (typeof name === 'string')
              masterInfo.name = name;

            if (typeof phone === 'string')
              handleChangePhone(phone);

            if (typeof mail === 'string')
              masterInfo.mail = mail;

            if (typeof memo === 'string')
              masterInfo.memo = memo;
          }
      })
    }

    /** 마스터 수정 params */
    const getEditParams = () => {
      const {
        name,
        phone,
        mail,
        memo,
        password,
        checkPassword
      } = masterInfo;
      const params:any = {
        id: route.params.id
      };

      if (name) {
        params.name = name;
      } else {
        notify.warning('관리자명을 입력해주세요');
        return;
      }

      if (phone !== '') {
        if (phoneRegex.test(phone)) {
          const onlyNumber = phone.replaceAll('-', '');
          if (onlyNumber.length > 11) {
            notify.warning('연락처를 11자 이내로 입력해 주세요');
            return;
          } else {
            params.phone = onlyNumber;
          }
        } else {
          notify.warning('연락처를 정확히 입력해주세요.');
          return;
        }
      } else {
        notify.warning('연락처를 입력해주세요');
        return;
      }

      if (mail !== '' && emailRegex.test(mail)) {
        params.mail = mail;
      } else {
        notify.warning('이메일을 정확히 입력해주세요');
        return;
      }

      if (password && checkPassword) {
        if (password === checkPassword && passwordRegex.test(password)) {
          params.password = password;
        } else {
          notify.warning('비밀번호를 확인해주세요');
          return;
        }
      } else if (password || checkPassword) {
        notify.warning('비밀번호를 확인해주세요');
        return;
      }

      params.memo = memo;

      return params;
    }

    /** 마스터 수정 api */
    const putEditMaster = async() => {
      const params = getEditParams();
      if (params) {
        await MasterAPI.putEditMaster(params)
        .then((response) => {
          const responseData = response.data;
          if (responseData 
          && typeof responseData === 'object'
          && Object.keys(responseData).indexOf('result') > -1
          && responseData.result ) {
            MessageBox({
              title: '관리자 수정',
              type: 'info',
              message: '관리자 수정이 완료되었습니다.',
              callback: router.back
            });
          }
        })
        .catch(error => {
          if (typeof error === 'object'
          && Object.keys(error).indexOf('response') > -1
          && typeof error.response === 'object'
          && Object.keys(error.response).indexOf('status') > -1
          && Object.keys(error.response).indexOf('data') > -1
          && Object.keys(error.response.data).indexOf('response') > -1
          && Object.keys(error.response.data.response).indexOf('message') > -1) {
            const status = error.response.status;
            if (status === 400) {
              if (error.response.data.response.message === 'User already exists.') {
                notify.error('이미 존재하는 이메일입니다.');
              } else if (error.response.data.response.message === 'Only master can do this.') {
                MessageBox({
                  type: 'error',
                  message: '잘못된 접근입니다.',
                  callback() {
                    router.push({ name: 'manageCounselee' }).catch(() => {});
                  }
                });
              } else {
                throw 'err';
              }
            } else {
              throw error;
            }
          } else {
            throw error;
          }
        })
      }
    }

    /** 마스터 삭제 api */
    const deleteMaster = async() => {
      const params = {
        id: route.params.id
      };
      await MasterAPI.deleteMaster(params)
      .then((response) => {
        const responseData = response.data;
        if (responseData 
        && typeof responseData === 'object'
        && Object.keys(responseData).indexOf('result') > -1
        && responseData.result ) {
          MessageBox({
            title: '삭제 완료',
            type: 'info',
            message: '관리자 삭제가 완료되었습니다.',
            callback: router.back
          })
        }
      })
      .catch(error => {
        if (typeof error === 'object'
        && Object.keys(error).indexOf('response') > -1
        && typeof error.response === 'object'
        && Object.keys(error.response).indexOf('status') > -1
        && Object.keys(error.response).indexOf('data') > -1
        && Object.keys(error.response.data).indexOf('response') > -1
        && Object.keys(error.response.data.response).indexOf('message') > -1) {
          const status = error.response.status;
          if (status === 400) {
            if (error.response.data.response.message === 'Only master can do this.') {
              MessageBox({
                type: 'error',
                message: '잘못된 접근입니다.',
                callback() {
                  router.push({ name: 'manageCounselee' }).catch(() => {});
                }
              });
            } else {
              throw 'err';
            }
          } else {
            throw error;
          }
        } else {
          throw error;
        }
      })
    }


    onBeforeMount(() => {
      if (route.params.status === '1') {
        mode.status = 1
        store.commit('loadingBar/showLoadingBar');
        getMasterDetail()
        .then(() => {
          store.commit('loadingBar/hideLoadingBar');
        })
      } else 
        mode.status = 0;

      
    })

    return {
      isFocused,
      masterInfo,
      handleChangePhone,
      handleClickCancel() {
        router.go(-1);
      },
      handleClickSave() {
        store.commit('loadingBar/showLoadingBar');
        if (mode.status) {
          putEditMaster()
          .then(() => {
            store.commit('loadingBar/hideLoadingBar');
          })
          .catch(() => {
            router.push({ name: 'error' }).catch(() => {});
          })
        } else {
          postCreateMaster()
          .then(() => {
            store.commit('loadingBar/hideLoadingBar');
          })
          .catch(() => {
            router.push({ name: 'error' }).catch(() => {});
          })
        }
      },
      handleClickDelete() {
        MessageBox.confirm(`${masterInfo.name} 관리자를 삭제하시겠습니까?`, '관리자 삭제 안내', {
          confirmButtonText: '삭제',
          cancelButtonText: '취소',
          type: 'warning'
        })
        .then(() => {
          store.commit('loadingBar/showLoadingBar');
          deleteMaster()
          .then(() => {
            store.commit('loadingBar/hideLoadingBar');
          })
        })
        .catch(() => {
        })
      }
    }
  }
}
</script>

<style>

</style>