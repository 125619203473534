import instance from '@/api/index';

const AppAPI = {
  getAppList: (query: object) => {
    const url = '/setting/app/list';
    const params = query;
    const method = 'get';

    return instance({
      url,
      params,
      method,
    });
  },
  getAppDetail: (query: object) => {
    const url = '/setting/app';
    const params = query;
    const method = 'get';

    return instance({
      url,
      params,
      method,
    });
  },
  deleteApp: (query: object) => {
    const url = '/setting/app';
    const params =  {
      data: query,
    };

    return instance.delete(
      url,
      params,
    );
  },
  postCreateApp: (query: object) => {
    const url = '/setting/app';
    const params = query;

    return instance.post(
      url,
      params,
    );
  },
  putEditApp: (query: object) => {
    const url = '/setting/app';
    const params = query;

    return instance.put(
      url,
      params,
    );
  },
};

export default AppAPI;
