
import { ref } from '@vue/reactivity'
import { watch } from '@vue/runtime-core';
export default {
  name: 'list',
  props: {
    selectedPage: Number,
    maxPage: Number,
    totalCount: Number,
    pagination: Boolean
  },
  emits: [
    'changePage'
  ],
  setup(props:any) {
    const currentPage = ref(1);

    /**
     * element plus pagination bug fix
     * totalCount가 currentPage보다 이후에 변경되면 
     * currentPage가 totalCount에 맞춰 pagination component 안에서 변경되어 
     * 2페이지를 클릭해도 1페이지 버튼이 활성화되는 현상 방지
     */
    watch(() => props.totalCount, (totalCount:number, prevTotal:number) => {
      if (totalCount !== prevTotal) {
        currentPage.value = props.selectedPage;
      }
    })

    return {
      props,
      currentPage
    }
  }
}
