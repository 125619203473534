<template>
  <div :style="chartStyle">
    <vue3-chart-js v-bind="{ ...pieChart }" :style="chartStyle" />
  </div>
</template>

<script lang="ts">
//@ts-ignore
import Vue3ChartJs from "@j-t-mcc/vue3-chartjs";

export default {
  name: "pieChart",
  components: {
    Vue3ChartJs,
  },
  props: {
    chartStyle: {
      type: String,
      required: true,
    },
    data: {
      type: Object,
      required: true
    },
    options: {
      type: Object,
      default: () => ({})
    },
  },
  setup(props: {
    data: object,
    options: object
  }) {
    const pieChart = {
      type: "pie",
      data: props.data,
      options: props.options
    };

    return {
      pieChart,
    };
  },
};
</script>
