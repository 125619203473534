<template>
  <el-footer class="footer-container">
    <div class="footer-wrapper">
      <div class="left-wrapper">
        <img
          alt="조나단 하단 로고"
          :src="require('@/assets/logos/logo-full.png')"
        />
        <h6>{{ acrylInc }}</h6>
      </div>
      <div class="right-wrapper">
        <div
          style="cursor:pointer;"
          @click="goManageKeyword"
        >0</div>
        <div style="width:50px;"/>
        <div
          style="cursor:pointer;"
          @click="goManageStatistics"
        >0</div>
        <div style="width:50px;"/>
        <div
          style="cursor:pointer;"
          @click="goManageMaster"
        >0</div>
        <div style="width:50px;"/>
        <div
          style="cursor:pointer;"
          @click="goManageOrg"
        >0</div>
        <div style="width:50px;"/>
        <div
          style="cursor:pointer;"
          @click="goManageSetGroup"
        >@</div>
        <div style="width:50px;"/>
        <div
          style="cursor:pointer;"
          @click="goManageTestSet"
        >@</div>
      </div>
    </div>
  </el-footer>
</template>

<script lang="ts">
import { useRouter } from 'vue-router'

export default {
  name: 'Footer',
  setup() {
    const router = useRouter();
    return {
      acrylInc: '© 2021 Acryl inc. All rights reserved.',
      goManageOrg() {
        router.push({ name: 'manageOrg' }).catch(() => {});
      },
      goManageMaster() {
        router.push({ name: 'manageMaster' }).catch(() => {});
      },
      goManageSetGroup() {
        router.push({ name: 'manageSetGroup'}).catch(() => {});
      },
      goManageTestSet() {
        router.push({ name: 'manageTestSet'}).catch(() => {});
      },
      goManageStatistics() {
        router.push({ name: 'counselorCounseleeStatistics' }).catch(() => {});
      },
      goManageKeyword() {
        router.push({ name: 'manageKeyword' }).catch(() => {});
      }
    }
  }

}
</script>