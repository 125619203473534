import { createStore } from 'vuex';
import loadingBar from './loadingBar';
import modal from './modal';


export default createStore({
  modules: {
    loadingBar,
    modal,
  },
});
