import { deleteCookie, getCookie } from '@/utils/cookie';
import { createRouter, createWebHistory } from 'vue-router';
import routes from './routes';

/** 등급별 권한 메뉴 */
const authMenu = {
  admin: [
    'counselee',
    'user',
    'report',
    'org',
    'myPage',
    'error',
  ],
  counselor: [
    'counselee',
    'myPage',
    'error',
  ],
};


/** 라우터 생성 */
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

/** router 이동 전 설정 */
router.beforeEach((to, from, next) => {
  const cookie = getCookie('Authorization');
  const userLevel = getCookie('userLevel');
  const userId = getCookie('userId');
  const userName = getCookie('userName');
  const userType = getCookie('userType');

  const menuName = to.path.split('/')[1];

  if (to.name === 'notFound' || to.name === undefined ) {
    return next({ name: 'error' });
  }

  if ((!cookie || !userLevel || !userId || !userName || !userType)
  && to.name !== 'login') {
    deleteCookie('Authorization');
    deleteCookie('user');
    deleteCookie('level');
    return next({path: '/login'});
  }

  /** 상담사 레벨 접근 제한 */
  if (userLevel === '2') {
    let isAuth = false;
    for (const menu of authMenu.counselor) {
      if (menuName === menu) {
        isAuth = true;
      }
    }

    if (!isAuth) {
      return next({path: '/'});
    }
  } else if (userLevel === '1') {
    let isAuth = false;
    for (const menu of authMenu.admin) {
      if (menuName === menu) {
        isAuth = true;
      }
    }

    if (!isAuth) {
      return next({path: '/'});
    }
  }

  if (to.name === 'login' && cookie) {
    return next({path: '/'});
  }

  return next();
});

// router.afterEach((to, from) => {
// });

/** router 에러 설정 */
router.onError(() => window.location.reload());
// 라우터 에러 처리

export default router;
