<template>
  <div class="login-wrapper" v-loading="loading.visible">
    <div class="container">
      <div class="logo-wrapper">
        <img
          :src="require('@/assets/logos/joanne-bi-1.png')"
          :srcset="require('@/assets/logos/joanne-bi-1@2x.png')"
          alt="조앤 로고"
        >
        <div class="login">
          {{ 'Login' }}
        </div>
        <div class="login-info">
          {{ '서비스를 위해 부여받은 아이디로 로그인 해주세요.' }}
        </div>
      </div>
      <el-form class="input-area">
        <div class="input-wrapper">
          <el-input
            type="email"
            v-model="inputData.email"
            placeholder="아이디(이메일 주소)"
          >
            <template v-slot:prefix>
              <img
                alt="이메일 아이콘"
                :src="require('@/assets/icons/00-ic-message-mail.svg')"
              />
            </template>
          </el-input>
        </div>
        <div class="input-wrapper">
          <el-input
            v-model="inputData.password"
            placeholder="비밀번호"
            type="password"
            @keyup.enter="handleClickLogin"
          >
            <template v-slot:prefix>
              <img
                alt="이메일 아이콘"
                :src="require('@/assets/icons/00-ic-info-lock.svg')"
              />
            </template>
          </el-input>
        </div>
        <el-button
          type="primary"
          class="long"
          @Click="handleClickLogin"
        >로그인</el-button>
      </el-form>
      <div class="description">
        {{ '아이디/비밀번호 관련 문의는 '}}
        <div class="inner">
          <b>{{ 'info@acryl.ai' }}</b>{{ '으로 문의주세요 '}}
        </div>
      </div>
      <div class="download-joanne-menual">
        <a :href="require('@/assets/img/Joanne_menual.png')" download>{{'JOANNE 조앤 사용방법 다운로드'}}</a>
      </div>
    </div>
    <Footer/>
  </div>
</template>

<script lang='ts'>
import { reactive } from '@vue/reactivity'
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import notify from '@/utils/notify';
import AccountAPI from '@/api/account';
import { setCookie } from '@/utils/cookie';
import Footer from '@/components/Footer/Footer.vue';

export default {
  name: 'login',
  components: {
    Footer
  },
  setup() {
    const router = useRouter();
    const store = useStore();

    const inputData: {
      email: string,
      password: string
    } = reactive({
      email: '',
      password: ''
    });

    const loading: {
      visible: boolean
    } = reactive({
      visible: false
    })

    /** 로그인 api */
    const login = async() => {
      let result = 0;
      await AccountAPI.login({
        id: inputData.email,
        password: inputData.password
      })
      .then(( response ) => {
        const responseData = response.data
        if (responseData
        && typeof responseData === 'object'
        && Object.keys(responseData).indexOf('result') > -1
        && Object.keys(responseData).indexOf('response') > -1
        && responseData.result ) {
          const {
            pk,
            organizationId,
            token,
            userId,
            userName,
            userType,
            userLevel
          } = responseData.response;
          setCookie('pk', pk, 86400000)
          setCookie('organizationId', organizationId, 86400000)
          setCookie('Authorization', token, 86400000);
          setCookie('userId', userId, 86400000);
          setCookie('userName', userName, 86400000);
          setCookie('userType', userType, 86400000);
          setCookie('userLevel', userLevel, 86400000);
          result = 1;
        } else {
          throw 'error';
        }
      })
      .catch((error) => {
        if (typeof error === 'object'
        && Object.keys(error).indexOf('response') > -1
        && typeof error.response === 'object'
        && Object.keys(error.response).indexOf('status') > -1
        && Object.keys(error.response).indexOf('data') > -1
        && Object.keys(error.response.data).indexOf('response') > -1
        && Object.keys(error.response.data.response).indexOf('message') > -1
        ) {
          const status = error.response.status;
          if (status === 400
          && error.response.data.response.message === 'Incorrect Id or Password') {
            notify.error('아이디 또는 비밀번호가 올바르지 않습니다.');
          } else {
            throw error;
          }
        } else {
          throw error;
        }
      })
      return result;

    }

    /** 로그인 클릭 */
    const handleClickLogin = () => {
      if (inputData.email === '' || inputData.password === '') {
        notify.warning('아이디, 비밀번호를 모두 입력해주세요');
        return;
      } else {
        loading.visible = true;
        store.commit('loadingBar/showLoadingBar');
        login()
        .then((result) => {
          loading.visible = false;
          if (result)
            router.push('/').catch(() => {});
        })
        .catch(() => {

        })

      }
    }

    return {
      inputData,
      handleClickLogin,
      loading,
    }
  },

}
</script>
